const SMPQuestDefaultStrategy = require("@/quest-editor/questImpl/strategies/SMPQuestDefaultStrategy");
const {MAX_HERO} = require("@/quest-editor/constants/GameConstant");

class SMPQuestUpgradeNHeroToLvStrategy extends SMPQuestDefaultStrategy {

     initStartingValue() {
          this._challengeList = [5, 3, 4, 5, 6, 6, 5, 7, 4];//n hero
     }

     increaseKPIGameplayReference() {
          this.questContext.KPIGameplayBossLevel += this.questContext.JumpingLevel;
     }

     computeChallenge() {
          let isAllHero = this.questContext.challengeTarget === -1;
          if(!isAllHero){
               let target = this.getTargetBaseOnLevel(this.questContext.level);
               if(target > MAX_HERO - 1){
                    target = MAX_HERO - 1;
               }
               this.questContext.challengeTarget = target;
          }
          this.questContext.challengeTarget2 += Math.round(1.15 * this.questContext.JumpingLevel);
     }

     computeLevelShouldAppear () {
          let shouldAppearAt = this.questContext.KPIGameplayBossLevel - 10;
          if (shouldAppearAt < 1) shouldAppearAt = 1;
          this.questContext.KPIGameLevelShouldAppear = shouldAppearAt;
     }
}

module.exports = SMPQuestUpgradeNHeroToLvStrategy;