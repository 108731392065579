//let SMPGameplay = require('../SMPGameplay');
let SMPNum = require('@/SMPNum');

class SMPQuestDefaultStrategy {

     // constructor(questContext) {
     //      this.setQuestContextAndInit(questContext)
     // }

     _challengeList = []
     _challenge2List = []

     setQuestContextAndInit(questContext) {
          this.questContext = questContext
          if (this.questContext.questJson.ordered[0].typeChallenge === 'smpnum' || this.questContext.questJson.ordered[0].bigTarget) {
               this.typeChallenge = 'smpnum';
               this.questContext.challengeTarget = SMPNum.fromPowerDouble(this.questContext.questJson.ordered[0].bigTarget);
          }else {
               this.typeChallenge = 'int';
               this.questContext.challengeTarget = this.questContext.questJson.ordered[0].target;
          }

          this.questContext.challengeTarget2 = this.questContext.questJson.ordered[0].target2;
          this.questContext.duration =this.questContext.questJson.ordered[0].duration;
          this.questContext.supportId =this.questContext.questJson.ordered[0].supportId;
          this.questContext.petId = this.questContext.questJson.ordered[0].petId;
          this.questContext.questId = this.questContext.questJson.id;

          this.initStartingValue();
     }


     initStartingValue () {
          // this.questContext.KPIGameplayBossKilled = 1;
          // this.questContext.KPIGameplayBossLevel =1;
          //this.questContext.challengeTarget = 10;
     }

     increaseKPIGameplayReference() {
          this.questContext.KPIGameplayBossLevel += 10;
          this.questContext.challengeTarget += 10;
          this.questContext.KPIGameplayBossKilled += 1;
     }

     computeLevelShouldAppear () {

     }

     computeChallenge() {

          /*if (this.typeChallenge === 'int') {
               this.questContext.challengeTarget = this.questContext.challengeTarget * this.questContext.level;
          }else {
               //let moneyDropByBoss =  SMPGameplay.DropCoins(this.questContext.KPIGameplayBossLevel);
               let moneyDropByBoss =  this.questContext.balanceBuilder.gameplay.DropCoins(this.questContext.KPIGameplayBossLevel);
               this.questContext.challengeTarget = SMPNum.multSmpNum(moneyDropByBoss,new SMPNum(10));
          }*/

     }

     computeReward() {
          let reward = this.questContext.gamePlayDataService.DropCoins(this.questContext.KPIGameplayBossLevel);
          //round in drop coin like game play
          reward.round();
          reward.setPower(Math.floor(reward._power));
          if(SMPNum.greaterThan(new SMPNum(1), reward)){
               reward = new SMPNum(1);
          }
          this.questContext.reward = reward.round();
     }

     getTargetBaseOnLevel(level)
     {
          let index = level - 1;
          if (index >= this._challengeList.length)
          {
               let howTime = index / this._challengeList.length;
               howTime = Math.floor(howTime);
               index = index - (howTime * this._challengeList.length);
          }
          return this._challengeList[index];
     }

     getTarget2BaseOnLevel(level) {
          let index = level - 1;
          if (index >= this._challenge2List.length)
          {
               let howTime = index / this._challenge2List.length;
               howTime = Math.floor(howTime);
               index = index - (howTime * this._challenge2List.length);
          }
          return this._challenge2List[index];
     }

     isCountAsQuestComplete(gameLevel){
          //console.log(this.questContext.ProgressModel.eventName+' call base : '+gameLevel+ ' isComplete: '+(this.questContext.KPIGameplayBossLevel <= gameLevel));
          return this.questContext.KPIGameplayBossLevel <= gameLevel;
     }
}

module.exports = SMPQuestDefaultStrategy
