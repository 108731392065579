const SMPQuestDefaultStrategy = require("@/quest-editor/questImpl/strategies/SMPQuestDefaultStrategy");
const SMPNum = require("@/SMPNum");

class SMPQuestCollectGoldInTSecondStrategy extends SMPQuestDefaultStrategy {
     increaseKPIGameplayReference() {
          this.questContext.KPIGameplayBossLevel += this.questContext.JumpingLevel;
     }

     computeChallenge() {
          let moneyDropByBoss =  this.questContext.gamePlayDataService.DropCoins(this.questContext.KPIGameplayBossLevel);
          moneyDropByBoss.round();
          moneyDropByBoss.setPower(Math.ceil(moneyDropByBoss._power));
          moneyDropByBoss = SMPNum.multSmpNum(moneyDropByBoss, SMPNum.fromNum(10));
          let value = SMPNum.plus(this.questContext.challengeTarget, moneyDropByBoss);
          this.questContext.challengeTarget = value;
     }

     computeLevelShouldAppear () {
          let shouldAppearAt = this.questContext.KPIGameplayBossLevel - 2;
          if (shouldAppearAt < 1) shouldAppearAt = 1;
          this.questContext.KPIGameLevelShouldAppear = shouldAppearAt;
     }
}

module.exports = SMPQuestCollectGoldInTSecondStrategy;