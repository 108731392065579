const SMPQuestDefaultStrategy = require("@/quest-editor/questImpl/strategies/SMPQuestDefaultStrategy");

class SMPQuestCriticalHitInNSecondStrategy extends SMPQuestDefaultStrategy {
     initStartingValue() {
          this._challengeList = [5, 3, 4, 5, 5, 4, 3, 5, 10];
          this._challenge2List = [ 8, 5, 10, 10, 8, 5, 2, 3, 5];
     }

     increaseKPIGameplayReference() {
          this.questContext.KPIGameplayBossLevel += this.questContext.JumpingLevel;
     }

     computeChallenge() {
          this.questContext.challengeTarget = this.getTargetBaseOnLevel(this.questContext.level);
          this.questContext.duration = this.getTarget2BaseOnLevel(this.questContext.level);
     }

     computeLevelShouldAppear () {
          let nTime = this.questContext.challengeTarget;
          let shouldAppearAt = this.questContext.KPIGameplayBossLevel - Math.round(nTime * 0.5);
          if (shouldAppearAt < 1) shouldAppearAt = 1;
          this.questContext.KPIGameLevelShouldAppear = shouldAppearAt;
     }
}

module.exports = SMPQuestCriticalHitInNSecondStrategy;