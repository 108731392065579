const SMPQuestDefaultStrategy = require("@/quest-editor/questImpl/strategies/SMPQuestDefaultStrategy");
const {MAX_SUPPORT} = require("@/quest-editor/constants/GameConstant");

class SMPQuestUpgradeSupportNRankStrategy extends SMPQuestDefaultStrategy {

     increaseKPIGameplayReference() {
          this.questContext.KPIGameplayBossLevel += this.questContext.JumpingLevel;
     }

     computeChallenge() {
          if(this.questContext.supportId < MAX_SUPPORT){
               this.questContext.supportId += 1;
          } else {
               this.questContext.challengeTarget += 1;
               this.questContext.supportId = 1;
          }

          /*define ready
          if(this.questContext.challengeTarget >= 4 //RankType.PLATINIUM
               && this.questContext.supportId >= MAX_SUPPORT) {
               this.questContext.dynamicAvailable = 0;
               console.log("End on "+this.questContext.level);//the first is 79
          }*/
     }

     computeLevelShouldAppear () {
          let shouldAppearAt = this.questContext.KPIGameplayBossLevel - 100;
          if (shouldAppearAt < 1) shouldAppearAt = 1;
          this.questContext.KPIGameLevelShouldAppear = shouldAppearAt;
     }
}

module.exports = SMPQuestUpgradeSupportNRankStrategy