const SMPQuestDefaultStrategy = require("@/quest-editor/questImpl/strategies/SMPQuestDefaultStrategy");
const {PASS_COLOR_PER_MASTERMINE} = require("@/quest-editor/constants/GameConstant");
class SMPQuestReachNMastermindColorStrategy extends SMPQuestDefaultStrategy {

     initStartingValue() {
         this._challengeList = [2, 3, 3, 5, 4, 6, 5, 8, 6, 10, 7, 5, 8, 6, 4];
     }

     increaseKPIGameplayReference() {
          this.questContext.KPIGameplayBossLevel += this.questContext.JumpingLevel;
     }

     computeChallenge() {
          this.questContext.challengeTarget = this.getTargetBaseOnLevel(this.questContext.level);
     }

     computeLevelShouldAppear() {
          let shouldAppearAt = this.questContext.KPIGameplayBossLevel - Math.round(this.questContext.challengeTarget / PASS_COLOR_PER_MASTERMINE);
          if (shouldAppearAt < 1) shouldAppearAt = 1;
          this.questContext.KPIGameLevelShouldAppear = shouldAppearAt;
     }
}

module.exports = SMPQuestReachNMastermindColorStrategy;