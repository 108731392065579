const SMPQuestDefaultStrategy = require("@/quest-editor/questImpl/strategies/SMPQuestDefaultStrategy");

class SMPQuestUseNPerkNTimeStrategy extends SMPQuestDefaultStrategy {

     initStartingValue() {
          this._challengeList = [2, 2, 3, 2, 4, 1, 2, 3];//nPerk
          this._challenge2List = [3, 2, 1, 3, 1, 4, 3, 1];//nTime
     }

     increaseKPIGameplayReference() {
          this.questContext.KPIGameplayBossLevel += this.questContext.JumpingLevel;
     }

     computeChallenge() {
          this.questContext.challengeTarget = this.getTargetBaseOnLevel(this.questContext.level);
          this.questContext.challengeTarget2 = this.getTarget2BaseOnLevel(this.questContext.level);
     }

     computeLevelShouldAppear() {
          //one perk use diamond 50 or 100 so we take 700 game level or equal to 70diamond farm
          let shouldAppearAt = this.questContext.KPIGameplayBossLevel - this.questContext.challengeTarget * 700;
          if (shouldAppearAt < 1) shouldAppearAt = 1;
          this.questContext.KPIGameLevelShouldAppear = shouldAppearAt;
     }
}

module.exports = SMPQuestUseNPerkNTimeStrategy;