const SMPQuestDefaultStrategy = require("@/quest-editor/questImpl/strategies/SMPQuestDefaultStrategy");
const {MAX_LEVEL_ON_STAGE, MAX_ZONE} = require("@/quest-editor/constants/GameConstant");

//this class is not exit on game play
//because game play this one exiting one time
class SMPQuestFirstWorldTourStrategy extends SMPQuestDefaultStrategy {
     increaseKPIGameplayReference() {
          //keep the same challenge value
     }

     computeChallenge() {
          //keep the same challenge value
     }

     computeLevelShouldAppear() {
          let shouldAppearAt = this.questContext.KPIGameplayBossLevel - (MAX_ZONE * MAX_LEVEL_ON_STAGE);
          if (shouldAppearAt < 1) shouldAppearAt = 1;
          this.questContext.KPIGameLevelShouldAppear = shouldAppearAt;
     }
}

module.exports = SMPQuestFirstWorldTourStrategy;