const SMPQuestDefaultStrategy = require("@/quest-editor/questImpl/strategies/SMPQuestDefaultStrategy");
class SMPQuestObtainNBirdWeekStrategy extends SMPQuestDefaultStrategy {

     initStartingValue() {
          this._challengeList = [40, 25, 20, 40, 60, 50, 80, 70, 100, 120, 90, 150, 100, 50, 30];
     }

     increaseKPIGameplayReference() {
          this.questContext.KPIGameplayBossLevel += this.questContext.JumpingLevel;
     }

     computeChallenge() {
          this.questContext.challengeTarget = this.getTargetBaseOnLevel(this.questContext.level);
     }

     computeLevelShouldAppear() {
          let shouldAppearAt = this.questContext.KPIGameplayBossLevel - (this.questContext.challengeTarget * 5);
          if (shouldAppearAt < 1) shouldAppearAt = 1;
          this.questContext.KPIGameLevelShouldAppear = shouldAppearAt;
     }
}

module.exports = SMPQuestObtainNBirdWeekStrategy;