module.exports = {
     MAX_LEVEL_ON_STAGE:10,
     TAP_AVG_PER_GAMELEVEL:40,
     MAX_HERO:8,
     GHOST_PER_WAVE: 9,
     MAX_SUPPORT: 20,
     MAX_PET: 39,
     MAX_PET_LEVEL: 99,
     MAX_POWER_UP:10,
     HERO_POWER_UP_MAX_LEVEL: 99,//equal to HERO_SKILL_MAX_LEVEL in gamePlay
     N_GAMELEVEL_PER_QUEST_COMPLETE: 2,
     PASS_COLOR_PER_MASTERMINE: 3,
     MAX_ZONE: 102,
     PASS_N_MATCH_PER_BOSS: 5,
     MAX_FLYING_SUPPORT: 4,
}