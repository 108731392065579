let QuestStrategyFactory = require('./SMPQuestStrategyFactory')
const QuestConstant = require("@/constants/QuestConstants");
const ZoneData = require("../assets/json/enemysdatabyzone.json");

class SMPGamePlayQuest {
     constructor(questJson, logicSimulatorService, progressTitle, progressMainTitle, tutorialTitle) {
          this.questId = null;
          this.challengeTarget = null;
          this.challengeTarget2 = null;
          this.totalCompletedChallenge = null;
          this.duration = null;
          this.supportId = null;
          this.petId = null;
          this.reward = null;
          this.KPIGameplayBossKilled = null;
          this.progressTitle = progressTitle;
          this.progressMainTitle = progressMainTitle;
          this.tutorialTitle = tutorialTitle;
          this.KPIGameplayBossLevel = questJson.kpiBossLevel;
          this.KPIGameLevelShouldAppear = questJson.kpiGameLevelShouldAppear;
          this.JumpingLevel = questJson.jumpingLevel;
          this.ProgressModel = questJson.ordered[0];
          this.gamePlayDataService = logicSimulatorService.gamePlayDataService;
          this.logicSimulatorService = logicSimulatorService;
          this.questJson = questJson;
          this.level = 1;
          this.stillUseDefaultStrategy = true;
          this.questStrategy = QuestStrategyFactory.getInstance(this.questJson, this);

          // if(this.questJson.isDaily){
          //      this.questStrategy.initStartingValue(5);
          // }
          //this.questStrategy.initStartingValue(this.level);
          //this.computeChallengeAndReward(this.level);
          this.computeOnlyReward();

          this.totalCompletedChallenge = this.challengeTarget;
     }

     isCountAsQuestComplete(gameLevel){
          return this.questStrategy.isCountAsQuestComplete(gameLevel);
     }

     setStartingLevelStep(dailyStepLevelStart){
          if(this.questJson.isDaily){
               this.questStrategy.setStartingLevelStep(dailyStepLevelStart);
          }
     }

     collect(dailyStepLevelStart) {

          this.level = this.level + 1;
          this.setStartingLevelStep(dailyStepLevelStart);
          this.questStrategy.increaseKPIGameplayReference();
          this.computeChallengeAndReward(this.level);
          this.questStrategy.computeLevelShouldAppear();

          // if (this.questJson.ordered[0].typeChallenge === 'smpnum') {
          //      this.questJson.ordered[0].bigTarget = this.challengeTarget;
          // } else {
          //      this.questJson.ordered[0].target = this.challengeTarget;
          // }

          // console.log("Strategy name: " + this.questJson.debug.strategyClassName);

          this.totalCompletedChallenge = this.totalCompletedChallenge + this.challengeTarget;

     }

     displayChallenge() {
          if (this.questJson.ordered[0].typeChallenge === 'smpnum' || this.questJson.ordered[0].bigTarget) {
               if (this.challengeTarget.ToReadableAlphabetV2) {
                    return this.challengeTarget.ToReadableAlphabetV2();
               } else {
                    return 'power: ' + this.challengeTarget;
               }
          } else {
               return this.challengeTarget;
          }
     }

     computeChallengeAndReward() {
          this.questStrategy.computeChallenge();
          this.questStrategy.computeReward();
     }

     computeOnlyReward(){
          this.questStrategy.computeReward()
     }

     getComputedQuestCurrentLevel() {
          let record = {
               questId: this.questId,
               level: this.level,
               questTitleCode: this.getFullLabel(this.questJson),
               KPIGameplayBossKilled: this.KPIGameplayBossKilled,
               JumpingLevel: this.JumpingLevel,
               KPIGameplayBossLevel: this.KPIGameplayBossLevel,
               KPIGameLevelShouldAppear: this.KPIGameLevelShouldAppear,
               challengeTarget: this.challengeTarget,
               reward: this.reward,
          }

          if (this.questJson.dynamicAvailable > 0) {
               this.questJson.dynamicAvailable -= 1;
          }

          return record;
     }


     computeQuestEvolutionSimulation() {
          let res = {};
          res.list = [];
          let numberOfTimesYouCanPlayTheQuest = 1;
          if (!this.questJson.dynamicAvailable) {
               numberOfTimesYouCanPlayTheQuest = 1;
          } else if (this.questJson.dynamicAvailable == 0) {
               numberOfTimesYouCanPlayTheQuest = 1;
          } else if (this.questJson.dynamicAvailable > 0)  {
               numberOfTimesYouCanPlayTheQuest = this.questJson.dynamicAvailable;
          } else {
               numberOfTimesYouCanPlayTheQuest = 1000;
          }
          for (let i = 0; i < numberOfTimesYouCanPlayTheQuest; i++) {
               let record = {
                    level: this.level,
                    questTitleCode: this.getFullLabel(this.questJson),
                    KPIGameplayBossKilled: this.KPIGameplayBossKilled,
                    JumpingLevel: this.JumpingLevel,
                    KPIGameplayBossLevel: this.KPIGameplayBossLevel,
                    KPIGameLevelShouldAppear: this.KPIGameLevelShouldAppear,
                    challengeTarget: this.challengeTarget,
                    reward: this.reward.GetDisplayString() + " -> " + this.reward.ToReadableAlphabetV2()
               }
               res.list.push(record)

               this.collect();

          }

          return res;
     }

     getFullLabel (quest,takeFromTitle) {

          if (!quest) quest = this.questJson;

          let fullLabel = "";

          ////console.log(gameplayQuest);
          let i = 0;
          quest.ordered.forEach(progress => {
               if (i > 0) {
                    fullLabel = fullLabel + ' and '
               }
               let progressTitle = this.progressTitle[progress.progressTitleCode];

               if (takeFromTitle) {
                    progressTitle = this.progressMainTitle[progress.progressTitleCode] + "(" + progressTitle +")";
               }

               if (progress.eventName === 'tutorial') {
                    progressTitle = this.tutorialTitle[progress.progressTitleCode];
               }


               //if (progressTitle) {
               //progressTitle = progressTitle.replace('[n]', progress.target);

               //gameplayQuest.displayChallenge()



               progressTitle = progressTitle.replace('[n]', this.displayChallenge());
               progressTitle = progressTitle.replace('[t]', this.duration);
               progressTitle = progressTitle.replace('[n2]', this.challengeTarget2);

               if (progressTitle.includes('[world_name]')) {
                    let zoneLabel = QuestConstant.zoneName[this.challengeTarget.toString()];
                    zoneLabel = ZoneData.find(d => d.zone_id === this.challengeTarget)?.zone_name;
                    progressTitle = progressTitle.replace('[world_name]', zoneLabel);
               }

               if (progress.bossType !== null || progress.bossType !== undefined) {
                    ////console.log(QuestConstant);
                    ////console.log(QuestConstant.bossType);
                    if (!progress.bossType) progress.bossType = 0;
                    let bossTypeLabel = QuestConstant.bossType[progress.bossType];
                    progressTitle = progressTitle.replace('[boss_type]',bossTypeLabel);
               }

               if (progress.eventName ==='onFruitMatch') {
                    let runeLabel = QuestConstant.runes[progress.fruitType];
                    progressTitle = progressTitle.replace('[fruit_type]',runeLabel);
               }

               if (progress.supportId) {
                    let supportLabel = QuestConstant.supportName[this.supportId.toString()];
                    progressTitle = progressTitle.replace('[support_name]',supportLabel);
               }

               if (progress.heroId != null || progress.heroId !== undefined) {
                    let label = QuestConstant.heroName[progress.heroId - 1];
                    progressTitle = progressTitle.replace('[hero_name]',label);
               }

               if (progress.progressType.includes('rank')) {
                    let supportLabel = QuestConstant.rankName[this.challengeTarget];
                    progressTitle = progressTitle.replace('[rank_title]',supportLabel);
               }

               if (progress.heroId !== null || progress.heroId !== undefined) {
                    let label = QuestConstant.heroName[progress.heroId];
                    progressTitle = progressTitle.replace('[hero_name]',label);
               }

               if (progress.petId !== null || progress.petId !== undefined) {
                    let label = QuestConstant.petName[progress.petId];
                    progressTitle = progressTitle.replace('[pet_name]',label);
               }

               if (progress.powerUpType !== null || progress.powerUpType !== undefined) {
                    let label = QuestConstant.heroSkillByName[progress.powerUpType];
                    progressTitle = progressTitle.replace('[powerup_name]',label);
               }

               fullLabel = fullLabel + progressTitle;
               //}

               i++;
          })

          //let marker = this.stillUseDefaultStrategy === true ? "----":"****"
          //return marker + fullLabel + marker;
          return fullLabel;
     }

}

module.exports = SMPGamePlayQuest
