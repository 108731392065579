<template>

    <v-card
            min-height="660"
            max-height="660"
            class="overflow-hidden"
    >
        <v-card-title>
            Support unlock settings of {{this.supportUnlock.length}} supporters
        </v-card-title>

        <v-card
                max-height="550" class="overflow-y-auto"
        >
            <v-data-table
                    :headers="headers"
                    :items="supportUnlock"
                    class="elevation-1"
                    hide-default-footer
                    :options="{itemsPerPage:this.supportUnlock.length}"
            >
                <template v-slot:item.kpiGameLevelShouldUnlock="{item}">
                    <v-edit-dialog
                            :return-value.sync="item.kpiGameLevelShouldUnlock"
                    >
                        {{ item.kpiGameLevelShouldUnlock }}
                        <template v-slot:input>
                            <v-text-field
                                    v-model="item.kpiGameLevelShouldUnlock"
                                    label="Edit"
                                    single-line
                                    autofocus
                                    @focus="$event.target.select()"
                                    @change="onChangeUnlock(item)"
                            ></v-text-field>
                        </template>
                    </v-edit-dialog>
                </template>
            </v-data-table>
        </v-card>
        <v-divider></v-divider>
        <v-card-actions>
            <v-btn color="blue darken-1" text @click="dispatchClose">Close</v-btn>
            <v-btn color="green darken-1" text @click="dispatchSave">Save</v-btn>
        </v-card-actions>
      <v-alert
          dense
          text
          type="success"
          v-if="displayConfirm"
      >
        Save Completed
      </v-alert>
    </v-card>
</template>

<script>

    export default {
        data() {
            return {
                search: "",
                supportUnlock: [],
                displayConfirm: false,
                headers: [
                    {text: 'Name', value: 'name'},
                    {
                        text: 'Unlock At',
                        align: 'start',
                        value: 'kpiGameLevelShouldUnlock',
                    },
                ]
            }
        },

        props: {
            'pSupportUnlock': {
                type: Array,
                required: true
            },
            'shouldInit': {
                type: Boolean,
            }
        },

        methods: {
            loadData: function (list) {
                this.supportUnlock = [];
                if (Array.isArray(list)) {
                    list.forEach(e => {
                        this.supportUnlock.push(Object.assign({}, e));
                    });
                }
            },
            didChange: function () {
                this.loadData(this.pSupportUnlock);
            },

            dispatchClose: function () {
                this.$emit('close');
            },

            dispatchSave: async function () {
                this.$store.commit('pushSupportUnlockSetting', this.supportUnlock);
                this.displayConfirm = true;

                const myTimeout = setTimeout(() => {
                    this.dispatchClose();
                    clearTimeout(myTimeout);
                    this.displayConfirm = false;
                }, 1000);
            },

            onChangeUnlock(item){
                item.kpiGameLevelShouldUnlock = parseInt(item.kpiGameLevelShouldUnlock);
            }
        },

        created: function () {
            this.loadData(this.pSupportUnlock);
        },
        watch: {
            pSupportUnlock: {
                deep: true,
                handler: 'didChange'
            },

            shouldInit: {
                handler: 'didChange'
            }
        },
    }

</script>
