const SMPQuestDefaultStrategy = require("@/quest-editor/questImpl/strategies/SMPQuestDefaultStrategy");
const SMPNum = require("@/SMPNum");

class SMPDailyQuestGoldCollectStrategy extends SMPQuestDefaultStrategy {

     initStartingValue() {
          this._challengeList = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10];
     }

     setStartingLevelStep(step){
          this._levelStart = (this.questContext.level - 1) * step;
     }

     increaseKPIGameplayReference() {
          this.questContext.KPIGameplayBossLevel = 6 + this._levelStart + this.getTargetBaseOnLevel(this.questContext.level);
     }

     computeChallenge() {
          // var totalCoinDrop = 10 + SMPEnemyLevelConfiguration.CoinToDrop(_startingLevel) * 5;
          // Progress.bigTarget_GS = totalCoinDrop + totalCoinDrop * GetTargetBaseOnLevel(_questModel.level);

          let moneyDropByBoss =  this.questContext.gamePlayDataService.DropCoins(this.questContext.KPIGameplayBossLevel);
          moneyDropByBoss.round();
          let totalCoinDrop = SMPNum.plus(new SMPNum(10), SMPNum.multSmpNum(moneyDropByBoss, new SMPNum(5)));
          let coinOnQuestLevel = SMPNum.multSmpNum(totalCoinDrop, new SMPNum(this.getTargetBaseOnLevel(this.questContext.level)));
          this.questContext.challengeTarget = SMPNum.plus(totalCoinDrop, coinOnQuestLevel);
     }

     computeReward() {
          let reward = this.questContext.gamePlayDataService.DropCoins(this.questContext.KPIGameplayBossLevel);
          //round in drop coin like game play
          reward.round();
          if(SMPNum.greaterThan(new SMPNum(1), reward)){
               reward = new SMPNum(1);
          }
          // reward *= 5;
          reward = SMPNum.multSmpNum(reward, new SMPNum(5));
          this.questContext.reward = reward.round();
     }

     isCountAsQuestComplete(gameLevel){
          /*this apply is correct but made kpi in bad performance
          if(this._levelStart > gameLevel) return false;
          let goldEarn = this.questContext.logicSimulatorService.cumulatedGoldWonAfterAllEnemiesFromGameLevelToGameLevel(this._levelStart, gameLevel);
          return SMPNum.greaterThan(goldEarn, this.questContext.challengeTarget);
           */

          return super.isCountAsQuestComplete(gameLevel);
     }
}

module.exports = SMPDailyQuestGoldCollectStrategy;