const SMPQuestDefaultStrategy = require("@/quest-editor/questImpl/strategies/SMPQuestDefaultStrategy");

class SMPQuestUsePerkDoomStrategy extends SMPQuestDefaultStrategy {

     initStartingValue() {
          this._challengeList = [5, 3, 5, 7, 6, 8, 10, 9, 5, 8, 6];
     }

     increaseKPIGameplayReference() {
          this.questContext.KPIGameplayBossLevel += this.questContext.JumpingLevel;
     }

     computeChallenge() {
          this.questContext.challengeTarget = this.getTargetBaseOnLevel(this.questContext.level);
     }

     computeLevelShouldAppear() {
          //one doom perk use 100 so we take 200 game level or equal to 20diamond farm
          let shouldAppearAt = this.questContext.KPIGameplayBossLevel - this.questContext.challengeTarget * 200;
          if (shouldAppearAt < 1) shouldAppearAt = 1;
          this.questContext.KPIGameLevelShouldAppear = shouldAppearAt;
     }
}

module.exports = SMPQuestUsePerkDoomStrategy;