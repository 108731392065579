<template>
    <v-snackbar
            v-model="snackbar"
            :timeout="pTimeout"
            :color="pColor"
            centered
    >
        <slot></slot>

        <template v-slot:action="{ attrs }">
            <v-btn
                    color="blue"
                    text
                    v-bind="attrs"
                    @click="onClose"
            >
                Close
            </v-btn>
        </template>
    </v-snackbar>
</template>

<script>
    export default {
        name: "PopupMessage",
        props: {
            'pColor':{
                type: String,
                required: false,
                default: 'success'
            },
            'pTimeout':{
                type: Number,
                required: false,
                default: 5000
            },
        },
        data(){
            return{
                snackbar: false,
            }
        },
        created() {
            this.snackbar = true;
            setTimeout(() => { this.onClose(); },this.pTimeout);
        },
        methods: {
            onClose(){
                if(this.snackbar){
                    this.snackbar = false;
                    this.$emit('onClose');
                }
            }
        },
    }
</script>

<style scoped>

</style>