const SMPQuestDefaultStrategy = require("@/quest-editor/questImpl/strategies/SMPQuestDefaultStrategy");
//const {MAX_SUPPORT_LEVEL} = require("@/quest-editor/constants/GameConstant");

class SMPQuestUpgradeSupportNReachStrategy extends SMPQuestDefaultStrategy {

     increaseKPIGameplayReference() {
          this.questContext.KPIGameplayBossLevel += this.questContext.JumpingLevel;
     }

     computeChallenge() {
          this.questContext.challengeTarget += this.questContext.level * 20;

          /*disable max level limit
          if(this.questContext.challengeTarget >= MAX_SUPPORT_LEVEL){
               this.questContext.challengeTarget = MAX_SUPPORT_LEVEL;

               this.questContext.dynamicAvailable = 0;
               console.log("Finish in "+this.questContext.level);//level end is 28;
          }*/
     }

     computeLevelShouldAppear () {
          let shouldAppearAt = this.questContext.KPIGameplayBossLevel - 10;
          if (shouldAppearAt < 1) shouldAppearAt = 1;
          this.questContext.KPIGameLevelShouldAppear = shouldAppearAt;
     }
}

module.exports = SMPQuestUpgradeSupportNReachStrategy