const SMPQuestDefaultStrategy = require("@/quest-editor/questImpl/strategies/SMPQuestDefaultStrategy");
const {MAX_SUPPORT} = require("@/quest-editor/constants/GameConstant");

class SMPQuestUpgradeSupportNTimeStrategy extends SMPQuestDefaultStrategy {

     initStartingValue() {
          this._challengeList = [2,3,4,5,6,7,8,9,10];
     }

     increaseKPIGameplayReference() {
          let preCheck = this.getTargetBaseOnLevel(this.questContext.level);
          this.questContext.KPIGameplayBossLevel += this.questContext.JumpingLevel + Math.round(preCheck * 7/10);
     }

     computeChallenge() {
          if (this.questContext.level % 5 === 0) {
               if (this.questContext.supportId < MAX_SUPPORT) {
                    this.questContext.supportId += 1;
               } else {
                    this.questContext.challengeTarget = this.getTargetBaseOnLevel(this.questContext.level);
                    this.questContext.supportId = 1;
               }
          } else {
               this.questContext.challengeTarget = this.getTargetBaseOnLevel(this.questContext.level);
          }
     }

     computeLevelShouldAppear () {
          // let shouldAppearAt = this.questContext.KPIGameplayBossLevel - 202;
          let shouldAppearAt = this.questContext.KPIGameplayBossLevel - this.questContext.challengeTarget;
          if (shouldAppearAt < 1) shouldAppearAt = 1;
          this.questContext.KPIGameLevelShouldAppear = shouldAppearAt;
     }
}

module.exports = SMPQuestUpgradeSupportNTimeStrategy