const SMPQuestDefaultStrategy = require("@/quest-editor/questImpl/strategies/SMPQuestDefaultStrategy");
const GameConstant = require("@/quest-editor/constants/GameConstant");

class SMPQuestCollectNDiamondStrategy extends SMPQuestDefaultStrategy {

     initStartingValue() {
          this._challengeList = [2,2,3,3,4,5,10,20,30,40,50];
     }

     increaseKPIGameplayReference() {
          let preCheckTarget = this.getTargetBaseOnLevel(this.questContext.level);
          this.questContext.KPIGameplayBossLevel += (this.questContext.JumpingLevel * preCheckTarget);
     }

     computeChallenge() {
          this.questContext.challengeTarget = this.getTargetBaseOnLevel(this.questContext.level);
     }

     computeLevelShouldAppear () {
          let shouldAppearAt = this.questContext.KPIGameplayBossLevel - (GameConstant.MAX_LEVEL_ON_STAGE * this.questContext.challengeTarget);
          if (shouldAppearAt < 1) shouldAppearAt = 1;
          this.questContext.KPIGameLevelShouldAppear = shouldAppearAt;
     }
}

module.exports = SMPQuestCollectNDiamondStrategy;