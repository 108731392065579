const SMPQuestDefaultStrategy = require("@/quest-editor/questImpl/strategies/SMPQuestDefaultStrategy");

class SMPQuestUseSkillNTimeStrategy extends SMPQuestDefaultStrategy {

     initStartingValue() {
          this._challengeList = [5, 2, 3, 4, 5, 6, 7, 8, 4, 8, 10];//nTime
     }

     increaseKPIGameplayReference() {
          this.questContext.KPIGameplayBossLevel += this.questContext.JumpingLevel;
     }

     computeChallenge() {
          this.questContext.challengeTarget = this.getTargetBaseOnLevel(this.questContext.level);
     }

     computeLevelShouldAppear() {
          let shouldAppearAt = this.questContext.KPIGameplayBossLevel - this.questContext.challengeTarget * 50;
          if (shouldAppearAt < 1) shouldAppearAt = 1;
          this.questContext.KPIGameLevelShouldAppear = shouldAppearAt;
     }
}

module.exports = SMPQuestUseSkillNTimeStrategy;