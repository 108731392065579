const SMPQuestDefaultStrategy = require("@/quest-editor/questImpl/strategies/SMPQuestDefaultStrategy");
const GameConstant = require("@/quest-editor/constants/GameConstant");
class SMPQuestCompleteYourNMissionStrategy extends SMPQuestDefaultStrategy {
     initStartingValue() {
          this._challengeList = [2,2,2,3,3,3,4,4,4,5,5,5,6,6,6,7,7,7,8,8,8,9,9,9,8,8,8,7,7,7,6,6,6,5,5,5,4,4,4 ];
     }

     increaseKPIGameplayReference() {
          let preCheckTarget = this.getTargetBaseOnLevel(this.questContext.level);
          this.questContext.KPIGameplayBossLevel += this.questContext.JumpingLevel * preCheckTarget;
     }

     computeChallenge() {
          this.questContext.challengeTarget = this.getTargetBaseOnLevel(this.questContext.level);
     }

     computeLevelShouldAppear () {
          let shouldAppearAt = this.questContext.KPIGameplayBossLevel - this.questContext.challengeTarget * GameConstant.MAX_LEVEL_ON_STAGE;
          if (shouldAppearAt < 1) shouldAppearAt = 1;
          this.questContext.KPIGameLevelShouldAppear = shouldAppearAt;
     }

     isCountAsQuestComplete(gameLevel){
          return this.questContext.KPIGameplayBossLevel - 1 <= gameLevel;
     }
}

module.exports = SMPQuestCompleteYourNMissionStrategy;