const SMPQuestDefaultStrategy = require("@/quest-editor/questImpl/strategies/SMPQuestDefaultStrategy");
class SMPQuestSwipeNRuneVerticalNTimeStrategy extends SMPQuestDefaultStrategy {

     initStartingValue() {
          this._challenge2List = [ 4, 5, 3, 5, 5]; //nRune
          this._challengeList = [ 3, 2, 5, 3, 4]; //nTime
     }

     increaseKPIGameplayReference() {
          this.questContext.KPIGameplayBossLevel += this.questContext.JumpingLevel;
     }

     computeChallenge() {
          this.questContext.challengeTarget = this.getTargetBaseOnLevel(this.questContext.level);
          this.questContext.challengeTarget2 = this.getTarget2BaseOnLevel(this.questContext.level);
     }

     computeLevelShouldAppear() {
          let nTime = this.questContext.challengeTarget2;
          let nRune = this.questContext.challengeTarget;
          let totalRune = nTime * nRune;
          let shouldAppearAt = this.questContext.KPIGameplayBossLevel - Math.ceil(totalRune / 6) - 1;
          if (shouldAppearAt < 1) shouldAppearAt = 1;
          this.questContext.KPIGameLevelShouldAppear = shouldAppearAt;
     }
}

module.exports = SMPQuestSwipeNRuneVerticalNTimeStrategy;