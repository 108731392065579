const SMPQuestDefaultStrategy = require("@/quest-editor/questImpl/strategies/SMPQuestDefaultStrategy");
const SMPNum = require("@/SMPNum");

class SMPQuestReachNDPSStrategy extends SMPQuestDefaultStrategy {


     increaseKPIGameplayReference() {
          this.questContext.KPIGameplayBossLevel += this.questContext.JumpingLevel;
     }

     computeChallenge() {
          let possibleDMGLevel = this.questContext.KPIGameplayBossLevel;

          //add more level to make quest more harder to finish
          possibleDMGLevel += 20;

          let possibleSupportActive = SMPNum.fromNum(4);
          let possibleOneSupportDps = this.questContext.gamePlayDataService.DMGStandardSupport(possibleDMGLevel);
          let possibleSupportDPS = SMPNum.multSmpNum(possibleSupportActive, possibleOneSupportDps);

          let possibleHeroDmg = this.questContext.gamePlayDataService.DMGHero(possibleDMGLevel);
          let tapPerSecond = SMPNum.fromNum(5);
          let possibleHeroDps = SMPNum.multSmpNum(possibleHeroDmg, tapPerSecond);

          this.questContext.challengeTarget = SMPNum.plus(possibleSupportDPS, possibleHeroDps);

          /*old rule take boss hp as reference
           let hpBossOnKpi = this.questContext.gamePlayDataService.HPBoss(this.questContext.KPIGameplayBossLevel);
           let tapsPerSecond = 5;
           this.questContext.challengeTarget = SMPNum.plus( hpBossOnKpi, SMPNum.fromNum(tapsPerSecond));
           */
     }

     computeLevelShouldAppear() {
          let shouldAppearAt = this.questContext.KPIGameplayBossLevel -  5;
          if (shouldAppearAt < 1) shouldAppearAt = 1;
          this.questContext.KPIGameLevelShouldAppear = shouldAppearAt;
     }
}

module.exports = SMPQuestReachNDPSStrategy;