<template>
    <v-app>
        <FooterLayout></FooterLayout>
        <KpiEditorView/>
<!--        <KpiEditorViewUsingMVC/>-->
    </v-app>
</template>

<script>

    import KpiEditorView from "./../modules/components/KpiEditorView";
    import FooterLayout  from "./../../components/commons/FooterLayout";
    // import KpiEditorViewUsingMVC from "@/kpi-editor/modules/components/KpiEditorViewUsingMVC.vue";

    export default {
        name: "KpiEditor",
        components: {
            // KpiEditorViewUsingMVC,
            KpiEditorView,
            FooterLayout,
        },
    }
</script>

<style scoped>

</style>