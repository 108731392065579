const SMPQuestDefaultStrategy = require("@/quest-editor/questImpl/strategies/SMPQuestDefaultStrategy");
const {PASS_N_MATCH_PER_BOSS} = require("@/quest-editor/constants/GameConstant");

class SMPQuestCountNFruitMatchByColorStrategy extends SMPQuestDefaultStrategy {

     initStartingValue() {
          this._challengeList = [ 15, 10, 15, 20, 25, 20, 30, 15, 40, 50, 25, 30];
     }

     increaseKPIGameplayReference() {
          this.questContext.KPIGameplayBossLevel += this.questContext.JumpingLevel;
     }

     computeChallenge() {
          this.questContext.challengeTarget = this.getTargetBaseOnLevel(this.questContext.level);
     }

     computeLevelShouldAppear() {
          let reachTarget = this.questContext.challengeTarget;
          let gameLevelReachTarget = reachTarget / PASS_N_MATCH_PER_BOSS;
          let shouldAppearAt = this.questContext.KPIGameplayBossLevel - gameLevelReachTarget;
          if (shouldAppearAt < 1) shouldAppearAt = 1;
          this.questContext.KPIGameLevelShouldAppear = shouldAppearAt;
     }
}

module.exports = SMPQuestCountNFruitMatchByColorStrategy;