const SMPQuestDefaultStrategy = require("@/quest-editor/questImpl/strategies/SMPQuestDefaultStrategy");
const GameConstant = require("@/quest-editor/constants/GameConstant");

class SMPQuestKillNGhostStrategy extends SMPQuestDefaultStrategy {

     increaseKPIGameplayReference() {
          this.questContext.KPIGameplayBossLevel += this.questContext.JumpingLevel;
     }

     computeChallenge() {
          this.questContext.challengeTarget += 5;
          if (this.questContext.challengeTarget > 1000) {
               this.questContext.challengeTarget = 1000;
          }
     }

     computeLevelShouldAppear () {
          let ghostTarget = this.questContext.challengeTarget;
          let shouldAppearAt = this.questContext.KPIGameplayBossLevel -  Math.ceil(ghostTarget * 1.0 / GameConstant.GHOST_PER_WAVE);
          if (shouldAppearAt < 1) shouldAppearAt = 1;
          this.questContext.KPIGameLevelShouldAppear = shouldAppearAt;
     }
}

module.exports = SMPQuestKillNGhostStrategy;