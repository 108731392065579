const SMPQuestDefaultStrategy = require("@/quest-editor/questImpl/strategies/SMPQuestDefaultStrategy");
class SMPQuestPlayTimeNDayStrategy extends SMPQuestDefaultStrategy {

     initStartingValue() {
          this._challengeList = [1,2,3,3,2,1];
     }

     increaseKPIGameplayReference() {
          let preCheck = this.getTargetBaseOnLevel(this.questContext.level);
          this.questContext.KPIGameplayBossLevel += this.questContext.JumpingLevel * preCheck;
     }

     computeChallenge() {
          this.questContext.challengeTarget = this.getTargetBaseOnLevel(this.questContext.level);
     }

     computeLevelShouldAppear() {
          let shouldAppearAt = this.questContext.KPIGameplayBossLevel - (this.questContext.challengeTarget * this.questContext.JumpingLevel);
          if (shouldAppearAt < 1) shouldAppearAt = 1;
          this.questContext.KPIGameLevelShouldAppear = shouldAppearAt;
     }
}

module.exports = SMPQuestPlayTimeNDayStrategy;