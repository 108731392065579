const SMPQuestDefaultStrategy = require("@/quest-editor/questImpl/strategies/SMPQuestDefaultStrategy");

class SMPQuestCriticalHitNTimeStrategy extends SMPQuestDefaultStrategy {
     initStartingValue() {
          this._challengeList = [3,5,10,25,50,75,100,150,200];
     }

     increaseKPIGameplayReference() {
          this.questContext.KPIGameplayBossLevel += this.questContext.JumpingLevel;
     }

     computeChallenge() {
          this.questContext.challengeTarget = this.getTargetBaseOnLevel(this.questContext.level);
     }

     computeLevelShouldAppear () {
          let nTime = this.questContext.challengeTarget;
          let shouldAppearAt = this.questContext.KPIGameplayBossLevel - Math.round(nTime * 0.5);
          if (shouldAppearAt < 1) shouldAppearAt = 1;
          this.questContext.KPIGameLevelShouldAppear = shouldAppearAt;
     }
}

module.exports = SMPQuestCriticalHitNTimeStrategy;