const SMPQuestDefaultStrategy = require("@/quest-editor/questImpl/strategies/SMPQuestDefaultStrategy");

class SMPQuestUsePerkMakeItRainStrategy extends SMPQuestDefaultStrategy {

     initStartingValue() {
          this._challengeList = [10, 5, 8, 7, 12, 8, 10, 9, 12, 15, 20, 18, 25, 10, 15, 5, 8];
     }

     increaseKPIGameplayReference() {
          this.questContext.KPIGameplayBossLevel += this.questContext.JumpingLevel;
     }

     computeChallenge() {
          this.questContext.challengeTarget = this.getTargetBaseOnLevel(this.questContext.level);
     }

     computeLevelShouldAppear() {
          let shouldAppearAt = this.questContext.KPIGameplayBossLevel - this.questContext.challengeTarget * 100;
          if (shouldAppearAt < 1) shouldAppearAt = 1;
          this.questContext.KPIGameLevelShouldAppear = shouldAppearAt;
     }
}

module.exports = SMPQuestUsePerkMakeItRainStrategy;