const SMPQuestDefaultStrategy = require("@/quest-editor/questImpl/strategies/SMPQuestDefaultStrategy");

class SMPQuestUpgradeNPetLvReachStrategy extends SMPQuestDefaultStrategy {

     initStartingValue() {
          this._challengeList = [5, 3, 5, 4, 6, 4, 8, 6, 8, 7, 10, 15, 12, 20, 15, 10, 8]; //number of pet
     }

     increaseKPIGameplayReference() {
          this.questContext.KPIGameplayBossLevel += this.questContext.JumpingLevel;
     }

     computeChallenge() {
          if (this.questContext.challengeTarget !== -1) {
               this.questContext.challengeTarget = this.getTargetBaseOnLevel(this.questContext.level);
          }
          this.questContext.challengeTarget2 += 1;
     }

     computeLevelShouldAppear () {
          let shouldAppearAt = 1;
          if (this.questContext.challengeTarget === -1) {
               //50 gamelevel per update first 1 level for define
               shouldAppearAt = this.questContext.KPIGameplayBossLevel - 50 * this.questContext.challengeTarget2;
          } else {
               //10 gamelevel per update first 1 level for define
               shouldAppearAt = this.questContext.KPIGameplayBossLevel - 10 * this.questContext.challengeTarget;
          }
          if (shouldAppearAt < 1) shouldAppearAt = 1;
          this.questContext.KPIGameLevelShouldAppear = shouldAppearAt;
     }


}

module.exports = SMPQuestUpgradeNPetLvReachStrategy