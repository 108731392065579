var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"overflow-hidden",attrs:{"min-height":"660","max-height":"660"}},[_c('v-card-title',[_vm._v(" Skill value settings "+_vm._s(this.skillList.length)+" ")]),_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"Search","single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c('v-card',{staticClass:"overflow-y-auto",attrs:{"max-height":"500"}},[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.skillList,"group-by":"support","hide-default-footer":"","options":{itemsPerPage:_vm.skillList.length},"search":_vm.search},scopedSlots:_vm._u([{key:"group.header",fn:function(ref){
var items = ref.items;
var isOpen = ref.isOpen;
var toggle = ref.toggle;
return [_c('th',{attrs:{"colspan":"2"}},[_c('v-icon',{on:{"click":toggle}},[_vm._v(_vm._s(isOpen ? 'mdi-minus' : 'mdi-plus')+" ")]),_vm._v(" "+_vm._s(items[0].support)+" ("+_vm._s(_vm.getColorText(items[0].support_id))+") ")],1)]}},{key:"item.m_skillPercent",fn:function(ref){
var item = ref.item;
return [_c('v-edit-dialog',{attrs:{"return-value":item.m_skillPercent},on:{"update:returnValue":function($event){return _vm.$set(item, "m_skillPercent", $event)},"update:return-value":function($event){return _vm.$set(item, "m_skillPercent", $event)}},scopedSlots:_vm._u([{key:"input",fn:function(){return [_c('v-text-field',{attrs:{"label":"Edit","single-line":"","autofocus":""},on:{"focus":function($event){return $event.target.select()}},model:{value:(item.m_skillPercent),callback:function ($$v) {_vm.$set(item, "m_skillPercent", $$v)},expression:"item.m_skillPercent"}})]},proxy:true}],null,true)},[_vm._v(" "+_vm._s(item.m_skillPercent)+" ")])]}},{key:"item.m_iCurrentLevel",fn:function(ref){
var item = ref.item;
return [_c('v-edit-dialog',{attrs:{"return-value":item.m_iCurrentLevel},on:{"update:returnValue":function($event){return _vm.$set(item, "m_iCurrentLevel", $event)},"update:return-value":function($event){return _vm.$set(item, "m_iCurrentLevel", $event)}},scopedSlots:_vm._u([{key:"input",fn:function(){return [_c('v-text-field',{attrs:{"label":"Edit","single-line":"","autofocus":""},on:{"focus":function($event){return $event.target.select()}},model:{value:(item.m_iCurrentLevel),callback:function ($$v) {_vm.$set(item, "m_iCurrentLevel", $$v)},expression:"item.m_iCurrentLevel"}})]},proxy:true}],null,true)},[_vm._v(" "+_vm._s(item.m_iCurrentLevel)+" ")])]}}])})],1),_c('v-divider'),_c('v-card-actions',[_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":_vm.dispatchClose}},[_vm._v("Close")]),_c('v-btn',{attrs:{"color":"green darken-1","text":""},on:{"click":_vm.dispatchSave}},[_vm._v("Save")])],1),(_vm.displayConfirm)?_c('v-alert',{attrs:{"dense":"","text":"","type":"success"}},[_vm._v(" Save Completed ")]):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }