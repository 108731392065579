<template>
<!--  <v-footer>-->
    <!-- -->

<!--    <BalanceParameters></BalanceParameters>-->


    <v-row>
      <v-col align="right">

        <v-btn
            color="pink"
            fab
            dark
            x-small

            right
            @click="showSettings"
        >
          <v-icon>mdi-movie</v-icon>
        </v-btn>


        <router-link  v-if="!pShowHome"  style="text-decoration: none; color: inherit;"
                      to='/'>
          <v-icon
              color=red>
            mdi-exit-to-app
          </v-icon>
        </router-link>
      </v-col>
    </v-row>


<!--  </v-footer>-->
</template>

<script>

//import BalanceParameters from '../../components/gameplay/BalanceParameters';

export default {
  data() {
    return {
      showBalanceParameters: false

    }
  },

  props : ["pShowHome"],

  methods : {
    showSettings : function() {
      //console.log('click on settings');
      //console.log(this);
      this.showBalanceParameters = !this.showBalanceParameters;
      this.$emit('showSettings')
    }
  },

  components: {
    //BalanceParameters: BalanceParameters
  }
}

</script>
