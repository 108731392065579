const SMPQuestDefaultStrategy = require("@/quest-editor/questImpl/strategies/SMPQuestDefaultStrategy");
const SMPPassiveSkillLevelConfiguration = require("@/quest-editor/questImpl/SMPPassiveSkillLevelConfiguration");

class SMPQuestReduceGhostRoundStrategy extends SMPQuestDefaultStrategy {


     increaseKPIGameplayReference() {
          this.questContext.KPIGameplayBossLevel += this.questContext.JumpingLevel;
     }

     computeChallenge() {
          this.questContext.challengeTarget += 1;
     }

     computeLevelShouldAppear() {
          let shouldAppearAt = SMPPassiveSkillLevelConfiguration.getStageForUnlockNextLevel(0, this.questContext.challengeTarget);
          if (shouldAppearAt < 1) shouldAppearAt = 1;
          this.questContext.KPIGameLevelShouldAppear = shouldAppearAt;
     }
}

module.exports = SMPQuestReduceGhostRoundStrategy;