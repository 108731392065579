const SMPQuestDefaultStrategy = require("@/quest-editor/questImpl/strategies/SMPQuestDefaultStrategy");
//const {MAX_POWER_UP} = require("@/quest-editor/constants/GameConstant");
class SMPQuestObtainNPowerUpAndUseStrategy extends SMPQuestDefaultStrategy {

     initStartingValue() {
          this._challengeList = [2,2,1,2,3,1];

          /*set dynamic ready
          let counter = 1;
          let totalChallenge = this.questContext.ProgressModel.target;
          for (let i = 2; totalChallenge < MAX_POWER_UP; i++) {
               let value = this.getTargetBaseOnLevel(i);
               totalChallenge += value;

               if (totalChallenge <= MAX_POWER_UP) {
                    counter++;
               }
          }

          this.questContext.questJson.dynamicAvailable = counter;*/
     }

     increaseKPIGameplayReference() {
          this.questContext.KPIGameplayBossLevel += this.questContext.JumpingLevel;
     }

     computeChallenge() {
          this.questContext.challengeTarget = this.getTargetBaseOnLevel(this.questContext.level);
     }

     computeLevelShouldAppear() {
          let shouldAppearAt = this.questContext.KPIGameplayBossLevel - 10;
          if (shouldAppearAt < 1) shouldAppearAt = 1;
          this.questContext.KPIGameLevelShouldAppear = shouldAppearAt;
     }
}

module.exports = SMPQuestObtainNPowerUpAndUseStrategy