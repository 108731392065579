const SMPQuestDefaultStrategy = require("@/quest-editor/questImpl/strategies/SMPQuestDefaultStrategy");
const GameConstant = require("@/quest-editor/constants/GameConstant");

class SMPQuestMakeNComboHitBossTypeStrategy extends SMPQuestDefaultStrategy {

     initStartingValue() {
          this._challengeList = [6, 9, 15, 25, 40, 60, 90];
     }

     increaseKPIGameplayReference() {
          this.questContext.KPIGameplayBossLevel += this.questContext.JumpingLevel;
     }

     computeChallenge() {
          this.questContext.challengeTarget = this.getTargetBaseOnLevel(this.questContext.level);
     }

     computeLevelShouldAppear() {
          let shouldAppearAt = Math.ceil(this.questContext.challengeTarget / 9.0) - 1;
          shouldAppearAt = this.questContext.KPIGameplayBossLevel - shouldAppearAt;
          let bossType = this.questContext.ProgressModel.bossType;
          if (bossType === 2) {
               let levelZoneBoss = GameConstant.MAX_LEVEL_ON_STAGE;
               let over = shouldAppearAt % levelZoneBoss;
               if (over > 0) {
                    shouldAppearAt = shouldAppearAt - over - 1;
               }
          } else if (bossType === 1) {
               shouldAppearAt = shouldAppearAt - (shouldAppearAt % 2) + 1;
          } else {
               shouldAppearAt = shouldAppearAt - (shouldAppearAt % 2);
          }

          if (shouldAppearAt < 1) shouldAppearAt = 1;

          this.questContext.KPIGameLevelShouldAppear = shouldAppearAt;
     }
}

module.exports = SMPQuestMakeNComboHitBossTypeStrategy;