const SMPQuestDefaultStrategy = require("@/quest-editor/questImpl/strategies/SMPQuestDefaultStrategy");
//const {TOTAL_SUPPORT_SKILL} = require("@/quest-editor/constants/GameConstant");

class SMPQuestUnlockNSupportSkillStrategy extends SMPQuestDefaultStrategy {

     initStartingValue() {
          this._challengeList = [2,3,4];

          //this.totalSkillUnlockCount = 0;
     }

     increaseKPIGameplayReference() {
          this.questContext.KPIGameplayBossLevel += this.questContext.JumpingLevel;
     }

     computeChallenge() {
          //this.totalSkillUnlockCount += this.questContext.challengeTarget;

          this.questContext.challengeTarget = this.getTargetBaseOnLevel(this.questContext.level);

          /*disable max level limit for support, so skill is unlimited also
          //total skill of all supports=1225
          if(this.totalSkillUnlockCount + nextTarget <= TOTAL_SUPPORT_SKILL){
               this.questContext.challengeTarget = nextTarget;
          } else{
               this.questContext.challengeTarget = TOTAL_SUPPORT_SKILL - this.totalSkillUnlockCount;
          }*/

          /*Define ready
          //define dynamic quest counter
          if(this.totalSkillUnlockCount + nextTarget >= TOTAL_SUPPORT_SKILL){
               this.questContext.dynamicAvailable = 0;
               console.log("The end of quest in level: "+this.questContext.level);//408
          }*/
     }

     computeLevelShouldAppear () {
          let shouldAppearAt = this.questContext.KPIGameplayBossLevel - 20;
          if (shouldAppearAt < 1) shouldAppearAt = 1;
          this.questContext.KPIGameLevelShouldAppear = shouldAppearAt;
     }
}

module.exports = SMPQuestUnlockNSupportSkillStrategy