import SMPQuestHeroReachRankStrategy from "./limited/SMPQuestHeroReachRankStrategy";
import SMPQuestDefaultStrategy from "./SMPQuestDefaultStrategy";
import SMPQuestUpgradeAllHeroReachRankStrategy from "./limited/SMPQuestUpgradeAllHeroReachRankStrategy";
import SMPQuestBeatNStageStrategy from "./repeated/SMPQuestBeatNStageStrategy";
import SMPQuestClearNBossStrategy from "./repeated/SMPQuestClearNBossStrategy";
import SMPCollectDiamondFromBirdNTimeStrategy from "./repeated/SMPCollectDiamondFromBirdNTimeStrategy";
import SMPQuestCollectGoldStrategy from "./repeated/SMPQuestCollectGoldStrategy";
import SMPQuestCollectNDiamondStrategy from "./repeated/SMPQuestCollectNDiamondStrategy";
import SMPQuestCollectGoldInTSecondStrategy from "./repeated/SMPQuestCollectGoldInTSecondStrategy";
import SMPQuestCollectPetNReachStrategy from "./limited/SMPQuestCollectPetNReachStrategy";
import SMPQuestCollectCrownStrategy from "./repeated/SMPQuestCollectCrownStrategy";
import SMPQuestCombinePowerUpStrategy from "./repeated/SMPQuestCombinePowerUpStrategy";
import SMPQuestComebackNDayStrategy from "./repeated/SMPQuestComebackNDayStrategy";
import SMPQuestCompleteYourNMissionStrategy from "./repeated/SMPQuestCompleteYourNMissionStrategy"
import SMPQuestCriticalHitNTimeStrategy from "./repeated/SMPQuestCriticalHitNTimeStrategy";
import SMPQuestDefeatNBossBattleStrategy from "./repeated/SMPQuestDefeatNBossBattleStrategy";
import SMPQuestDefeatNBossRevengeStrategy from "./repeated/SMPQuestDefeatNBossRevengeStrategy";
import SMPQuestFailNTimeStrategy from "./repeated/SMPQuestFailNTimeStrategy";
import SMPQuestTapStrategy from "./repeated/SMPQuestTapStrategy";
import SMPQuestHireNHeroStrategy from "./limited/SMPQuestHireNHeroStrategy";
import SMPQuestHireNSupportCountStrategy from "./limited/SMPQuestHireNSupportCountStrategy";
import SMPQuestKillNGhostStrategy from "./repeated/SMPQuestKillNGhostStrategy";
import SMPQuestUpdateHeroLvStrategy from "./limited/SMPQuestUpdateHeroLvStrategy";
import SMPQuestKillNTimeBigBossStrategy from "./repeated/SMPQuestKillNTimeBigBossStrategy";
import SMPQuestUpgradeSupportNTimeStrategy from "./limited/SMPQuestUpgradeSupportNTimeStrategy";
import SMPQuestUpdatePetNameLevelNTimeStrategy from "./limited/SMPQuestUpdatePetNameLevelNTimeStrategy";
import SMPQuestMakeNComboHitBossTypeStrategy from "./repeated/SMPQuestMakeNComboHitBossTypeStrategy";
import SMPQuestMakeNComboHitStrategy from "./repeated/SMPQuestMakeNComboHitStrategy";
import SMPQuestMakeNHeroReviveStrategy from "./repeated/SMPQuestMakeNHeroReviveStrategy";
import SMPQuestCompleteNQuestStrategy from "./repeated/SMPQuestCompleteNQuestStrategy";
import SMPQuestMakeReviveNSupportStrategy from "./repeated/SMPQuestMakeReviveNSupportStrategy";
import SMPQuestPlayTimeNMinuteStrategy from "./repeated/SMPQuestPlayTimeNMinuteStrategy";
import SMPQuestPlayTimeNHourStrategy from "./repeated/SMPQuestPlayTimeNHourStrategy";
import SMPQuestPlayTimeNDayStrategy from "./repeated/SMPQuestPlayTimeNDayStrategy";
import SMPQuestObtainNBirdWeekStrategy from "./repeated/SMPQuestObtainNBirdWeekStrategy";
import SMPQuestObtainNPowerUpAndUseStrategy from "./limited/SMPQuestObtainNPowerUpAndUseStrategy";
import SMPQuestOpenChestNTimeStrategy from "./repeated/SMPQuestOpenChestNTimeStrategy";
import SMPQuestClearNBossConsecutivlyStrategy from "./repeated/SMPQuestClearNBossConsecutivlyStrategy";
import SMPQuestPlayNTimeStrategy from "./repeated/SMPQuestPlayNTimeStrategy";
import SMPQuestPlayMastermindNTimeDuringDayStrategy from "./repeated/SMPQuestPlayMastermindNTimeDuringDayStrategy";
import SMPQuestPlayMastermindNTimeStrategy from "./repeated/SMPQuestPlayMastermindNTimeStrategy";
import SMPQuestPlayOnWeekendStrategy from "./repeated/SMPQuestPlayOnWeekendStrategy";
import SMPQuestUpgradeNHeroReachRankStrategy from "./limited/SMPQuestUpgradeNHeroReachRankStrategy";
import SMPQuestReachNMastermindColorStrategy from "./repeated/SMPQuestReachNMastermindColorStrategy";
import SMPQuestReachStageStrategy from "./repeated/SMPQuestReachStageStrategy";
import SMPQuestReachNDPSStrategy from "./repeated/SMPQuestReachNDPSStrategy";
import SMPQuestReduceGhostRoundStrategy from "./repeated/SMPQuestReduceGhostRoundStrategy";
import SMPQuestMakeReviveAllNTimeStrategy from "./repeated/SMPQuestMakeReviveAllNTimeStrategy";
import SMPQuestMakeHeroReviveNTimeStrategy from "./repeated/SMPQuestMakeHeroReviveNTimeStrategy";
import SMPQuestMakeSupportReviveNTimeStrategy from "./repeated/SMPQuestMakeSupportReviveNTimeStrategy";
import SMPQuestShareToFacebookStrategy from "./repeated/SMPQuestShareToFacebookStrategy";
import SMPQuestShareToTwitterStrategy from "./repeated/SMPQuestShareToTwitterStrategy";
import SMPQuestSwipeNRuneHorizontalNTimeStrategy from "./repeated/SMPQuestSwipeNRuneHorizontalNTimeStrategy";
import SMPQuestSwipeNRuneOneRowNTimeStrategy from "./repeated/SMPQuestSwipeNRuneOneRowNTimeStrategy";
import SMPQuestSwipeNRuneVerticalNTimeStrategy from "./repeated/SMPQuestSwipeNRuneVerticalNTimeStrategy";
import SMPQuestCriticalHitInNSecondStrategy from "./repeated/SMPQuestCriticalHitInNSecondStrategy";
import SMPQuestTapNTimeInSecondStrategy from "./repeated/SMPQuestTapNTimeInSecondStrategy";
import SMPQuestTrainPowerUpOfNHeroStrategy from  "./limited/SMPQuestTrainPowerUpOfNHeroStrategy";
import SMPQuestUnlockAllSupportNEvolveStrategy from "./repeated/SMPQuestUnlockAllSupportNEvolveStrategy";
import SMPQuestUnlockNHeroPowerUpStrategy from "./limited/SMPQuestUnlockNHeroPowerUpStrategy";
import SMPQuestUnlockNSupportSkillStrategy from "./limited/SMPQuestUnlockNSupportSkillStrategy";
import SMPQuestUnlockSupportReachNEvolveStrategy from  "./limited/SMPQuestUnlockSupportReachNEvolveStrategy";
import SMPQuestWorldNameStrategy from "./repeated/SMPQuestWorldNameStrategy";
import SMPQuestUpdateAPetLevelNTimeStrategy from "./limited/SMPQuestUpdateAPetLevelNTimeStrategy";
import SMPQuestUpgradeNPetLvReachStrategy from "./limited/SMPQuestUpgradeNPetLvReachStrategy";
import SMPQuestUpgradeNPetStrategy from "./limited/SMPQuestUpgradeNPetStrategy";
import SMPQuestCountWorldTourStrategy from "./repeated/SMPQuestCountWorldTourStrategy";
import SMPQuestFirstWorldTourStrategy from "./limited/SMPQuestFirstWorldTourStrategy";
import SMPQuestUpgradePowerupNTimeStrategy from "./limited/SMPQuestUpgradePowerupNTimeStrategy";
import SMPQuestUpdateHeroLvReachStrategy from "./repeated/SMPQuestUpdateHeroLvReachStrategy";
import SMPQuestUpgradeNHeroToLvStrategy from "./repeated/SMPQuestUpgradeNHeroToLvStrategy";
import SMPQuestUpgradeSupportNRankStrategy from "./limited/SMPQuestUpgradeSupportNRankStrategy";
import SMPQuestUpgradeSupportNReachStrategy from "./limited/SMPQuestUpgradeSupportNReachStrategy";
import SMPQuestCountNFruitMatchByColorStrategy from "./repeated/SMPQuestCountNFruitMatchByColorStrategy";
import SMPQuestVideoAdsStrategy from "./repeated/SMPQuestVideoAdsStrategy";
import SMPQuestWinMastermindNTimeStrategy from "./repeated/SMPQuestWinMastermindNTimeStrategy";
import SMPQuestUseNPerkNTimeStrategy from "./repeated/SMPQuestUseNPerkNTimeStrategy";
import SMPQuestUsePerkMakeItRainStrategy from "./repeated/SMPQuestUsePerkMakeItRainStrategy";
import SMPQuestUsePerkDoomStrategy from "./repeated/SMPQuestUsePerkDoomStrategy";
import SMPQuestUsePerkManaPortionStrategy from "./repeated/SMPQuestUsePerkManaPortionStrategy";
import SMPQuestUsePerkPowerOfHoldingStrategy from "./repeated/SMPQuestUsePerkPowerOfHoldingStrategy";
import SMPQuestUseNSkillNTimeStrategy from "./repeated/SMPQuestUseNSkillNTimeStrategy";
import SMPQuestUseSkillNTimeStrategy from "./repeated/SMPQuestUseSkillNTimeStrategy";
import SMPQuestTutorialStrategy from "./tutorial/SMPQuestTutorialStrategy";

import SMPDailyQuestCollectDiamondFromBirdStrategy from "./daily/SMPDailyQuestCollectDiamondFromBirdStrategy";
import SMPDailyQuestGoldCollectStrategy from './daily/SMPDailyQuestGoldCollectStrategy';
import SMPDailyQuestKillGhostStrategy from './daily/SMPDailyQuestKillGhostStrategy';
import SMPDailyQuestOpenChestStrategy from './daily/SMPDailyQuestOpenChestStrategy';
import SMPDailyQuestPlayTimeNMinutesStrategy from './daily/SMPDailyQuestPlayTimeNMinutesStrategy';
import SMPDailyQuestTapStrategy from './daily/SMPDailyQuestTapStrategy';
import SMPDailyQuestVideoAdsStrategy from './daily/SMPDailyQuestVideoAdsStrategy';

let SMPQuestStrategyClassConstructors =  {
     'SMPQuestUpgradeAllHeroReachRankStrategy': SMPQuestUpgradeAllHeroReachRankStrategy,
     'SMPQuestBeatNStageStrategy': SMPQuestBeatNStageStrategy,
     'SMPQuestClearNBossStrategy': SMPQuestClearNBossStrategy,
     'SMPCollectDiamondFromBirdNTimeStrategy': SMPCollectDiamondFromBirdNTimeStrategy,
     'SMPQuestCollectGoldStrategy': SMPQuestCollectGoldStrategy,
     'SMPQuestCollectNDiamondStrategy': SMPQuestCollectNDiamondStrategy,
     'SMPQuestCollectGoldInTSecondStrategy': SMPQuestCollectGoldInTSecondStrategy,
     'SMPQuestCollectPetNReachStrategy': SMPQuestCollectPetNReachStrategy,
     'SMPQuestCollectCrownStrategy': SMPQuestCollectCrownStrategy,
     'SMPQuestCombinePowerUpStrategy': SMPQuestCombinePowerUpStrategy,
     'SMPQuestComebackNDayStrategy': SMPQuestComebackNDayStrategy,
     'SMPQuestCompleteYourNMissionStrategy': SMPQuestCompleteYourNMissionStrategy,
     'SMPQuestCriticalHitNTimeStrategy': SMPQuestCriticalHitNTimeStrategy,
     'SMPQuestDefeatNBossBattleStrategy': SMPQuestDefeatNBossBattleStrategy,
     'SMPQuestDefeatNBossRevengeStrategy': SMPQuestDefeatNBossRevengeStrategy,
     'SMPQuestFailNTimeStrategy': SMPQuestFailNTimeStrategy,
     'SMPQuestTapStrategy': SMPQuestTapStrategy,
     'SMPQuestHireNHeroStrategy': SMPQuestHireNHeroStrategy,
     'SMPQuestHireNSupportCountStrategy': SMPQuestHireNSupportCountStrategy,
     'SMPQuestKillNGhostStrategy': SMPQuestKillNGhostStrategy,
     'SMPQuestUpdateHeroLvStrategy': SMPQuestUpdateHeroLvStrategy,
     'SMPQuestKillNTimeBigBossStrategy': SMPQuestKillNTimeBigBossStrategy,
     'SMPQuestUpgradeSupportNTimeStrategy': SMPQuestUpgradeSupportNTimeStrategy,
     'SMPQuestUpdatePetNameLevelNTimeStrategy': SMPQuestUpdatePetNameLevelNTimeStrategy,
     'SMPQuestMakeNComboHitBossTypeStrategy': SMPQuestMakeNComboHitBossTypeStrategy,
     'SMPQuestMakeNComboHitStrategy': SMPQuestMakeNComboHitStrategy,
     'SMPQuestMakeNHeroReviveStrategy': SMPQuestMakeNHeroReviveStrategy,
     'SMPQuestCompleteNQuestStrategy': SMPQuestCompleteNQuestStrategy,
     'SMPQuestMakeReviveNSupportStrategy': SMPQuestMakeReviveNSupportStrategy,
     'SMPQuestPlayTimeNMinuteStrategy': SMPQuestPlayTimeNMinuteStrategy,

     'SMPQuestPlayTimeNHourStrategy': SMPQuestPlayTimeNHourStrategy,
     'SMPQuestPlayTimeNDayStrategy': SMPQuestPlayTimeNDayStrategy,
     'SMPQuestObtainNBirdWeekStrategy': SMPQuestObtainNBirdWeekStrategy,
     'SMPQuestObtainNPowerUpAndUseStrategy': SMPQuestObtainNPowerUpAndUseStrategy,
     'SMPQuestOpenChestNTimeStrategy': SMPQuestOpenChestNTimeStrategy,
     'SMPQuestClearNBossConsecutivlyStrategy': SMPQuestClearNBossConsecutivlyStrategy,
     'SMPQuestPlayNTimeStrategy': SMPQuestPlayNTimeStrategy,
     'SMPQuestPlayMastermindNTimeDuringDayStrategy': SMPQuestPlayMastermindNTimeDuringDayStrategy,
     'SMPQuestPlayMastermindNTimeStrategy': SMPQuestPlayMastermindNTimeStrategy,
     'SMPQuestPlayOnWeekendStrategy': SMPQuestPlayOnWeekendStrategy,
     'SMPQuestUpgradeNHeroReachRankStrategy': SMPQuestUpgradeNHeroReachRankStrategy,
     'SMPQuestReachNMastermindColorStrategy': SMPQuestReachNMastermindColorStrategy,
     'SMPQuestReachStageStrategy': SMPQuestReachStageStrategy,
     'SMPQuestReachNDPSStrategy': SMPQuestReachNDPSStrategy,
     'SMPQuestReduceGhostRoundStrategy': SMPQuestReduceGhostRoundStrategy,
     'SMPQuestMakeReviveAllNTimeStrategy': SMPQuestMakeReviveAllNTimeStrategy,
     'SMPQuestMakeHeroReviveNTimeStrategy': SMPQuestMakeHeroReviveNTimeStrategy,
     'SMPQuestMakeSupportReviveNTimeStrategy': SMPQuestMakeSupportReviveNTimeStrategy,
     'SMPQuestShareToFacebookStrategy': SMPQuestShareToFacebookStrategy,
     'SMPQuestShareToTwitterStrategy': SMPQuestShareToTwitterStrategy,

     'SMPQuestSwipeNRuneHorizontalNTimeStrategy': SMPQuestSwipeNRuneHorizontalNTimeStrategy,
     'SMPQuestSwipeNRuneOneRowNTimeStrategy': SMPQuestSwipeNRuneOneRowNTimeStrategy,
     'SMPQuestSwipeNRuneVerticalNTimeStrategy': SMPQuestSwipeNRuneVerticalNTimeStrategy,
     'SMPQuestCriticalHitInNSecondStrategy': SMPQuestCriticalHitInNSecondStrategy,
     'SMPQuestTapNTimeInSecondStrategy': SMPQuestTapNTimeInSecondStrategy,
     'SMPQuestTrainPowerUpOfNHeroStrategy': SMPQuestTrainPowerUpOfNHeroStrategy,
     'SMPQuestUnlockAllSupportNEvolveStrategy': SMPQuestUnlockAllSupportNEvolveStrategy,
     'SMPQuestUnlockNHeroPowerUpStrategy': SMPQuestUnlockNHeroPowerUpStrategy,
     'SMPQuestUnlockNSupportSkillStrategy': SMPQuestUnlockNSupportSkillStrategy,
     'SMPQuestUnlockSupportReachNEvolveStrategy': SMPQuestUnlockSupportReachNEvolveStrategy,
     'SMPQuestWorldNameStrategy': SMPQuestWorldNameStrategy,
     'SMPQuestUpdateAPetLevelNTimeStrategy': SMPQuestUpdateAPetLevelNTimeStrategy,
     'SMPQuestUpgradeNPetLvReachStrategy': SMPQuestUpgradeNPetLvReachStrategy,
     'SMPQuestUpgradeNPetStrategy': SMPQuestUpgradeNPetStrategy,

     'SMPQuestHeroReachRankStrategy': SMPQuestHeroReachRankStrategy,
     'SMPQuestDefaultStrategy': SMPQuestDefaultStrategy,

     'SMPQuestCountWorldTourStrategy': SMPQuestCountWorldTourStrategy,
     'SMPQuestFirstWorldTourStrategy': SMPQuestFirstWorldTourStrategy,
     'SMPQuestUpgradePowerupNTimeStrategy': SMPQuestUpgradePowerupNTimeStrategy,
     'SMPQuestUpdateHeroLvReachStrategy': SMPQuestUpdateHeroLvReachStrategy,
     'SMPQuestUpgradeNHeroToLvStrategy': SMPQuestUpgradeNHeroToLvStrategy,
     'SMPQuestUpgradeSupportNRankStrategy': SMPQuestUpgradeSupportNRankStrategy,
     'SMPQuestUpgradeSupportNReachStrategy': SMPQuestUpgradeSupportNReachStrategy,
     'SMPQuestCountNFruitMatchByColorStrategy': SMPQuestCountNFruitMatchByColorStrategy,
     'SMPQuestVideoAdsStrategy': SMPQuestVideoAdsStrategy,
     'SMPQuestWinMastermindNTimeStrategy': SMPQuestWinMastermindNTimeStrategy,
     'SMPQuestUseNPerkNTimeStrategy': SMPQuestUseNPerkNTimeStrategy,
     'SMPQuestUsePerkMakeItRainStrategy': SMPQuestUsePerkMakeItRainStrategy,
     'SMPQuestUsePerkDoomStrategy': SMPQuestUsePerkDoomStrategy,
     'SMPQuestUsePerkManaPortionStrategy': SMPQuestUsePerkManaPortionStrategy,
     'SMPQuestUsePerkPowerOfHoldingStrategy': SMPQuestUsePerkPowerOfHoldingStrategy,
     'SMPQuestUseNSkillNTimeStrategy': SMPQuestUseNSkillNTimeStrategy,
     'SMPQuestUseSkillNTimeStrategy': SMPQuestUseSkillNTimeStrategy,

     'SMPQuestTutorialStrategy': SMPQuestTutorialStrategy,

     'SMPDailyQuestCollectDiamondFromBirdStrategy': SMPDailyQuestCollectDiamondFromBirdStrategy,
     'SMPDailyQuestGoldCollectStrategy': SMPDailyQuestGoldCollectStrategy,
     'SMPDailyQuestKillGhostStrategy': SMPDailyQuestKillGhostStrategy,
     'SMPDailyQuestOpenChestStrategy': SMPDailyQuestOpenChestStrategy,
     'SMPDailyQuestPlayTimeNMinutesStrategy': SMPDailyQuestPlayTimeNMinutesStrategy,
     'SMPDailyQuestTapStrategy': SMPDailyQuestTapStrategy,
     'SMPDailyQuestVideoAdsStrategy': SMPDailyQuestVideoAdsStrategy
};

export {SMPQuestStrategyClassConstructors}