<template>
    <v-card min-width="500"
            max-width="500"
    >
        <v-file-input
                v-model="file"
                label="JSON input"
                accept="application/JSON"
                @change="onChangeInput"
        >
        </v-file-input>
        <v-btn
                @click="doImportClick"
                :disabled="jsonResult===''">
            Import this data
        </v-btn>
        <v-btn
                @click="close">
            Cancel
        </v-btn>
        <div v-html="errorMessages"></div>
        <div v-html="jsonViewResult"></div>
    </v-card>
</template>

<script>
    export default {
        name: "ImportJsonBrowser.vue",
        data() {
            return {
                file: undefined,
                jsonViewResult: '',
                errorMessages: '',
                jsonResult: '',
            }
        },
        methods: {
            clear(){
                this.errorMessages = '';
                this.jsonResult = '';
                this.jsonViewResult = '';
            },
            removeAllRef(){
                this.clear();
                this.file = undefined;
            },
            onChangeInput(){
                this.clear();
                try{
                    if(this.file){
                        const fr = new FileReader();
                        fr.onload = e => {
                            if(e.target.result){
                                const result = JSON.parse(e.target.result);
                                const formatted = JSON.stringify(result, null, 2);
                                this.jsonResult = result;
                                this.jsonViewResult = '<br />Preview:<br /><pre>'+ formatted+ '</pre><br />';
                            }
                        };
                        fr.readAsText(this.file);
                    }
                }catch (e) {
                    this.errorMessages = '\nInvalid import file!!!\n'+e;
                }
            },
            doImportClick() {
                this.$emit('import', this.jsonResult);
                this.close();
            },
            close(){
                this.$emit('close');
                this.removeAllRef();
            },
        },
    }
</script>

<style scoped>

</style>