const SMPQuestDefaultStrategy = require("@/quest-editor/questImpl/strategies/SMPQuestDefaultStrategy");
const HeroRankType = require("@/quest-editor/constants/HeroRankType");

class SMPQuestUpgradeAllHeroReachRankStrategy extends SMPQuestDefaultStrategy {

     increaseKPIGameplayReference() {
          this.questContext.KPIGameplayBossLevel += this.questContext.JumpingLevel;
     }

     computeChallenge() {
          if (this.questContext.challengeTarget < HeroRankType.Platinum) {
               this.questContext.challengeTarget += 1;
          }
     }

     computeLevelShouldAppear () {
          let shouldAppearAt = this.questContext.KPIGameplayBossLevel - 202;
          if (shouldAppearAt < 1) shouldAppearAt = 1;
          this.questContext.KPIGameLevelShouldAppear = shouldAppearAt;
     }
}

module.exports = SMPQuestUpgradeAllHeroReachRankStrategy