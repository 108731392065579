const SMPQuestDefaultStrategy = require("@/quest-editor/questImpl/strategies/SMPQuestDefaultStrategy");
const {MAX_PET} = require("@/quest-editor/constants/GameConstant");

class SMPQuestUpdatePetNameLevelNTimeStrategy extends SMPQuestDefaultStrategy {

     initStartingValue() {
          this._challengeList = [1, 2, 3];
     }

     increaseKPIGameplayReference() {
          this.questContext.KPIGameplayBossLevel += this.questContext.JumpingLevel;
     }

     computeChallenge() {
          let Progress = this.questContext.ProgressModel;
          if (Progress.petId < MAX_PET + 1) {
               Progress.petId += 1;
               if(Progress.petId == 6) {//skip id 6
                    Progress.petId += 1;
               }
          } else {
               Progress.petId = 1;
               this.questContext.challengeTarget = this.getTargetBaseOnLevel(this.questContext.level);
          }
     }

     computeLevelShouldAppear () {
          let shouldAppearAt = this.questContext.KPIGameplayBossLevel - 50 * this.questContext.challengeTarget;
          if (shouldAppearAt < 1) shouldAppearAt = 1;
          this.questContext.KPIGameLevelShouldAppear = shouldAppearAt;
     }
}

module.exports = SMPQuestUpdatePetNameLevelNTimeStrategy