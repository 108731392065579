module.exports = {
     getStageForUnlockNextLevel(level, addLevel){
          return (level + addLevel) * 235
     },

     getMaxLevelCanUnlockOnStage(stage, currentLevel){
       let lvUnlock = (stage/235) - currentLevel;
       if (lvUnlock < 1) {
            return 0;
       }

       return lvUnlock;
     }
}