<template>
  <v-dialog
      :persistent="this.pIgnoreClose"
      v-model="this.shouldShow"
      :width="this.width"
      @click:outside="close()"
      @keydown.esc="close()"
  >
    <v-container fluid class="hk_modal">
      <slot></slot>
    </v-container>
  </v-dialog>
</template>

<script>
export default {
  name: "SmpDialog",
  props: {
    'pIgnoreClose':{
      type: Boolean,
      default: false
    },
    'width':{
      default: "auto"
    },
    'shouldShow': {
      type: Boolean,
      default: false
    },
  },
  methods: {
    close: function () {
      if (!this.pIgnoreClose){
        this.$emit('close');
      }
    }
  }
}
</script>

<style scoped>
.hk_modal {
  background: #fff;
}
</style>