const SMPQuestDefaultStrategy = require("@/quest-editor/questImpl/strategies/SMPQuestDefaultStrategy");

class SMPQuestUseNSkillNTimeStrategy extends SMPQuestDefaultStrategy {

     initStartingValue() {
          this._challengeList = [5, 2, 3, 4, 5, 6, 7, 8, 4, 8, 10];//nSkill
          this._challenge2List = [2, 3, 2, 2, 1, 2, 2, 1, 3, 2, 1];//nTime
     }

     increaseKPIGameplayReference() {
          this.questContext.KPIGameplayBossLevel += this.questContext.JumpingLevel;
     }

     computeChallenge() {
          this.questContext.challengeTarget = this.getTargetBaseOnLevel(this.questContext.level);
          this.questContext.challengeTarget2 = this.getTarget2BaseOnLevel(this.questContext.level);
     }

     computeLevelShouldAppear() {
          let shouldAppearAt = this.questContext.KPIGameplayBossLevel - this.questContext.challengeTarget2 * 50;
          if (shouldAppearAt < 1) shouldAppearAt = 1;
          this.questContext.KPIGameLevelShouldAppear = shouldAppearAt;
     }
}

module.exports = SMPQuestUseNSkillNTimeStrategy;