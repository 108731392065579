const SMPQuestDefaultStrategy = require("@/quest-editor/questImpl/strategies/SMPQuestDefaultStrategy");
class SMPQuestBeatNStageStrategy extends SMPQuestDefaultStrategy {

     initStartingValue() {
          this._challengeList = [2,2,3,3,2,3,3,4,4,3,2,4,5,5,4,3,5];
     }

     increaseKPIGameplayReference() {
          this.questContext.KPIGameplayBossLevel += this.questContext.JumpingLevel;
     }

     computeChallenge() {
          this.questContext.challengeTarget = this.getTargetBaseOnLevel(this.questContext.level);
     }

     computeLevelShouldAppear () {
          let shouldAppearAt = this.questContext.KPIGameplayBossLevel - this.questContext.challengeTarget;
          if (shouldAppearAt < 1) shouldAppearAt = 1;
          this.questContext.KPIGameLevelShouldAppear = shouldAppearAt;
     }
}

module.exports = SMPQuestBeatNStageStrategy;