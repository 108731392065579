const SMPQuestDefaultStrategy = require("@/quest-editor/questImpl/strategies/SMPQuestDefaultStrategy");
class SMPQuestPlayTimeNMinuteStrategy extends SMPQuestDefaultStrategy {

     initStartingValue() {
          this._challengeList = [5,6,7,8,9,10];
     }

     increaseKPIGameplayReference() {
          let preCheck = this.getTargetBaseOnLevel(this.questContext.level);
          this.questContext.KPIGameplayBossLevel += this.questContext.JumpingLevel * preCheck;
     }

     computeChallenge() {
          this.questContext.challengeTarget = this.getTargetBaseOnLevel(this.questContext.level);
     }

     computeLevelShouldAppear() {
          let shouldAppearAt = this.questContext.KPIGameplayBossLevel - (this.questContext.challengeTarget * this.questContext.JumpingLevel);
          if (shouldAppearAt < 1) shouldAppearAt = 1;
          this.questContext.KPIGameLevelShouldAppear = shouldAppearAt;
     }
}

module.exports = SMPQuestPlayTimeNMinuteStrategy;