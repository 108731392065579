<template>
    <v-row justify="center">
        <v-dialog
                v-model="showSupportForm"
                width="700"
        >
            <supportSkillViewer :pSkillList="this.$store.state.skillList" :should-init="showSupportForm" @close="showSupportForm=false"/>

        </v-dialog>

        <v-dialog
                v-model="showSupportUnlockForm"
                width="500"
        >
            <SupportUnlockViewer :pSupportUnlock="this.$store.state.unlockSupportSetting" :should-init="showSupportUnlockForm" @close="showSupportUnlockForm=false"/>

        </v-dialog>

        <v-dialog
                v-model="pShowSettings"
                fullscreen
                hide-overlay
                transition="dialog-bottom-transition"
        >
            <v-card>
                <v-toolbar
                        dark
                        color="primary"
                >

                    <v-btn
                            icon
                            dark
                            @click="closeSettings()"
                    >
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                    <v-toolbar-title>Settings</v-toolbar-title>
                    <v-spacer></v-spacer>
                    <span v-if="complete" class="green--text">Balance Builder Updated</span>
                    <v-toolbar-items>
                        <v-btn
                                dark
                                text
                                @click="doSave()"
                        >
                            Save
                        </v-btn>
                    </v-toolbar-items>
                    <v-toolbar-items>
                        <v-btn
                                dark
                                text
                                @click="doSaveAndExport()"
                        >
                            Save & Export
                        </v-btn>
                    </v-toolbar-items>
                    <v-toolbar-items>
                        <v-btn
                                dark
                                text
                                @click="pushToUGS()"
                        >
                            Push Balance Config to {{this.$store.state.ugsSetting.environmentName}}
                        </v-btn>
                    </v-toolbar-items>
                    <v-toolbar-items>
                        <v-btn
                                dark
                                text
                                @click="pushDevToUGS()"
                        >
                            Push Balance_Dev Config to {{this.$store.state.ugsSetting.environmentName}}
                        </v-btn>
                    </v-toolbar-items>
                    <!-- not push to strapi any more
                    <v-toolbar-items>
                        <v-btn
                            dark
                            text
                            @click="pushToProduction"
                        >
                            Push to game
                        </v-btn>
                    </v-toolbar-items>
                    <v-toolbar-items>
                        <v-btn
                                dark
                                text
                                @click="pushToDevelopment"
                        >
                            Push to game dev
                        </v-btn>
                    </v-toolbar-items>
                    -->
                    <v-toolbar-items>
                        <v-btn
                                dark
                                text
                                @click="doExportClick()"
                        >
                            Export
                        </v-btn>
                    </v-toolbar-items>
                    <v-toolbar-items>
                        <v-btn
                                dark
                                text
                                @click="doImportClick()"
                        >
                            Import
                        </v-btn>
                    </v-toolbar-items>
                </v-toolbar>
                <v-list
                        three-line
                        subheader
                >
                    <v-subheader> First Term for the Drop Coins </v-subheader>
                    <v-list-item>
                        <v-text-field label="K_GAME_INTRO" v-model="dropCoinsGameFirstIntro" />
                        <v-text-field label="K_GAME_TUNNEL_1" v-model="dropCoinsFirstTunnel1"/>
                        <v-text-field label="K_GAME_TUNNEL_2" v-model="dropCoinsFirstTunnel2"/>
                        <v-text-field label="K_GAME_TUNNEL_3" v-model="dropCoinsFirstTunnel3"/>
                        <v-text-field label="K_GAME_DEFAULT" v-model="dropCoinsFirstDefault"/>
                    </v-list-item>
                    <v-subheader class="yellow lighten-2"> Common Ratio for the Drop Coins </v-subheader>
                    <v-list-item class="yellow lighten-4">
                        <v-text-field label="K_GAME_INTRO" v-model="dropCoinsGameIntro" />
                        <v-text-field label="K_GAME_TUNNEL_1" v-model="dropCoinsTunnel1"/>
                        <v-text-field label="K_GAME_TUNNEL_2" v-model="dropCoinsTunnel2"/>
                        <v-text-field label="K_GAME_TUNNEL_3" v-model="dropCoinsTunnel3"/>
                        <v-text-field label="K_GAME_DEFAULT" v-model="dropCoinsDefault"/>
                    </v-list-item>

                    <v-subheader> First Term for the Cost of Hero Level Up </v-subheader>
                    <v-list-item>
                        <v-text-field label="K_GAME_INTRO" v-model="costHeroLevelUpFirstIntro" />
                        <v-text-field label="K_GAME_TUNNEL_1" v-model="costHeroLevelUpFirstTunnel1"/>
                        <v-text-field label="K_GAME_TUNNEL_2" v-model="costHeroLevelUpFirstTunnel2"/>
                        <v-text-field label="K_GAME_TUNNEL_3" v-model="costHeroLevelUpFirstTunnel3"/>
                        <v-text-field label="K_GAME_DEFAULT" v-model="costHeroLevelUpFirstDefault"/>
                    </v-list-item>
                    <v-subheader class="blue lighten-2"> Common Ratio for the Cost of Hero Level Up </v-subheader>
                    <v-list-item class="blue lighten-4">
                        <v-text-field label="K_GAME_INTRO" v-model="costHeroLevelUpIntro" />
                        <v-text-field label="K_GAME_TUNNEL_1" v-model="costHeroLevelUpTunnel1"/>
                        <v-text-field label="K_GAME_TUNNEL_2" v-model="costHeroLevelUpTunnel2"/>
                        <v-text-field label="K_GAME_TUNNEL_3" v-model="costHeroLevelUpTunnel3"/>
                        <v-text-field label="K_GAME_DEFAULT" v-model="costHeroLevelUpDefault"/>
                    </v-list-item>

                    <v-subheader> First Term for the HP Ghost </v-subheader>
                    <v-list-item>
                        <v-text-field :label="levelRangeOfSequenceDefault('K_GAME_INTRO',0)" v-model="baseHPGhostFirstIntro" />
                        <v-text-field :label="levelRangeOfSequenceDefault('K_GAME_TUNNEL_1', 1)" v-model="baseHPGhostFirstTunnel1"/>
                        <v-text-field :label="levelRangeOfSequenceDefault('K_GAME_TUNNEL_2', 2)" v-model="baseHPGhostFirstTunnel2"/>
                        <v-text-field :label="levelRangeOfSequenceDefault('K_GAME_TUNNEL_3', 3)" v-model="baseHPGhostFirstTunnel3"/>
                        <v-text-field label="K_GAME_DEFAULT" v-model="baseHPGhostFirstDefault"/>
                    </v-list-item>
                    <v-subheader> Common Ratio for the HP Ghost </v-subheader>
                    <v-list-item>
                        <v-text-field :label="levelRangeOfSequenceDefault('K_GAME_INTRO',0)" v-model="baseHPGhostIntro" />
                        <v-text-field :label="levelRangeOfSequenceDefault('K_GAME_TUNNEL_1', 1)" v-model="baseHPGhostTunnel1"/>
                        <v-text-field :label="levelRangeOfSequenceDefault('K_GAME_TUNNEL_2', 2)" v-model="baseHPGhostTunnel2"/>
                        <v-text-field :label="levelRangeOfSequenceDefault('K_GAME_TUNNEL_3', 3)" v-model="baseHPGhostTunnel3"/>
                        <v-text-field label="K_GAME_DEFAULT" v-model="baseHPGhostDefault"/>
                    </v-list-item>

                    <v-subheader> First Term for the HP Normal Boss </v-subheader>
                    <v-list-item>
                        <v-text-field :disabled="isEnableHpBossMultiplier" label="K_GAME_INTRO" v-model="baseHPNormalBossFirstIntro" />
                        <v-text-field :disabled="isEnableHpBossMultiplier" label="K_GAME_TUNNEL_1" v-model="baseHPNormalBossFirstTunnel1"/>
                        <v-text-field :disabled="isEnableHpBossMultiplier" label="K_GAME_TUNNEL_2" v-model="baseHPNormalBossFirstTunnel2"/>
                        <v-text-field :disabled="isEnableHpBossMultiplier" label="K_GAME_TUNNEL_3" v-model="baseHPNormalBossFirstTunnel3"/>
                        <v-text-field :disabled="isEnableHpBossMultiplier" label="K_GAME_DEFAULT" v-model="baseHPNormalBossFirstDefault"/>
                    </v-list-item>
                    <v-subheader> Common Ratio for the HP Normal Boss </v-subheader>
                    <v-list-item>
                        <v-text-field :disabled="isEnableHpBossMultiplier" label="K_GAME_INTRO" v-model="baseHPNormalBossIntro" />
                        <v-text-field :disabled="isEnableHpBossMultiplier" label="K_GAME_TUNNEL_1" v-model="baseHPNormalBossTunnel1"/>
                        <v-text-field :disabled="isEnableHpBossMultiplier" label="K_GAME_TUNNEL_2" v-model="baseHPNormalBossTunnel2"/>
                        <v-text-field :disabled="isEnableHpBossMultiplier" label="K_GAME_TUNNEL_3" v-model="baseHPNormalBossTunnel3"/>
                        <v-text-field :disabled="isEnableHpBossMultiplier" label="K_GAME_DEFAULT" v-model="baseHPNormalBossDefault"/>
                    </v-list-item>

                    <v-subheader> First Term for the HP Zone Boss </v-subheader>
                    <v-list-item>
                        <v-text-field :disabled="isEnableHpBossMultiplier" label="K_GAME_INTRO" v-model="baseHPZoneBossFirstIntro" />
                        <v-text-field :disabled="isEnableHpBossMultiplier" label="K_GAME_TUNNEL_1" v-model="baseHPZoneBossFirstTunnel1"/>
                        <v-text-field :disabled="isEnableHpBossMultiplier" label="K_GAME_TUNNEL_2" v-model="baseHPZoneBossFirstTunnel2"/>
                        <v-text-field :disabled="isEnableHpBossMultiplier" label="K_GAME_TUNNEL_3" v-model="baseHPZoneBossFirstTunnel3"/>
                        <v-text-field :disabled="isEnableHpBossMultiplier" label="K_GAME_DEFAULT" v-model="baseHPZoneBossFirstDefault"/>
                    </v-list-item>
                    <v-subheader> Common Ratio for the HP Zone Boss </v-subheader>
                    <v-list-item>
                        <v-text-field :disabled="isEnableHpBossMultiplier" label="K_GAME_INTRO" v-model="baseHPZoneBossIntro" />
                        <v-text-field :disabled="isEnableHpBossMultiplier" label="K_GAME_TUNNEL_1" v-model="baseHPZoneBossTunnel1"/>
                        <v-text-field :disabled="isEnableHpBossMultiplier" label="K_GAME_TUNNEL_2" v-model="baseHPZoneBossTunnel2"/>
                        <v-text-field :disabled="isEnableHpBossMultiplier" label="K_GAME_TUNNEL_3" v-model="baseHPZoneBossTunnel3"/>
                        <v-text-field :disabled="isEnableHpBossMultiplier" label="K_GAME_DEFAULT" v-model="baseHPZoneBossDefault"/>
                    </v-list-item>

                    <v-subheader> Hp multiplier with Ghost HP </v-subheader>
                    <v-list-item>
                        <v-card min-width="220"
                                max-width="220"
                                class="pl-0 pr-5 pt-2 pb-0"
                                elevation="0"
                        >
                            <v-select
                                    :items="hpBossOptions"
                                    v-model="selectedHpBossOption"
                                    label="Boss Hp Option"
                                    item-text="label"
                                    item-value="list"
                                    dense
                                    outlined
                            ></v-select>
                        </v-card>
                        <v-text-field :disabled="!isEnableHpBossMultiplier" label="K_NORMAL_BOSS_HP_MULTIPLIER" v-model="normalBossHPMultiplier" />
                        <v-text-field :disabled="!isEnableHpBossMultiplier" label="K_ZONE_BOSS_HP_MULTIPLIER" v-model="zoneBossHPMultiplier" />
                    </v-list-item>

                    <v-subheader> First Term for the Dmg Boss  </v-subheader>
                    <v-list-item>
                        <v-text-field label="K_GAME_INTRO" v-model="dmgBossFirstIntro" />
                        <v-text-field label="K_GAME_TUNNEL_1" v-model="dmgBossFirstTunnel1"/>
                        <v-text-field label="K_GAME_TUNNEL_2" v-model="dmgBossFirstTunnel2"/>
                        <v-text-field label="K_GAME_TUNNEL_3" v-model="dmgBossFirstTunnel3"/>
                        <v-text-field label="K_GAME_DEFAULT" v-model="dmgBossFirstDefault"/>
                    </v-list-item>
                    <v-subheader> Common Ratio for the Dmg Boss  </v-subheader>
                    <v-list-item>
                        <v-text-field label="K_GAME_INTRO" v-model="dmgBossCommonIntro" />
                        <v-text-field label="K_GAME_TUNNEL_1" v-model="dmgBossCommonTunnel1"/>
                        <v-text-field label="K_GAME_TUNNEL_2" v-model="dmgBossCommonTunnel2"/>
                        <v-text-field label="K_GAME_TUNNEL_3" v-model="dmgBossCommonTunnel3"/>
                        <v-text-field label="K_GAME_DEFAULT" v-model="dmgBossCommonDefault"/>
                    </v-list-item>

                    <v-subheader> First Term for the Base Dmg hero  </v-subheader>
                    <v-list-item>
                        <v-text-field label="K_GAME_INTRO" v-model="baseDmgFirstIntro" />
                        <v-text-field label="K_GAME_TUNNEL_1" v-model="baseDmgFirstTunnel1"/>
                        <v-text-field label="K_GAME_TUNNEL_2" v-model="baseDmgFirstTunnel2"/>
                        <v-text-field label="K_GAME_TUNNEL_3" v-model="baseDmgFirstTunnel3"/>
                        <v-text-field label="K_GAME_DEFAULT" v-model="baseDmgFirstDefault"/>
                    </v-list-item>
                    <v-subheader> Common Ratio for the Base Dmg hero  </v-subheader>
                    <v-list-item>
                        <v-text-field label="K_GAME_INTRO" v-model="baseDmgIntro" />
                        <v-text-field label="K_GAME_TUNNEL_1" v-model="baseDmgTunnel1"/>
                        <v-text-field label="K_GAME_TUNNEL_2" v-model="baseDmgTunnel2"/>
                        <v-text-field label="K_GAME_TUNNEL_3" v-model="baseDmgTunnel3"/>
                        <v-text-field label="K_GAME_DEFAULT" v-model="baseDmgDefault"/>
                    </v-list-item>

                    <v-subheader> First Term for the Cost of Support Level Up </v-subheader>
                    <v-list-item>
                        <v-text-field label="K_GAME_INTRO" v-model="costSupportLevelUpFirstIntro" />
                        <v-text-field label="K_GAME_TUNNEL_1" v-model="costSupportLevelUpFirstTunnel1"/>
                        <v-text-field label="K_GAME_TUNNEL_2" v-model="costSupportLevelUpFirstTunnel2"/>
                        <v-text-field label="K_GAME_TUNNEL_3" v-model="costSupportLevelUpFirstTunnel3"/>
                        <v-text-field label="K_GAME_DEFAULT" v-model="costSupportLevelUpFirstDefault"/>
                    </v-list-item>
                    <v-subheader> Common Ratio for the Cost of Support Level Up </v-subheader>
                    <v-list-item>
                        <v-text-field label="K_GAME_INTRO" v-model="costSupportLevelUpIntro" />
                        <v-text-field label="K_GAME_TUNNEL_1" v-model="costSupportLevelUpTunnel1"/>
                        <v-text-field label="K_GAME_TUNNEL_2" v-model="costSupportLevelUpTunnel2"/>
                        <v-text-field label="K_GAME_TUNNEL_3" v-model="costSupportLevelUpTunnel3"/>
                        <v-text-field label="K_GAME_DEFAULT" v-model="costSupportLevelUpDefault"/>
                    </v-list-item>

                    <v-subheader> First Term for the Base Dmg of Support </v-subheader>
                    <v-list-item>
                        <v-text-field label="K_GAME_INTRO" v-model="supportBaseDmgFirstIntro" />
                        <v-text-field label="K_GAME_TUNNEL_1" v-model="supportBaseDmgFirstTunnel1"/>
                        <v-text-field label="K_GAME_TUNNEL_2" v-model="supportBaseDmgFirstTunnel2"/>
                        <v-text-field label="K_GAME_TUNNEL_3" v-model="supportBaseDmgFirstTunnel3"/>
                        <v-text-field label="K_GAME_DEFAULT" v-model="supportBaseDmgFirstDefault"/>
                    </v-list-item>
                    <v-subheader> Common Ratio for the Base Dmg of Support  </v-subheader>
                    <v-list-item>
                        <v-text-field label="K_GAME_INTRO" v-model="supportBaseDmgIntro" />
                        <v-text-field label="K_GAME_TUNNEL_1" v-model="supportBaseDmgTunnel1"/>
                        <v-text-field label="K_GAME_TUNNEL_2" v-model="supportBaseDmgTunnel2"/>
                        <v-text-field label="K_GAME_TUNNEL_3" v-model="supportBaseDmgTunnel3"/>
                        <v-text-field label="K_GAME_DEFAULT" v-model="supportBaseDmgDefault"/>
                    </v-list-item>

                    <v-subheader> Setting Gold for Enemy level</v-subheader>
                    <v-list-item>
                        <v-text-field label="GHOST_COUNTER_PER_LV" v-model="ghostCounterPerLv" />
                        <v-text-field label="BOSS_COUNTER_PER_LV" v-model="bossCounterPerLv" />
                        <v-text-field label="ZONE_BOSS_COUNTER_PER_LV" v-model="zoneBossCounterPerLv" />
                        <v-text-field label="BOSS_GOLD_GHOST_RATIO" v-model="bossGoldGhostRatio" />
                        <v-text-field label="ZONE_BOSS_GOLD_GHOST_RATIO" v-model="zoneBossGoldGhostRatio" />
                    </v-list-item>

                    <v-subheader> Setting Diamond</v-subheader>
                    <v-list-item>
                        <v-text-field label="DIAMOND_GAME_START_UP" v-model="diamondGameStartup" />
                        <v-text-field label="STAGE_COUNTER_TO_DROP_DIAMOND" v-model="stageCounterToDropDiamond" />
                    </v-list-item>

                    <v-subheader> Hit per seconds </v-subheader>
                    <v-list-item>
                        <v-text-field label="K_HIT_PER_SECONDS" v-model="hitPerSeconds" />
                        <v-text-field label="K_HIT_BOSS_PER_SECONDS" v-model="hitBossPerSeconds" />
                    </v-list-item>

                    <v-subheader> Control gold bonus from support skill and pet skill </v-subheader>
                    <v-list-item>
                        <v-text-field label="PERCENT_SUPPORT_GOLD_DROP" v-model="percentSupportGoldDropSkillControl" />
                        <v-text-field label="PERCENT_PET_GOLD_DROP" v-model="percentPetGoldDropSkillControl" />
                    </v-list-item>

                    <v-subheader> Control dispatch of Golden cat and Bird </v-subheader>
                    <v-list-item>
                        <v-text-field label="LEVEL_STAGE_RANGE_NEXT" v-model="levelStageRangeForNextGoldBoostAppear" />
                        <v-text-field label="TTKB_NECESSARY_TO_GET_GOLD_BOOST" v-model="ttkbNecessaryToGetGoldBoost" />
                    </v-list-item>

                    <v-subheader> Control Offline gold </v-subheader>
                    <v-list-item>
                        <v-text-field label="PERCENT_OFFLINE_GOLD" v-model="percentOfflineGoldControl" />
                        <v-text-field label="MAX_GHOST_FARMING_OFFLINE_GOLD" v-model="maxGhostFarmingOfflineGold" />
                        <v-text-field label="HIT_GHOST_OFFLINE_GOLD_PER_SECOND" v-model="hitGhostOfflineGoldPerSecond" />
                    </v-list-item>

                    <v-subheader> Control daily quest</v-subheader>
                    <v-list-item>
                        <v-text-field label="GAME_LEVEL_PER_NEW_DAY" v-model="dailyGameLevelPerDay" />
                    </v-list-item>

                    <v-list-item>
                        <div >

                            <v-btn class="mr-5" v-on:click="doSave"> Save</v-btn>
                            <v-btn class="mr-5" v-on:click="doClickChangeSupport"> Change Support skill</v-btn>
                            <v-btn class="mr-5" v-on:click="doClickUnlockSupport"> Change Support unlock</v-btn>
                        </div>
                    </v-list-item>
                </v-list>
                <v-divider></v-divider>
            </v-card>
        </v-dialog>

        <SmpDialog
                :should-show="this.isShowImportBrowser"
                p-ignore-close
                @close="isShowImportBrowser=false"
        >
            <ImportJsonBrowser
                    @import="onImportJson"
                    @close="isShowImportBrowser=false"/>
        </SmpDialog>

        <v-overlay :value="isLoading">
            <v-progress-circular
                    indeterminate
                    size="64"
            ></v-progress-circular>
        </v-overlay>

        <PopupMessage v-if="messageAlert!==''"
                      :pColor="messageColor"
                      :pTimeout="600"
                      @onClose="messageAlert=''"
        >
            {{messageAlert}}
        </PopupMessage>
    </v-row>
</template>

<script>

    import PopupMessage from "@/item-editor/modules/PopupMessage/PopupMessage";
    import SupportSkillViewer from '../../components/SupportSkillViewer.vue';
    import SupportUnlockViewer from '../../components/SupportUnlockSettingViewer.vue';
    import {KpiGlobalEvents, EVENT_ON_CHANGE_BALANCE_SETTINGS} from "@/kpi-editor/events/KpiGlobalEvents";
    import GamePlayRatioApdater from "./../../kpi-editor/classes/GamePlayRatioApdater";
    import ParameterLoader from "./../../dao/ParameterLoader";
    import ItemApi from "@/item-editor/dao/ItemApi";
    import UnityServiceApi from '@/unity-service-dashboard/api/UnityServiceApi';
    import ImportJsonBrowser from './../commons/ImportJsonBrowser';
    import SmpDialog from "@/item-editor/modules/SmpDialog";

    export default {

        props: ["pShowSettings"],

        components: {
            SupportSkillViewer: SupportSkillViewer,
            SupportUnlockViewer,
            PopupMessage,
            ImportJsonBrowser,
            SmpDialog
        },

        data() {
            return {
                isLoading: false,
                messageAlert: '',
                messageColor: 'orange',

                showSupportForm: false,
                showSupportUnlockForm: false,
                farmingSize: null,
                seriesFirstTermGhost: null,
                seriesFirstTermCostHero: null,
                seriesFirstTermDropCoins: null,
                hitPerSeconds: null,
                hitBossPerSeconds: null,

                //setting for baseHPGhost
                baseHPGhostIntro: null,
                baseHPGhostTunnel1: null,
                baseHPGhostTunnel2: null,
                baseHPGhostTunnel3: null,
                baseHPGhostDefault: null,
                baseHPGhostFirstIntro: null,
                baseHPGhostFirstTunnel1: null,
                baseHPGhostFirstTunnel2: null,
                baseHPGhostFirstTunnel3: null,
                baseHPGhostFirstDefault: null,

                //setting for baseDmg
                baseDmgFirstIntro: null,
                baseDmgFirstTunnel1: null,
                baseDmgFirstTunnel2: null,
                baseDmgFirstTunnel3: null,
                baseDmgFirstDefault: null,
                baseDmgIntro: null,
                baseDmgTunnel1: null,
                baseDmgTunnel2: null,
                baseDmgTunnel3: null,
                baseDmgDefault: null,

                //setting for baseDmg support
                supportBaseDmgFirstIntro: null,
                supportBaseDmgFirstTunnel1: null,
                supportBaseDmgFirstTunnel2: null,
                supportBaseDmgFirstTunnel3: null,
                supportBaseDmgFirstDefault: null,
                supportBaseDmgIntro: null,
                supportBaseDmgTunnel1: null,
                supportBaseDmgTunnel2: null,
                supportBaseDmgTunnel3: null,
                supportBaseDmgDefault: null,

                //setting for DMGBoss
                dmgBossFirstIntro: null,
                dmgBossFirstTunnel1: null,
                dmgBossFirstTunnel2: null,
                dmgBossFirstTunnel3: null,
                dmgBossFirstDefault: null,
                dmgBossCommonIntro: null,
                dmgBossCommonTunnel1: null,
                dmgBossCommonTunnel2: null,
                dmgBossCommonTunnel3: null,
                dmgBossCommonDefault: null,

                costHeroLevelUpFirstIntro: null,
                costHeroLevelUpFirstTunnel1: null,
                costHeroLevelUpFirstTunnel2: null,
                costHeroLevelUpFirstTunnel3: null,
                costHeroLevelUpFirstDefault: null,
                costHeroLevelUpIntro: null,
                costHeroLevelUpTunnel1: null,
                costHeroLevelUpTunnel2: null,
                costHeroLevelUpTunnel3: null,
                costHeroLevelUpDefault: null,

                costSupportLevelUpFirstIntro: null,
                costSupportLevelUpFirstTunnel1: null,
                costSupportLevelUpFirstTunnel2: null,
                costSupportLevelUpFirstTunnel3: null,
                costSupportLevelUpFirstDefault: null,
                costSupportLevelUpIntro: null,
                costSupportLevelUpTunnel1: null,
                costSupportLevelUpTunnel2: null,
                costSupportLevelUpTunnel3: null,
                costSupportLevelUpDefault: null,

                dropCoinsGameFirstIntro: null,
                dropCoinsFirstTunnel1: null,
                dropCoinsFirstTunnel2: null,
                dropCoinsFirstTunnel3: null,
                dropCoinsFirstDefault: null,
                dropCoinsGameIntro: null,
                dropCoinsTunnel1: null,
                dropCoinsTunnel2: null,
                dropCoinsTunnel3: null,
                dropCoinsDefault: null,

                //adding for gold formula
                ghostCounterPerLv: 9, //=1 level, appear 9 ghost
                bossCounterPerLv: 0.9, //=10 levels, appear 9 boss
                zoneBossCounterPerLv: 0.1, //=10 levels, appear 1 zone bozz
                bossGoldGhostRatio: 2, //=2 time of ghost gold
                zoneBossGoldGhostRatio: 4, //=4 time of ghost gold

                diamondGameStartup: 90,
                stageCounterToDropDiamond: 10,

                selectedHpBossOption: null,
                hpBossOptions:["Ratio", "Multiplier"],

                baseHPNormalBossFirstIntro: null,
                baseHPNormalBossFirstTunnel1: null,
                baseHPNormalBossFirstTunnel2: null,
                baseHPNormalBossFirstTunnel3: null,
                baseHPNormalBossFirstDefault: null,
                baseHPNormalBossIntro: null,
                baseHPNormalBossTunnel1: null,
                baseHPNormalBossTunnel2: null,
                baseHPNormalBossTunnel3: null,
                baseHPNormalBossDefault: null,

                baseHPZoneBossFirstIntro: null,
                baseHPZoneBossFirstTunnel1: null,
                baseHPZoneBossFirstTunnel2: null,
                baseHPZoneBossFirstTunnel3: null,
                baseHPZoneBossFirstDefault: null,
                baseHPZoneBossIntro: null,
                baseHPZoneBossTunnel1: null,
                baseHPZoneBossTunnel2: null,
                baseHPZoneBossTunnel3: null,
                baseHPZoneBossDefault: null,

                normalBossHPMultiplier: 1.1,
                zoneBossHPMultiplier: 1.2,

                //balance param config
                percentSupportGoldDropSkillControl: 100,
                percentPetGoldDropSkillControl: 100,
                percentOfflineGoldControl: 10,
                maxGhostFarmingOfflineGold: 5,
                levelStageRangeForNextGoldBoostAppear: 10,
                ttkbNecessaryToGetGoldBoost: 20,
                hitGhostOfflineGoldPerSecond: 2,
                dailyGameLevelPerDay: 30,

                dialog: this.pShowSettings,
                widgets: false,
                sound: false,
                notifications: false,
                show: true,
                complete: false,
                isShowImportBrowser: false,
            }
        },

        created: async function () {
            this.initData();
        },

        computed: {
            isEnableHpBossMultiplier(){
                return this.selectedHpBossOption === this.hpBossOptions[1];
            },
        },

        methods: {
            initData(){
                this.selectedHpBossOption = this.$store.state.ratios.selectedHpBossOption;
                if (!this.selectedHpBossOption) {
                    this.selectedHpBossOption = this.hpBossOptions[0];
                }

                this.normalBossHPMultiplier = this.$store.state.ratios.normalBossHPMultiplier;
                if (!this.normalBossHPMultiplier) {
                    this.normalBossHPMultiplier = 1.1;
                    this.zoneBossHPMultiplier = 1.2;
                } else {
                    this.zoneBossHPMultiplier = this.$store.state.ratios.zoneBossHPMultiplier;
                }

                this.farmingSize = this.$store.state.ratios.farmingSize;
                if (!this.farmingSize) {
                    this.farmingSize = 20;
                }

                this.ghostCounterPerLv = this.$store.state.ratios.ghostCounterPerLv;
                if (!this.ghostCounterPerLv) {
                    this.ghostCounterPerLv = 9;
                    this.bossCounterPerLv = 0.9;
                    this.zoneBossCounterPerLv = 0.1;
                    this.bossGoldGhostRatio = 2;
                    this.zoneBossGoldGhostRatio = 4;
                } else {
                    this.ghostCounterPerLv = this.$store.state.ratios.ghostCounterPerLv;
                    this.bossCounterPerLv = this.$store.state.ratios.bossCounterPerLv;
                    this.zoneBossCounterPerLv = this.$store.state.ratios.zoneBossCounterPerLv;
                    this.bossGoldGhostRatio = this.$store.state.ratios.bossGoldGhostRatio;
                    this.zoneBossGoldGhostRatio = this.$store.state.ratios.zoneBossGoldGhostRatio;
                }

                this.diamondGameStartup = this.$store.state.ratios.diamondGameStartup;
                if (!this.diamondGameStartup) {
                    this.diamondGameStartup = 90;
                    this.stageCounterToDropDiamond = 10;
                } else {
                    this.stageCounterToDropDiamond = this.$store.state.ratios.stageCounterToDropDiamond;
                }

                this.seriesFirstTermGhost = this.$store.state.ratios.seriesFirstTermGhost;
                if (!this.seriesFirstTermGhost) {
                    this.seriesFirstTermGhost = 1000;
                }

                this.seriesFirstTermDropCoins = this.$store.state.ratios.seriesFirstTermDropCoins;
                if (!this.seriesFirstTermDropCoins) {
                    this.seriesFirstTermDropCoins = 1;
                }

                this.seriesFirstTermCostHero = this.$store.state.ratios.seriesFirstTermCostHero;
                if (!this.seriesFirstTermCostHero) {
                    this.seriesFirstTermCostHero = 1000;
                }

                this.hitPerSeconds = this.$store.state.ratios.hitPerSeconds;
                if (!this.hitPerSeconds) {
                    this.hitPerSeconds = 4;
                }

                this.hitBossPerSeconds = this.$store.state.ratios.hitBossPerSeconds;
                if (!this.hitBossPerSeconds) {
                    this.hitBossPerSeconds = 0;
                }

                if (!this.dropCoinsDefault) {
                    this.dropCoinsGameIntro = this.$store.state.ratios.dropCoinsRatio.dropCoinsGameIntro;
                    this.dropCoinsTunnel1 = this.$store.state.ratios.dropCoinsRatio.dropCoinsTunnel1;
                    this.dropCoinsTunnel2 = this.$store.state.ratios.dropCoinsRatio.dropCoinsTunnel2;
                    this.dropCoinsTunnel3 = this.$store.state.ratios.dropCoinsRatio.dropCoinsTunnel3;
                    this.dropCoinsDefault = this.$store.state.ratios.dropCoinsRatio.dropCoinsDefault;
                }

                if(!this.dropCoinsFirstDefault){
                    if(this.$store.state.ratios.dropCoinsFirstTerm){
                        this.dropCoinsGameFirstIntro = this.$store.state.ratios.dropCoinsFirstTerm.dropCoinsGameFirstIntro;
                        this.dropCoinsFirstTunnel1 = this.$store.state.ratios.dropCoinsFirstTerm.dropCoinsFirstTunnel1;
                        this.dropCoinsFirstTunnel2 = this.$store.state.ratios.dropCoinsFirstTerm.dropCoinsFirstTunnel2;
                        this.dropCoinsFirstTunnel3 = this.$store.state.ratios.dropCoinsFirstTerm.dropCoinsFirstTunnel3;
                        this.dropCoinsFirstDefault = this.$store.state.ratios.dropCoinsFirstTerm.dropCoinsFirstDefault;
                    } else {
                        this.dropCoinsGameFirstIntro = 1;
                        this.dropCoinsFirstTunnel1 = 1;
                        this.dropCoinsFirstTunnel2 = 1;
                        this.dropCoinsFirstTunnel3 = 1;
                        this.dropCoinsFirstDefault = 1;
                    }
                }

                if (!this.costHeroLevelUpDefault) {
                    this.costHeroLevelUpIntro = this.$store.state.ratios.costHeroLevelUpRatio.costHeroLevelUpIntro;
                    this.costHeroLevelUpTunnel1 = this.$store.state.ratios.costHeroLevelUpRatio.costHeroLevelUpTunnel1;
                    this.costHeroLevelUpTunnel2 = this.$store.state.ratios.costHeroLevelUpRatio.costHeroLevelUpTunnel2;
                    this.costHeroLevelUpTunnel3 = this.$store.state.ratios.costHeroLevelUpRatio.costHeroLevelUpTunnel3;
                    this.costHeroLevelUpDefault = this.$store.state.ratios.costHeroLevelUpRatio.costHeroLevelUpDefault;
                }
                if(!this.costHeroLevelUpFirstDefault){
                    if(this.$store.state.ratios.costHeroLevelUpFirstTerm){
                        this.costHeroLevelUpFirstIntro = this.$store.state.ratios.costHeroLevelUpFirstTerm.costHeroLevelUpFirstIntro;
                        this.costHeroLevelUpFirstTunnel1 = this.$store.state.ratios.costHeroLevelUpFirstTerm.costHeroLevelUpFirstTunnel1;
                        this.costHeroLevelUpFirstTunnel2 = this.$store.state.ratios.costHeroLevelUpFirstTerm.costHeroLevelUpFirstTunnel2;
                        this.costHeroLevelUpFirstTunnel3 = this.$store.state.ratios.costHeroLevelUpFirstTerm.costHeroLevelUpFirstTunnel3;
                        this.costHeroLevelUpFirstDefault = this.$store.state.ratios.costHeroLevelUpFirstTerm.costHeroLevelUpFirstDefault;
                    } else{
                        this.costHeroLevelUpFirstIntro = this.$store.state.ratios.seriesFirstTermCostHero;
                        this.costHeroLevelUpFirstTunnel1 = this.$store.state.ratios.seriesFirstTermCostHero;
                        this.costHeroLevelUpFirstTunnel2 = this.$store.state.ratios.seriesFirstTermCostHero;
                        this.costHeroLevelUpFirstTunnel3 = this.$store.state.ratios.seriesFirstTermCostHero;
                        this.costHeroLevelUpFirstDefault = this.$store.state.ratios.seriesFirstTermCostHero;
                    }
                }

                if(!this.costSupportLevelUpFirstDefault){
                    if(this.$store.state.ratios.costSupportLevelUpFirstTerm){
                        this.costSupportLevelUpFirstIntro = this.$store.state.ratios.costSupportLevelUpFirstTerm.costSupportLevelUpFirstIntro;
                        this.costSupportLevelUpFirstTunnel1 = this.$store.state.ratios.costSupportLevelUpFirstTerm.costSupportLevelUpFirstTunnel1;
                        this.costSupportLevelUpFirstTunnel2 = this.$store.state.ratios.costSupportLevelUpFirstTerm.costSupportLevelUpFirstTunnel2;
                        this.costSupportLevelUpFirstTunnel3 = this.$store.state.ratios.costSupportLevelUpFirstTerm.costSupportLevelUpFirstTunnel3;
                        this.costSupportLevelUpFirstDefault = this.$store.state.ratios.costSupportLevelUpFirstTerm.costSupportLevelUpFirstDefault;
                    } else {
                        this.costSupportLevelUpFirstIntro = 5;
                        this.costSupportLevelUpFirstTunnel1 = 10;
                        this.costSupportLevelUpFirstTunnel2 = 30;
                        this.costSupportLevelUpFirstTunnel3 = 40;
                        this.costSupportLevelUpFirstDefault = 50;
                    }
                }
                if (!this.costSupportLevelUpDefault) {
                    this.costSupportLevelUpIntro = this.$store.state.ratios.costSupportLevelUpRatio.costSupportLevelUpIntro;
                    this.costSupportLevelUpTunnel1 = this.$store.state.ratios.costSupportLevelUpRatio.costSupportLevelUpTunnel1;
                    this.costSupportLevelUpTunnel2 = this.$store.state.ratios.costSupportLevelUpRatio.costSupportLevelUpTunnel2;
                    this.costSupportLevelUpTunnel3 = this.$store.state.ratios.costSupportLevelUpRatio.costSupportLevelUpTunnel3;
                    this.costSupportLevelUpDefault = this.$store.state.ratios.costSupportLevelUpRatio.costSupportLevelUpDefault;
                }

                if (!this.baseDmgDefault) {
                    this.baseDmgIntro = this.$store.state.ratios.baseDmgRatio.baseDmgIntro;
                    this.baseDmgTunnel1 = this.$store.state.ratios.baseDmgRatio.baseDmgTunnel1;
                    this.baseDmgTunnel2 = this.$store.state.ratios.baseDmgRatio.baseDmgTunnel2;
                    this.baseDmgTunnel3 = this.$store.state.ratios.baseDmgRatio.baseDmgTunnel3;
                    this.baseDmgDefault = this.$store.state.ratios.baseDmgRatio.baseDmgDefault;
                }

                if(!this.baseDmgFirstDefault){
                    if(this.$store.state.ratios.baseDmgFirstTerm){
                        this.baseDmgFirstIntro = this.$store.state.ratios.baseDmgFirstTerm.baseDmgFirstIntro;
                        this.baseDmgFirstTunnel1 = this.$store.state.ratios.baseDmgFirstTerm.baseDmgFirstTunnel1;
                        this.baseDmgFirstTunnel2 = this.$store.state.ratios.baseDmgFirstTerm.baseDmgFirstTunnel2;
                        this.baseDmgFirstTunnel3 = this.$store.state.ratios.baseDmgFirstTerm.baseDmgFirstTunnel3;
                        this.baseDmgFirstDefault = this.$store.state.ratios.baseDmgFirstTerm.baseDmgFirstDefault;
                    } else {
                        this.baseDmgFirstIntro = 1;
                        this.baseDmgFirstTunnel1 = 1;
                        this.baseDmgFirstTunnel2 = 1;
                        this.baseDmgFirstTunnel3 = 1;
                        this.baseDmgFirstDefault = 1;
                    }
                }

                if(!this.dmgBossFirstDefault) {
                    if (this.$store.state.ratios.dmgBossFirstTerm) {
                        this.dmgBossFirstIntro = this.$store.state.ratios.dmgBossFirstTerm.dmgBossFirstIntro;
                        this.dmgBossFirstTunnel1 = this.$store.state.ratios.dmgBossFirstTerm.dmgBossFirstTunnel1;
                        this.dmgBossFirstTunnel2 = this.$store.state.ratios.dmgBossFirstTerm.dmgBossFirstTunnel2;
                        this.dmgBossFirstTunnel3 = this.$store.state.ratios.dmgBossFirstTerm.dmgBossFirstTunnel3;
                        this.dmgBossFirstDefault = this.$store.state.ratios.dmgBossFirstTerm.dmgBossFirstDefault;

                        this.dmgBossCommonIntro = this.$store.state.ratios.dmgBossCommonRatio.dmgBossCommonIntro;
                        this.dmgBossCommonTunnel1 = this.$store.state.ratios.dmgBossCommonRatio.dmgBossCommonTunnel1;
                        this.dmgBossCommonTunnel2 = this.$store.state.ratios.dmgBossCommonRatio.dmgBossCommonTunnel2;
                        this.dmgBossCommonTunnel3 = this.$store.state.ratios.dmgBossCommonRatio.dmgBossCommonTunnel3;
                        this.dmgBossCommonDefault = this.$store.state.ratios.dmgBossCommonRatio.dmgBossCommonDefault;
                    } else {
                        this.dmgBossFirstIntro = 1;
                        this.dmgBossFirstTunnel1 = 1;
                        this.dmgBossFirstTunnel2 = 1;
                        this.dmgBossFirstTunnel3 = 1;
                        this.dmgBossFirstDefault = 1;

                        this.dmgBossCommonIntro = this.$store.state.ratios.baseDmgRatio.baseDmgIntro;
                        this.dmgBossCommonTunnel1 = this.$store.state.ratios.baseDmgRatio.baseDmgTunnel1;
                        this.dmgBossCommonTunnel2 = this.$store.state.ratios.baseDmgRatio.baseDmgTunnel2;
                        this.dmgBossCommonTunnel3 = this.$store.state.ratios.baseDmgRatio.baseDmgTunnel3;
                        this.dmgBossCommonDefault = this.$store.state.ratios.baseDmgRatio.baseDmgDefault;
                    }
                }

                if(!this.supportBaseDmgFirstDefault){
                    if(this.$store.state.ratios.supportDmgFirstTerm){
                        this.supportBaseDmgFirstIntro = this.$store.state.ratios.supportDmgFirstTerm.supportBaseDmgFirstIntro;
                        this.supportBaseDmgFirstTunnel1 = this.$store.state.ratios.supportDmgFirstTerm.supportBaseDmgFirstTunnel1;
                        this.supportBaseDmgFirstTunnel2 = this.$store.state.ratios.supportDmgFirstTerm.supportBaseDmgFirstTunnel2;
                        this.supportBaseDmgFirstTunnel3 = this.$store.state.ratios.supportDmgFirstTerm.supportBaseDmgFirstTunnel3;
                        this.supportBaseDmgFirstDefault = this.$store.state.ratios.supportDmgFirstTerm.supportBaseDmgFirstDefault;
                    } else {
                        this.supportBaseDmgFirstIntro = 1;
                        this.supportBaseDmgFirstTunnel1 = 2;
                        this.supportBaseDmgFirstTunnel2 = 3;
                        this.supportBaseDmgFirstTunnel3 = 4;
                        this.supportBaseDmgFirstDefault = 5;
                    }
                }

                if (!this.supportBaseDmgDefault) {
                    this.supportBaseDmgIntro = this.$store.state.ratios.supportDmgRatio.supportBaseDmgIntro;
                    this.supportBaseDmgTunnel1 = this.$store.state.ratios.supportDmgRatio.supportBaseDmgTunnel1;
                    this.supportBaseDmgTunnel2 = this.$store.state.ratios.supportDmgRatio.supportBaseDmgTunnel2;
                    this.supportBaseDmgTunnel3 = this.$store.state.ratios.supportDmgRatio.supportBaseDmgTunnel3;
                    this.supportBaseDmgDefault = this.$store.state.ratios.supportDmgRatio.supportBaseDmgDefault;
                }

                if (!this.baseHPGhostDefault) {
                    this.baseHPGhostIntro = this.$store.state.ratios.baseHPGhostRatio.baseHPGhostIntro;
                    this.baseHPGhostTunnel1 = this.$store.state.ratios.baseHPGhostRatio.baseHPGhostTunnel1;
                    this.baseHPGhostTunnel2 = this.$store.state.ratios.baseHPGhostRatio.baseHPGhostTunnel2;
                    this.baseHPGhostTunnel3 = this.$store.state.ratios.baseHPGhostRatio.baseHPGhostTunnel3;
                    this.baseHPGhostDefault = this.$store.state.ratios.baseHPGhostRatio.baseHPGhostDefault;
                }

                if(!this.baseHPGhostFirstDefault){
                    if(this.$store.state.ratios.baseHPGhostFirstTerm){
                        this.baseHPGhostFirstIntro = this.$store.state.ratios.baseHPGhostFirstTerm.baseHPGhostFirstIntro;
                        this.baseHPGhostFirstTunnel1 = this.$store.state.ratios.baseHPGhostFirstTerm.baseHPGhostFirstTunnel1;
                        this.baseHPGhostFirstTunnel2 = this.$store.state.ratios.baseHPGhostFirstTerm.baseHPGhostFirstTunnel2;
                        this.baseHPGhostFirstTunnel3 = this.$store.state.ratios.baseHPGhostFirstTerm.baseHPGhostFirstTunnel3;
                        this.baseHPGhostFirstDefault = this.$store.state.ratios.baseHPGhostFirstTerm.baseHPGhostFirstDefault;
                    } else {
                        this.baseHPGhostFirstIntro = 20;
                        this.baseHPGhostFirstTunnel1 = 20;
                        this.baseHPGhostFirstTunnel2 = 20;
                        this.baseHPGhostFirstTunnel3 = 20;
                        this.baseHPGhostFirstDefault = 20;
                    }
                }

                if (!this.baseHPNormalBossDefault) {
                    if(this.$store.state.ratios.baseHPNormalBossRatio){
                        this.baseHPNormalBossIntro = this.$store.state.ratios.baseHPNormalBossRatio.baseHPNormalBossIntro;
                        this.baseHPNormalBossTunnel1 = this.$store.state.ratios.baseHPNormalBossRatio.baseHPNormalBossTunnel1;
                        this.baseHPNormalBossTunnel2 = this.$store.state.ratios.baseHPNormalBossRatio.baseHPNormalBossTunnel2;
                        this.baseHPNormalBossTunnel3 = this.$store.state.ratios.baseHPNormalBossRatio.baseHPNormalBossTunnel3;
                        this.baseHPNormalBossDefault = this.$store.state.ratios.baseHPNormalBossRatio.baseHPNormalBossDefault;
                    } else {
                        this.baseHPNormalBossIntro = this.$store.state.ratios.baseHPGhostRatio.baseHPGhostIntro;
                        this.baseHPNormalBossTunnel1 = this.$store.state.ratios.baseHPGhostRatio.baseHPGhostTunnel1;
                        this.baseHPNormalBossTunnel2 = this.$store.state.ratios.baseHPGhostRatio.baseHPGhostTunnel2;
                        this.baseHPNormalBossTunnel3 = this.$store.state.ratios.baseHPGhostRatio.baseHPGhostTunnel3;
                        this.baseHPNormalBossDefault = this.$store.state.ratios.baseHPGhostRatio.baseHPGhostDefault;
                    }
                }

                if(!this.baseHPNormalBossFirstDefault){
                    if(this.$store.state.ratios.baseHPNormalBossFirstTerm){
                        this.baseHPNormalBossFirstIntro = this.$store.state.ratios.baseHPNormalBossFirstTerm.baseHPNormalBossFirstIntro;
                        this.baseHPNormalBossFirstTunnel1 = this.$store.state.ratios.baseHPNormalBossFirstTerm.baseHPNormalBossFirstTunnel1;
                        this.baseHPNormalBossFirstTunnel2 = this.$store.state.ratios.baseHPNormalBossFirstTerm.baseHPNormalBossFirstTunnel2;
                        this.baseHPNormalBossFirstTunnel3 = this.$store.state.ratios.baseHPNormalBossFirstTerm.baseHPNormalBossFirstTunnel3;
                        this.baseHPNormalBossFirstDefault = this.$store.state.ratios.baseHPNormalBossFirstTerm.baseHPNormalBossFirstDefault;
                    } else {
                        this.baseHPNormalBossFirstIntro = 20;
                        this.baseHPNormalBossFirstTunnel1 = 20;
                        this.baseHPNormalBossFirstTunnel2 = 20;
                        this.baseHPNormalBossFirstTunnel3 = 20;
                        this.baseHPNormalBossFirstDefault = 20;
                    }
                }

                if (!this.baseHPZoneBossDefault) {
                    if(this.$store.state.ratios.baseHPZoneBossRatio){
                        this.baseHPZoneBossIntro = this.$store.state.ratios.baseHPZoneBossRatio.baseHPZoneBossIntro;
                        this.baseHPZoneBossTunnel1 = this.$store.state.ratios.baseHPZoneBossRatio.baseHPZoneBossTunnel1;
                        this.baseHPZoneBossTunnel2 = this.$store.state.ratios.baseHPZoneBossRatio.baseHPZoneBossTunnel2;
                        this.baseHPZoneBossTunnel3 = this.$store.state.ratios.baseHPZoneBossRatio.baseHPZoneBossTunnel3;
                        this.baseHPZoneBossDefault = this.$store.state.ratios.baseHPZoneBossRatio.baseHPZoneBossDefault;
                    } else {
                        this.baseHPZoneBossIntro = this.$store.state.ratios.baseHPGhostRatio.baseHPGhostIntro;
                        this.baseHPZoneBossTunnel1 = this.$store.state.ratios.baseHPGhostRatio.baseHPGhostTunnel1;
                        this.baseHPZoneBossTunnel2 = this.$store.state.ratios.baseHPGhostRatio.baseHPGhostTunnel2;
                        this.baseHPZoneBossTunnel3 = this.$store.state.ratios.baseHPGhostRatio.baseHPGhostTunnel3;
                        this.baseHPZoneBossDefault = this.$store.state.ratios.baseHPGhostRatio.baseHPGhostDefault;
                    }
                }

                if(!this.baseHPZoneBossFirstDefault){
                    if(this.$store.state.ratios.baseHPZoneBossFirstTerm){
                        this.baseHPZoneBossFirstIntro = this.$store.state.ratios.baseHPZoneBossFirstTerm.baseHPZoneBossFirstIntro;
                        this.baseHPZoneBossFirstTunnel1 = this.$store.state.ratios.baseHPZoneBossFirstTerm.baseHPZoneBossFirstTunnel1;
                        this.baseHPZoneBossFirstTunnel2 = this.$store.state.ratios.baseHPZoneBossFirstTerm.baseHPZoneBossFirstTunnel2;
                        this.baseHPZoneBossFirstTunnel3 = this.$store.state.ratios.baseHPZoneBossFirstTerm.baseHPZoneBossFirstTunnel3;
                        this.baseHPZoneBossFirstDefault = this.$store.state.ratios.baseHPZoneBossFirstTerm.baseHPZoneBossFirstDefault;
                    } else {
                        this.baseHPZoneBossFirstIntro = 20;
                        this.baseHPZoneBossFirstTunnel1 = 20;
                        this.baseHPZoneBossFirstTunnel2 = 20;
                        this.baseHPZoneBossFirstTunnel3 = 20;
                        this.baseHPZoneBossFirstDefault = 20;
                    }
                }

                if(this.$store.state.ratios.ttkbNecessaryToGetGoldBoost) {
                    this.percentSupportGoldDropSkillControl = this.$store.state.ratios.percentSupportGoldDropSkillControl;
                    this.percentPetGoldDropSkillControl = this.$store.state.ratios.percentPetGoldDropSkillControl;
                    this.percentOfflineGoldControl = this.$store.state.ratios.percentOfflineGoldControl;
                    this.maxGhostFarmingOfflineGold = this.$store.state.ratios.maxGhostFarmingOfflineGold;
                    this.levelStageRangeForNextGoldBoostAppear = this.$store.state.ratios.levelStageRangeForNextGoldBoostAppear;
                    this.ttkbNecessaryToGetGoldBoost = this.$store.state.ratios.ttkbNecessaryToGetGoldBoost;
                }

                if(this.$store.state.ratios.hitGhostOfflineGoldPerSecond){
                    this.hitGhostOfflineGoldPerSecond = this.$store.state.ratios.hitGhostOfflineGoldPerSecond;
                }

                if(this.$store.state.ratios.dailyGameLevelPerDay){
                    this.dailyGameLevelPerDay = this.$store.state.ratios.dailyGameLevelPerDay;
                }
            },

            levelRangeOfSequenceDefault(des, index){
                let start = this.$store.state.gamePlayDataService.mathGamePlay.mathCore.resultSequenceDefault[index].start;
                let max = this.$store.state.gamePlayDataService.mathGamePlay.mathCore.resultSequenceDefault[index].max;

                return `${des} [${start}-${max}]`;
            },

            closeSettings() {
                this.$emit('closeParameters');
            },

            async doClickChangeSupport() {
                this.showSupportForm = true;
            },

            async doClickUnlockSupport() {
                this.showSupportUnlockForm = true;
            },

            applyConfigData() {

                let dropCoinsFirstTerm = {
                    dropCoinsGameFirstIntro: this.dropCoinsGameFirstIntro,
                    dropCoinsFirstTunnel1: this.dropCoinsFirstTunnel1,
                    dropCoinsFirstTunnel2: this.dropCoinsFirstTunnel2,
                    dropCoinsFirstTunnel3: this.dropCoinsFirstTunnel3,
                    dropCoinsFirstDefault: this.dropCoinsFirstDefault
                };

                let dropCoinsRatio = {
                    dropCoinsGameIntro: this.dropCoinsGameIntro,
                    dropCoinsTunnel1: this.dropCoinsTunnel1,
                    dropCoinsTunnel2: this.dropCoinsTunnel2,
                    dropCoinsTunnel3: this.dropCoinsTunnel3,
                    dropCoinsDefault: this.dropCoinsDefault
                };

                let baseDmgFirstTerm = {
                    baseDmgFirstIntro: this.baseDmgFirstIntro,
                    baseDmgFirstTunnel1: this.baseDmgFirstTunnel1,
                    baseDmgFirstTunnel2: this.baseDmgFirstTunnel2,
                    baseDmgFirstTunnel3: this.baseDmgFirstTunnel3,
                    baseDmgFirstDefault: this.baseDmgFirstDefault
                };

                let baseDmgRatio = {
                    baseDmgIntro: this.baseDmgIntro,
                    baseDmgTunnel1: this.baseDmgTunnel1,
                    baseDmgTunnel2: this.baseDmgTunnel2,
                    baseDmgTunnel3: this.baseDmgTunnel3,
                    baseDmgDefault: this.baseDmgDefault
                };

                let dmgBossFirstTerm = {
                    dmgBossFirstIntro: this.dmgBossFirstIntro,
                    dmgBossFirstTunnel1: this.dmgBossFirstTunnel1,
                    dmgBossFirstTunnel2: this.dmgBossFirstTunnel2,
                    dmgBossFirstTunnel3: this.dmgBossFirstTunnel3,
                    dmgBossFirstDefault: this.dmgBossFirstDefault
                };

                let dmgBossCommonRatio = {
                    dmgBossCommonIntro: this.dmgBossCommonIntro,
                    dmgBossCommonTunnel1: this.dmgBossCommonTunnel1,
                    dmgBossCommonTunnel2: this.dmgBossCommonTunnel2,
                    dmgBossCommonTunnel3: this.dmgBossCommonTunnel3,
                    dmgBossCommonDefault: this.dmgBossCommonDefault
                };

                let supportDmgFirstTerm = {
                    supportBaseDmgFirstIntro: this.supportBaseDmgFirstIntro,
                    supportBaseDmgFirstTunnel1: this.supportBaseDmgFirstTunnel1,
                    supportBaseDmgFirstTunnel2: this.supportBaseDmgFirstTunnel2,
                    supportBaseDmgFirstTunnel3: this.supportBaseDmgFirstTunnel3,
                    supportBaseDmgFirstDefault: this.supportBaseDmgFirstDefault
                };

                let supportDmgRatio = {
                    supportBaseDmgIntro: this.supportBaseDmgIntro,
                    supportBaseDmgTunnel1: this.supportBaseDmgTunnel1,
                    supportBaseDmgTunnel2: this.supportBaseDmgTunnel2,
                    supportBaseDmgTunnel3: this.supportBaseDmgTunnel3,
                    supportBaseDmgDefault: this.supportBaseDmgDefault
                };

                let baseHPGhostFirstTerm = {
                    baseHPGhostFirstIntro: this.baseHPGhostFirstIntro,
                    baseHPGhostFirstTunnel1: this.baseHPGhostFirstTunnel1,
                    baseHPGhostFirstTunnel2: this.baseHPGhostFirstTunnel2,
                    baseHPGhostFirstTunnel3: this.baseHPGhostFirstTunnel3,
                    baseHPGhostFirstDefault: this.baseHPGhostFirstDefault
                };

                let baseHPGhostRatio = {
                    baseHPGhostIntro: this.baseHPGhostIntro,
                    baseHPGhostTunnel1: this.baseHPGhostTunnel1,
                    baseHPGhostTunnel2: this.baseHPGhostTunnel2,
                    baseHPGhostTunnel3: this.baseHPGhostTunnel3,
                    baseHPGhostDefault: this.baseHPGhostDefault
                };

                let baseHPNormalBossFirstTerm = {
                    baseHPNormalBossFirstIntro: this.baseHPNormalBossFirstIntro,
                    baseHPNormalBossFirstTunnel1: this.baseHPNormalBossFirstTunnel1,
                    baseHPNormalBossFirstTunnel2: this.baseHPNormalBossFirstTunnel2,
                    baseHPNormalBossFirstTunnel3: this.baseHPNormalBossFirstTunnel3,
                    baseHPNormalBossFirstDefault: this.baseHPNormalBossFirstDefault
                };

                let baseHPNormalBossRatio = {
                    baseHPNormalBossIntro: this.baseHPNormalBossIntro,
                    baseHPNormalBossTunnel1: this.baseHPNormalBossTunnel1,
                    baseHPNormalBossTunnel2: this.baseHPNormalBossTunnel2,
                    baseHPNormalBossTunnel3: this.baseHPNormalBossTunnel3,
                    baseHPNormalBossDefault: this.baseHPNormalBossDefault
                };

                let baseHPZoneBossFirstTerm = {
                    baseHPZoneBossFirstIntro: this.baseHPZoneBossFirstIntro,
                    baseHPZoneBossFirstTunnel1: this.baseHPZoneBossFirstTunnel1,
                    baseHPZoneBossFirstTunnel2: this.baseHPZoneBossFirstTunnel2,
                    baseHPZoneBossFirstTunnel3: this.baseHPZoneBossFirstTunnel3,
                    baseHPZoneBossFirstDefault: this.baseHPZoneBossFirstDefault
                };

                let baseHPZoneBossRatio = {
                    baseHPZoneBossIntro: this.baseHPZoneBossIntro,
                    baseHPZoneBossTunnel1: this.baseHPZoneBossTunnel1,
                    baseHPZoneBossTunnel2: this.baseHPZoneBossTunnel2,
                    baseHPZoneBossTunnel3: this.baseHPZoneBossTunnel3,
                    baseHPZoneBossDefault: this.baseHPZoneBossDefault
                };

                let costHeroLevelUpFirstTerm = {
                    costHeroLevelUpFirstIntro: this.costHeroLevelUpFirstIntro,
                    costHeroLevelUpFirstTunnel1: this.costHeroLevelUpFirstTunnel1,
                    costHeroLevelUpFirstTunnel2: this.costHeroLevelUpFirstTunnel2,
                    costHeroLevelUpFirstTunnel3: this.costHeroLevelUpFirstTunnel3,
                    costHeroLevelUpFirstDefault: this.costHeroLevelUpFirstDefault
                };

                let costHeroLevelUpRatio = {
                    costHeroLevelUpIntro: this.costHeroLevelUpIntro,
                    costHeroLevelUpTunnel1: this.costHeroLevelUpTunnel1,
                    costHeroLevelUpTunnel2: this.costHeroLevelUpTunnel2,
                    costHeroLevelUpTunnel3: this.costHeroLevelUpTunnel3,
                    costHeroLevelUpDefault: this.costHeroLevelUpDefault
                };

                let costSupportLevelUpFirstTerm = {
                    costSupportLevelUpFirstIntro: this.costSupportLevelUpFirstIntro,
                    costSupportLevelUpFirstTunnel1: this.costSupportLevelUpFirstTunnel1,
                    costSupportLevelUpFirstTunnel2: this.costSupportLevelUpFirstTunnel2,
                    costSupportLevelUpFirstTunnel3: this.costSupportLevelUpFirstTunnel3,
                    costSupportLevelUpFirstDefault: this.costSupportLevelUpFirstDefault
                };

                let costSupportLevelUpRatio = {
                    costSupportLevelUpIntro: this.costSupportLevelUpIntro,
                    costSupportLevelUpTunnel1: this.costSupportLevelUpTunnel1,
                    costSupportLevelUpTunnel2: this.costSupportLevelUpTunnel2,
                    costSupportLevelUpTunnel3: this.costSupportLevelUpTunnel3,
                    costSupportLevelUpDefault: this.costSupportLevelUpDefault
                };

                let ratios = {
                    dropCoinsFirstTerm: dropCoinsFirstTerm,
                    dropCoinsRatio: dropCoinsRatio,
                    baseDmgFirstTerm: baseDmgFirstTerm,
                    baseDmgRatio: baseDmgRatio,
                    dmgBossFirstTerm: dmgBossFirstTerm,
                    dmgBossCommonRatio: dmgBossCommonRatio,
                    supportDmgFirstTerm: supportDmgFirstTerm,
                    supportDmgRatio: supportDmgRatio,
                    baseHPGhostFirstTerm: baseHPGhostFirstTerm,
                    baseHPGhostRatio: baseHPGhostRatio,
                    baseHPNormalBossFirstTerm: baseHPNormalBossFirstTerm,
                    baseHPNormalBossRatio: baseHPNormalBossRatio,
                    baseHPZoneBossFirstTerm: baseHPZoneBossFirstTerm,
                    baseHPZoneBossRatio: baseHPZoneBossRatio,
                    seriesFirstTermGhost: this.seriesFirstTermGhost,
                    costHeroLevelUpFirstTerm: costHeroLevelUpFirstTerm,
                    seriesFirstTermCostHero: this.seriesFirstTermCostHero,
                    seriesFirstTermDropCoins: this.seriesFirstTermDropCoins,
                    hitPerSeconds: this.hitPerSeconds,
                    hitBossPerSeconds: this.hitBossPerSeconds,
                    costHeroLevelUpRatio: costHeroLevelUpRatio,
                    costSupportLevelUpFirstTerm: costSupportLevelUpFirstTerm,
                    costSupportLevelUpRatio: costSupportLevelUpRatio,
                    farmingSize: this.farmingSize,
                    ghostCounterPerLv: this.ghostCounterPerLv,
                    bossCounterPerLv: this.bossCounterPerLv,
                    zoneBossCounterPerLv: this.zoneBossCounterPerLv,
                    bossGoldGhostRatio: this.bossGoldGhostRatio,
                    zoneBossGoldGhostRatio: this.zoneBossGoldGhostRatio,
                    diamondGameStartup: this.diamondGameStartup,
                    stageCounterToDropDiamond: this.stageCounterToDropDiamond,
                    normalBossHPMultiplier: this.normalBossHPMultiplier,
                    zoneBossHPMultiplier: this.zoneBossHPMultiplier,
                    selectedHpBossOption: this.selectedHpBossOption,

                    percentSupportGoldDropSkillControl: this.percentSupportGoldDropSkillControl,
                    percentPetGoldDropSkillControl: this.percentPetGoldDropSkillControl,
                    percentOfflineGoldControl: this.percentOfflineGoldControl,
                    maxGhostFarmingOfflineGold: this.maxGhostFarmingOfflineGold,
                    levelStageRangeForNextGoldBoostAppear: this.levelStageRangeForNextGoldBoostAppear,
                    ttkbNecessaryToGetGoldBoost: this.ttkbNecessaryToGetGoldBoost,
                    hitGhostOfflineGoldPerSecond: this.hitGhostOfflineGoldPerSecond,
                    dailyGameLevelPerDay: Number(this.dailyGameLevelPerDay),
                };

                let configData = {
                    ratios: ratios,
                };
                return configData;
            },

            async doSave() {
                let configData = this.applyConfigData();
                this.$store.commit(this.$store.state.commitActions.INIT_RATIOS, configData.ratios);
                //this.$store.commit(this.$store.state.commitActions.INIT_BALANCE, configData.formatted);
                this.$store.commit(this.$store.state.commitActions.UPDATE_GAMEPLAY_SERVICE_DATA, configData.ratios);
                await this.$store.commit(this.$store.state.commitActions.PUSH_RATIOS, this.$store.state.ratios);

                this.complete = true;

                setTimeout(() => {
                    KpiGlobalEvents.$emit(EVENT_ON_CHANGE_BALANCE_SETTINGS);
                    this.complete = false
                }, 1000);
            },

            async doSaveAndExport(){
                await this.doSave();
                this.doExportClick();
            },

            getGamePlayRatioWithAllConfig(){
                let configData = this.applyConfigData();
                let supportSkill = this.$store.state.skillList;
                let supportUnlock = this.$store.state.unlockSupportSetting;
                //convert from ratio editor to ratio like game play
                return GamePlayRatioApdater.getGamePlayRatioWithAllConfig(configData.ratios, supportUnlock, supportSkill);
            },

            doExportClick() {
                let ratioLikeGamePlay = this.getGamePlayRatioWithAllConfig();
                let dataStr = JSON.stringify(ratioLikeGamePlay, null, 2);
                ParameterLoader.exportData(dataStr);
            },
            doImportClick() {
                this.isShowImportBrowser = true;
            },
            onImportJson(json){
                this.isShowImportBrowser = false;
                if(json !== ''){

                    let supportSkill = this.$store.state.skillList;
                    let supportUnlock = this.$store.state.unlockSupportSetting;
                    let editorRatio = this.$store.state.ratios;

                    GamePlayRatioApdater.setEditorRatioFromJSonGamePlay(editorRatio, supportUnlock, supportSkill, json);

                    this.$store.commit(this.$store.state.commitActions.PUSH_SKILL_SUPPORTS, supportSkill);
                    this.$store.commit(this.$store.state.commitActions.PUSH_SUPPORT_UNLOCK_SETTING, supportUnlock);
                    this.$store.commit(this.$store.state.commitActions.UPDATE_GAMEPLAY_SERVICE_DATA, editorRatio);
                    this.$store.commit(this.$store.state.commitActions.PUSH_RATIOS, editorRatio);

                    this.setAlertMessage('Import success!', false);

                    this.isLoading = true;
                    setTimeout(() => {
                        this.$emit("reload");
                        this.isLoading = false;
                        KpiGlobalEvents.$emit(EVENT_ON_CHANGE_BALANCE_SETTINGS);
                    }, 250);

                } else {
                    this.setAlertMessage('Import failed!', true);
                }
            },
            pushToProduction() {
                let ratioLikeGamePlay = this.getGamePlayRatioWithAllConfig();
                ItemApi.updateItemToProd({json: ratioLikeGamePlay})
                    .then( result => {
                        console.log("RESULTS:")
                        console.log(result);
                        this.complete = true;

                        setTimeout(() => {
                            this.complete = false
                        }, 1000);
                    })
                    .catch(error => {
                        console.log(error);
                    });
            },
            pushToDevelopment() {
                let ratioLikeGamePlay = this.getGamePlayRatioWithAllConfig();
                ItemApi.updateItemToDevelopment({json: ratioLikeGamePlay})
                    .then( result => {
                        console.log("RESULTS:")
                        console.log(result);
                        this.complete = true;

                        setTimeout(() => {
                            this.complete = false
                        }, 1000);
                    })
                    .catch(error => {
                        console.log(error);
                    });
            },
            pushToUGS() {
                this.isLoading = true;
                let ratioLikeGamePlay = this.getGamePlayRatioWithAllConfig();
                UnityServiceApi.updateRemoteConfig(ratioLikeGamePlay, 'Balance Config', this.$store.state.ugsSetting.environmentId)
                    .then(result => {
                        this.setAlertMessage(result.data.message, result.data.status !== 200);
                        setTimeout(() => {
                            this.isLoading = false;
                        }, 1500);

                    })
                    .catch(error => {
                        this.setAlertMessage('Failed to add remote config: '+error, true);
                        setTimeout(() => {
                            this.isLoading = false;
                        }, 200);
                    });
            },
            pushDevToUGS() {
                this.isLoading = true;
                let ratioLikeGamePlay = this.getGamePlayRatioWithAllConfig();
                UnityServiceApi.updateRemoteConfig(ratioLikeGamePlay, 'Balance_Dev Config', this.$store.state.ugsSetting.environmentId)
                    .then(result => {
                        this.setAlertMessage(result.data.message, result.data.status !== 200);
                        setTimeout(() => {
                            this.isLoading = false;
                        }, 1500);

                    })
                    .catch(error => {
                        this.setAlertMessage('Failed to add remote config: '+error, true);
                        setTimeout(() => {
                            this.isLoading = false;
                        }, 200);
                    });
            },
            setAlertMessage(msg, isError){
                this.messageAlert = msg;
                if(isError){
                    this.messageColor = "red";
                } else {
                    this.messageColor = "success";
                }
            }
        }
    }

</script>
