const SMPQuestDefaultStrategy = require("@/quest-editor/questImpl/strategies/SMPQuestDefaultStrategy");
const SMPNum = require("@/SMPNum");

class SMPDailyQuestCollectDiamondFromBirdStrategy extends SMPQuestDefaultStrategy {

     initStartingValue() {
          this._challengeList = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9];
     }

     setStartingLevelStep(step){
          this._levelStart = (this.questContext.level - 1) * step;
     }

     increaseKPIGameplayReference() {
          this.questContext.KPIGameplayBossLevel = this._levelStart + this.getTargetBaseOnLevel(this.questContext.level);
     }

     computeChallenge() {
          this.questContext.challengeTarget = 1 + this.getTargetBaseOnLevel(this.questContext.level);
     }

     computeReward() {
          let reward = this.questContext.gamePlayDataService.DropCoins(this.questContext.KPIGameplayBossLevel);
          //round in drop coin like game play
          reward.round();
          if(SMPNum.greaterThan(new SMPNum(1), reward)){
               reward = new SMPNum(1);
          }
          //reward *= 4;
          reward = SMPNum.multSmpNum(reward, new SMPNum(4));
          this.questContext.reward = reward.round();
     }
}

module.exports = SMPDailyQuestCollectDiamondFromBirdStrategy;