const SMPQuestDefaultStrategy = require("@/quest-editor/questImpl/strategies/SMPQuestDefaultStrategy");
const GameConstant = require("@/quest-editor/constants/GameConstant");

class SMPQuestHireNHeroStrategy extends SMPQuestDefaultStrategy {

     increaseKPIGameplayReference() {
          this.questContext.KPIGameplayBossLevel += this.questContext.JumpingLevel;
     }

     computeChallenge() {
          let newTarget = this.questContext.challengeTarget + 1;
          if (newTarget <= GameConstant.MAX_HERO) {
               this.questContext.challengeTarget = newTarget;
          }
     }

     computeLevelShouldAppear () {
          let shouldAppearAt = this.questContext.KPIGameplayBossLevel - 3;
          if (shouldAppearAt < 1) shouldAppearAt = 1;
          this.questContext.KPIGameLevelShouldAppear = shouldAppearAt;
     }
}

module.exports = SMPQuestHireNHeroStrategy