const SMPGamePlayQuest = require("@/quest-editor/questImpl/SMPGamePlayQuest");
const {fromNum, plus} = require("@/SMPNum");
//const {MAX_PET_LEVEL} = require("@/quest-editor/constants/GameConstant");
// const {MAX_SUPPORT_LEVEL} = require("@/quest-editor/constants/GameConstant");
const {MAX_SUPPORT, MAX_HERO, MAX_POWER_UP, HERO_POWER_UP_MAX_LEVEL} = require("@/quest-editor/constants/GameConstant");
let lodash = require('lodash');

class QuestSimulationService {
     constructor(quests, gamePlayDataService, progressTitle, progressMainTitle, tutorialTitle) {
          this.originalJsonQuests = quests;
          this.gamePlayDataService = gamePlayDataService;
          this.progressTitle = progressTitle;
          this.progressMainTitle = progressMainTitle;
          this.tutorialTitle = tutorialTitle;

          this.supportInfos = [];
          this.petInfos = [];

          this.hireSupportCounter = 0;

          this.powerupLevelCounter = 0;
          this.totalPowerUp = MAX_HERO * MAX_POWER_UP;
          this.totalPowerUpLevel = this.totalPowerUp * HERO_POWER_UP_MAX_LEVEL;
          this.powerupUnlock = 0;

          this.initPetInfos();
          this.initSupportInfos();

     }

     initSupportInfos () {
          for (let i = 1; i <= 20; i++) {
               this.supportInfos.push({
                  supportId:i,
                  level:1
               });
          }
     }

     initPetInfos () {
          //init pet infos
          for (let j = 1; j <= 40; j++) {
               if (j === 6) continue;
               this.petInfos.push({
                    petId: j,
                    level: 1,
               })
          }
     }

     initGameQuests() {
          //build GameQuest, insert quest json into game quest
          this.gamePlayQuestList = [];//SMPGamePlayQuest
          this.originalJsonQuests.forEach(q => {
               let gameQuest = new SMPGamePlayQuest(
                    lodash.cloneDeep(q)
                    , this.gamePlayDataService
                    , this.progressTitle
                    , this.progressMainTitle
                    , this.tutorialTitle);

               this.gamePlayQuestList.push(gameQuest);
          });
     }
     initComputeQuests() {
          //build computed quests for first time, it's new object include correct display and reward
          this.computedQuestList = [];
          this.gamePlayQuestList.forEach(gamePlayQuest => {
               let computed = gamePlayQuest.getComputedQuestCurrentLevel();
               this.computedQuestList.push(computed);
          });
     }
     computeRewardForQuest(gamePlayQuest, targetGameLevel, dailyStepLevelStart){
          let reward = fromNum(0);
          let countCompleted = 0;

          gamePlayQuest.setStartingLevelStep(dailyStepLevelStart);
          let computedQuest = gamePlayQuest.getComputedQuestCurrentLevel();

          while (gamePlayQuest.isCountAsQuestComplete(targetGameLevel)){//computedQuest.KPIGameplayBossLevel <= targetGameLevel) {
               //completed current challenge
               reward = plus(reward, computedQuest.reward);
               countCompleted += 1;

               //define new challenge
               if (gamePlayQuest.questJson.dynamicAvailable < 0
                    || (gamePlayQuest.questJson.dynamicAvailable > 0)) { //-1 mean unlimited, 0 mean only one time
                    gamePlayQuest.collect(dailyStepLevelStart); //increase level, compute new challenge and new reward
                    computedQuest = gamePlayQuest.getComputedQuestCurrentLevel();

               } else {
                    break;
               }
          }

          return {
               "reward": reward,
               "countCompleted": countCompleted
          };
     }
     computeRewardForAllQuests(targetGameLevel, dailyStepLevelStart) {
          let totalReward = fromNum(0);
          let totalCompleted = 0;
          for (const gamePlayQuest of this.gamePlayQuestList) {
               let result = this.computeRewardForQuest(gamePlayQuest, targetGameLevel, dailyStepLevelStart);
               totalReward = plus(totalReward, result.reward);
               totalCompleted += result.countCompleted;
          }

          return {
               "reward": totalReward,
               "countCompleted": totalCompleted,
               "median": totalCompleted/targetGameLevel
          };
     }

     async computeRewardForAllQuestsStrategy2(targetGameLevel, dailyStepLevelStart, itemFilterChosen, onUpdate) {
          let totalReward = fromNum(0);
          let totalCompleted = 0;
          let reachedLevel = 0;

          let result = {};
          let completedData = [];
          // let totalCompletedChallenge = 0;


          for (let i = 1; i <= targetGameLevel; i++) {
               // let minKpiBossLevel = Math.min(...this.gamePlayQuestList.map(item => item.KPIGameplayBossLevel));
               // if (minKpiBossLevel > i) {
               //      i = minKpiBossLevel - 1;
               //      if (i >= targetGameLevel) {
               //           i = targetGameLevel;
               //           reachedLevel = i;
               //      }
               //      continue;
               // }

               reachedLevel = i;

               for (const gamePlayQuest of this.gamePlayQuestList) {
                    let shouldCheck = true;
                    if (itemFilterChosen.length > 0) {
                         let matches = itemFilterChosen.filter(eFilter => eFilter.value === gamePlayQuest.questId);

                         if (matches.length > 0) {
                              shouldCheck = true;
                         } else {
                              shouldCheck = false;
                         }
                    }
                    gamePlayQuest.setStartingLevelStep(dailyStepLevelStart);
                    if (shouldCheck && !gamePlayQuest.finished && gamePlayQuest.isCountAsQuestComplete(i)){//gamePlayQuest.KPIGameplayBossLevel <= i) {
                         //completed
                         totalReward = plus(totalReward, gamePlayQuest.reward);
                         totalCompleted += 1;
                         let data = gamePlayQuest.getComputedQuestCurrentLevel();
                         completedData.push(data);

                         //totalCompletedChallenge += gamePlayQuest.challengeTarget;
                         //console.log("Challenge Target : " + gamePlayQuest.challengeTarget);


                         //Upgrade 12 pets to level 99 (level:98, kpi:9720)
                         //Update 25 pets! (level:89, kpi:22020)
                         //Upgrade all pets to level 99 (level:98, kpi:143650)
                         //Update a pet 12 times! (level:87, kpi:30125)
                         //Level up your Knaiy Teol 1 times (level:9, kpi:9000)
                         /*let petId = gamePlayQuest.ProgressModel.petId;
                         if (petId > 0) {
                              let found = this.petInfos.find(s => s.petId === petId);
                              found.level += gamePlayQuest.challengeTarget;

                              let predictNextLevel = found.level + 15;//10 is max challenge for this quest
                              if (predictNextLevel >= MAX_PET_LEVEL) {
                                   gamePlayQuest.questJson.dynamicAvailable = 0;
                              }
                         }

                        else if (gamePlayQuest.questId === "681bec6d-d9fc-4ca4-b5f6-2d65f808a8dd"
                              || gamePlayQuest.questId === "e3c482fd-21ea-4e47-9f14-987dbd993e36"
                              || gamePlayQuest.questId === "fe17c21c-7062-4620-be6e-243dd736b578"
                         ) {
                              let found = this.petInfos[0];

                              let minLevel = found.level; //find pet level less than max level
                              for (let info of this.petInfos) {
                                   if (info.level < minLevel) {
                                        minLevel = info.level;
                                        found = info;
                                   }
                              }
                              found.level += gamePlayQuest.challengeTarget;

                              let predictNextLevel = found.level + 10;
                              if (predictNextLevel >= MAX_PET_LEVEL) { //10 is max challenge for this quest
                                   gamePlayQuest.questJson.dynamicAvailable = 0;
                              }
                         }
                        else if (gamePlayQuest.questId === "01fcf719-4672-4f8c-a801-c66941d0185f"){
                              for (let info of this.petInfos) {
                                   if (info.level < gamePlayQuest.challengeTarget2) {
                                        info.level = gamePlayQuest.challengeTarget2;
                                   }
                              }
                              if (gamePlayQuest.challengeTarget2 + 1 > MAX_PET_LEVEL){
                                   gamePlayQuest.questJson.dynamicAvailable = 0;
                              }
                         }

                        else if (gamePlayQuest.questId === "fe9cc4ff-3666-4921-949b-dc7dfd777fcb"){
                              let countMatch = 0;
                              for (let info of this.petInfos) {

                                   if (info.level < gamePlayQuest.challengeTarget2) {
                                        info.level = gamePlayQuest.challengeTarget2;
                                        countMatch += 1;
                                   }
                                   if (countMatch >= gamePlayQuest.challengeTarget) {
                                        break;
                                   }
                              }
                              if (gamePlayQuest.challengeTarget2 + 1 > MAX_PET_LEVEL){
                                   gamePlayQuest.questJson.dynamicAvailable = 0;
                              }
                         }*/


                         /*if (gamePlayQuest.supportId > 0) {
                              let found = this.supportInfos.find(s => s.supportId === gamePlayQuest.supportId);
                              if (found) {
                                   let reachedLevel = found.level + gamePlayQuest.challengeTarget;
                                   if (reachedLevel > MAX_SUPPORT_LEVEL) {
                                        gamePlayQuest.questJson.dynamicAvailable = 0;
                                   } else {
                                        found.level += gamePlayQuest.challengeTarget;
                                   }


                              } else {
                                   this.supportInfos.push({
                                      supportId: gamePlayQuest.supportId,
                                      level: gamePlayQuest.challengeTarget
                                   });
                              }
                         }

                         if (gamePlayQuest.questId === "84ad457d-0efe-464a-94b2-ddfcc6556161") {
                              let found;
                              let minLevel = MAX_SUPPORT_LEVEL; //find support level less than max level
                              for (let info of this.supportInfos) {
                                   if (info.level < minLevel) {
                                        minLevel = info.level;
                                        found = info;
                                   }
                              }

                              if (found) {
                                   found.level += gamePlayQuest.challengeTarget;
                              } else {
                                   //consider on supportId 1 as default
                                   let foundSupport1 = this.supportInfos.find(s => s.supportId === 1);
                                   if (!foundSupport1) {
                                        this.supportInfos.push({
                                             supportId: 1,
                                             level: gamePlayQuest.challengeTarget
                                        });
                                   } else {
                                        gamePlayQuest.dynamicAvailable = 0;
                                   }
                              }
                         }*/

                         //calculate limit support evolve
                         /*if (gamePlayQuest.supportId > 0) {
                              let found = this.supportInfos.find(s => s.supportId === gamePlayQuest.supportId);
                              let reachedLevel = found.level + 1000;
                              if (reachedLevel > MAX_SUPPORT_LEVEL) {
                                   gamePlayQuest.questJson.dynamicAvailable = 0;
                                   console.log("End quest at level : " + data.level - 1);
                              } else {
                                   found.level = 1000 * gamePlayQuest.challengeTarget;
                              }
                         }*/

                         //calculate limit hire support
                         if (gamePlayQuest.questId === "20035ae1-8ce7-4eee-9234-521524f127cf") {
                              //count by one
                              let newCount = this.hireSupportCounter + gamePlayQuest.challengeTarget;
                              if (newCount >= MAX_SUPPORT) {
                                   gamePlayQuest.questJson.dynamicAvailable = 0;
                                   if (newCount > MAX_SUPPORT) {
                                        console.log("not possible to complete last hire support quest in " + newCount);
                                   }
                                   //the result of this quest level is 11
                              }
                              this.hireSupportCounter = newCount;
                         }
                         if (gamePlayQuest.questId === "3c108949-7294-436a-8ab7-ef47f75295b5") {
                              //full active support
                              if (this.hireSupportCounter < 4) {
                                   this.hireSupportCounter = 4;
                              }
                         }
                         if (gamePlayQuest.questId === "6fb95e9d-d285-40be-8236-04ae0168c312") {
                              //full flying support
                              this.hireSupportCounter += 4;
                         }
                         if (gamePlayQuest.questId === "7fd2600c-0356-478e-8c15-b073e8a421f8") {
                              //full team
                              if (this.hireSupportCounter < 4) {
                                   this.hireSupportCounter = 4;
                              }
                         }
                         if (gamePlayQuest.questId === "022b940d-3487-405a-be95-5925ff843eca") {
                              //full support
                              this.hireSupportCounter = MAX_SUPPORT;
                         }

                         //calculate limit Unlock power-up
                         if (gamePlayQuest.questId === "7b0429dd-bc9b-413e-8d2f-b7b65eeb27ed") {
                              //first obtain
                              this.powerupUnlock += 1;
                         }
                         if (gamePlayQuest.questId === "7b0429dd-bc9b-413e-8d2f-b7b65eeb27ed" //obtain counter
                              || gamePlayQuest.questId === "eff6b9f6-b726-4cdb-951c-1fbc4becebf0") { //unlock power-up

                              let newCount = this.powerupUnlock + gamePlayQuest.challengeTarget;
                              if (newCount >= this.totalPowerUp) {
                                   gamePlayQuest.questJson.dynamicAvailable = 0;
                                   if (newCount > this.totalPowerUp) {
                                        console.log("not possible to complete last unlock power-up quest in " + newCount);
                                   }
                                   //the result of this obtain quest level is 13
                                   //the result of this unlock quest level is 24
                              }
                              this.powerupUnlock = newCount;
                         }

                         //calculate limit Train and Upgrade Power-up
                         if (gamePlayQuest.questId === "dec66176-80dc-4182-a144-b0db6448ce1f") {
                              //upgrade power up
                              let newCount = this.powerupLevelCounter + gamePlayQuest.challengeTarget;
                              if (newCount >= this.totalPowerUpLevel) {
                                   gamePlayQuest.questJson.dynamicAvailable = 0;
                                   if (newCount > this.totalPowerUpLevel) {
                                        console.log("not possible to complete last upgrade power up quest in " + newCount);
                                   }
                                   //the result of this quest level is 400
                              }
                              this.powerupLevelCounter = newCount;
                         }
                         if (gamePlayQuest.questId === "df080cb5-1e84-4d52-9fef-710b969bcc62") {
                              //count 1 level by hero target counter
                              this.powerupLevelCounter += gamePlayQuest.challengeTarget;
                         }


                         //define new challenge
                         if (gamePlayQuest.questJson.dynamicAvailable > 0
                             || gamePlayQuest.questJson.dynamicAvailable < 0) { //-1 mean unlimited, 0 mean only one time
                              gamePlayQuest.collect(dailyStepLevelStart); //increase level, compute new challenge and new reward
                         } else {
                              gamePlayQuest.finished = true;
                         }
                    }
               }

               result.reward = totalReward;
               result.countCompleted = totalCompleted;
               result.median = totalCompleted / targetGameLevel;
               result.reachedLevel = reachedLevel;
               if (i % 1000 === 0) {
                    await this.sleep(5);
               }
               onUpdate(result);

          }


          //console.log("Total challenge : " + totalCompletedChallenge);
          //console.log(this.supportInfos);


          return {
               "reward": totalReward,
               "countCompleted": totalCompleted,
               "median": totalCompleted / targetGameLevel,
               "reachedLevel": reachedLevel,
               "completedData": completedData,
               // "totalCompletedChallenge": totalCompletedChallenge,
          };
     }

     sleep(ms) {
          return new Promise((resolve) => {
               setTimeout(resolve, ms);
          });
     }
}

module.exports = QuestSimulationService;