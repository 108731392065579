<template>

<!--    -->
  <!-- <v-footer app bottom fixed padless> -->
   <div>
    <BalanceParameters v-if="dialog" :pShowSettings="dialog" @closeParameters="dialog=false" @reload="reload"></BalanceParameters>
  <Footer @showSettings="showSettings" :pShowHome="pShowHome"></Footer>
   </div>
  <!-- </v-footer> -->

</template>

<script>

import Footer from './Footer'
import BalanceParameters from '../gameplay/BalanceParameters'

export default {
  data () {
    return {
      dialog: false,
      notifications: false,
      sound: true,
      widgets: false,

    }
  },
  components : {
    Footer: Footer,
    BalanceParameters : BalanceParameters
  },

  props : {
    pShowHome : Boolean
  },
  methods : {
    showSettings : function() {
      //console.log('i am after mit');
      this.dialog = true;
    },
      reload(){
        this.dialog = false;
          setTimeout(() => {
              this.dialog = true;
          }, 250);
      },
  }
}

</script>
