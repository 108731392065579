const SMPQuestDefaultStrategy = require("@/quest-editor/questImpl/strategies/SMPQuestDefaultStrategy");
const {MAX_SUPPORT} = require("@/quest-editor/constants/GameConstant");

class SMPQuestUnlockSupportReachNEvolveStrategy extends SMPQuestDefaultStrategy {

     increaseKPIGameplayReference() {
          this.questContext.KPIGameplayBossLevel += this.questContext.JumpingLevel;
     }

     computeChallenge() {
          if (this.questContext.supportId < MAX_SUPPORT) {
               this.questContext.supportId += 1;
          } else {
               this.questContext.challengeTarget += 1;
               this.questContext.supportId = 1;
          }
     }

     computeLevelShouldAppear () {
          let shouldAppearAt = this.questContext.KPIGameplayBossLevel - 100;
          if (shouldAppearAt < 1) shouldAppearAt = 1;
          this.questContext.KPIGameLevelShouldAppear = shouldAppearAt;
     }
}

module.exports = SMPQuestUnlockSupportReachNEvolveStrategy