const SMPQuestDefaultStrategy = require("@/quest-editor/questImpl/strategies/SMPQuestDefaultStrategy");
const GameConstant = require("@/quest-editor/constants/GameConstant");

class SMPQuestTapStrategy extends SMPQuestDefaultStrategy {

     increaseKPIGameplayReference() {
          this.questContext.KPIGameplayBossLevel += this.questContext.JumpingLevel;
     }

     computeChallenge() {
          this.questContext.challengeTarget += 10 * (this.questContext.level - 1);
          // if (this.questContext.challengeTarget > 1000) {
          //      this.questContext.challengeTarget = 500 + Math.floor(Math.random() * 500);
          // }
     }

     computeLevelShouldAppear () {
          let shouldAppearAt = this.questContext.KPIGameplayBossLevel - Math.floor(this.questContext.challengeTarget / GameConstant.TAP_AVG_PER_GAMELEVEL);
          if (shouldAppearAt < 1) shouldAppearAt = 1;
          this.questContext.KPIGameLevelShouldAppear = shouldAppearAt;
     }
}

module.exports = SMPQuestTapStrategy;