const SMPQuestDefaultStrategy = require("@/quest-editor/questImpl/strategies/SMPQuestDefaultStrategy");
const SMPNum = require("@/SMPNum");

class SMPQuestTutorialStrategy extends SMPQuestDefaultStrategy {
     initStartingValue() {
          let tutorialNum = this.questContext.questJson.tutorialNum;

          //it's hero match 3
          if(tutorialNum <= 3){
               this.questContext.KPIGameplayBossLevel = 1;
          } else {
               //it's support match 3
               let supportDatas = this.questContext.gamePlayDataService.supportGamePlay.supportData;
               if(supportDatas){
                    let supportColor = this.getSupportColorForTutorial(tutorialNum);
                    let supportTutorials = supportDatas.filter(s => s.m_iFruitType === supportColor);
                    let supports = supportTutorials.sort(function(a, b){
                         if(a.kpiGameLevelShouldUnlock < b.kpiGameLevelShouldUnlock) { return -1; }
                         if(a.kpiGameLevelShouldUnlock > b.kpiGameLevelShouldUnlock) { return 1; }
                         return 0;
                    });

                    this.questContext.KPIGameplayBossLevel = supports[0].kpiGameLevelShouldUnlock;
               }
          }

          //console.log('tutorialNum: '+tutorialNum+" kpiLevel: "+this.questContext.KPIGameplayBossLevel);
     }

     computeReward() {
          let kpi = this.questContext.questJson.kpiBossLevel;
          let reward = this.questContext.gamePlayDataService.DropCoins(kpi);
          reward.round();
          reward.setPower(Math.floor(reward._power));
          if(SMPNum.greaterThan(new SMPNum(1), reward)){
               reward = new SMPNum(1);
          }
          this.questContext.reward = reward.round();
     }

     getSupportColorForTutorial(tutorialNum){
          if(tutorialNum === 4){
               return 0;//HERO_COLOR.SUPPORT1
          } else if(tutorialNum === 5){
               return 3;//HERO_COLOR.SUPPORT3
          } else if(tutorialNum === 6){
               return 4;//HERO_COLOR.SUPPORT4
          } else if(tutorialNum === 7){
               return 5;//HERO_COLOR.SUPPORT5
          }

          return -1;
     }
}

module.exports = SMPQuestTutorialStrategy;