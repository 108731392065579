const SMPQuestDefaultStrategy = require("@/quest-editor/questImpl/strategies/SMPQuestDefaultStrategy");

class SMPQuestUpdateHeroLvStrategy extends SMPQuestDefaultStrategy {

     initStartingValue() {
          this._challengeList = [50, 10, 20, 30, 40, 50, 60, 70, 80, 90, 100];//n time level update
          if (this.questContext.ProgressModel.heroId != 0) {
               this._challenge2List = [1, 5,  8,  2,  4,  6,  9,  2,  4,  3, 6]; //id of hero but 7 we have remove
          }
     }

     increaseKPIGameplayReference() {
          let preCheckTarget = this.getTargetBaseOnLevel(this.questContext.level);
          this.questContext.KPIGameplayBossLevel += this.questContext.JumpingLevel + preCheckTarget;
     }

     computeChallenge() {
          this.questContext.challengeTarget = this.getTargetBaseOnLevel(this.questContext.level);
          if (this.questContext.ProgressModel.heroId != 0) {
               this.questContext.ProgressModel.heroId = this.getTarget2BaseOnLevel(this.questContext.level);
          }
     }

     computeLevelShouldAppear () {
          let shouldAppearAt = this.questContext.KPIGameplayBossLevel - this.questContext.challengeTarget;
          if (shouldAppearAt < 1) shouldAppearAt = 1;
          this.questContext.KPIGameLevelShouldAppear = shouldAppearAt;
     }


}

module.exports = SMPQuestUpdateHeroLvStrategy