const SMPQuestDefaultStrategy = require("@/quest-editor/questImpl/strategies/SMPQuestDefaultStrategy");
const {MAX_HERO} = require("@/quest-editor/constants/GameConstant");
class SMPQuestUpgradeNHeroReachRankStrategy extends SMPQuestDefaultStrategy {

     initStartingValue() {
          let counter = 1;
          let totalChallenge = this.questContext.ProgressModel.target;
          for (let i = 2; totalChallenge < MAX_HERO; i++) {
               let value = 1;
               totalChallenge += value;

               if (totalChallenge <= MAX_HERO) {
                    counter++;
               }
          }

          this.questContext.questJson.dynamicAvailable = counter;
     }

     increaseKPIGameplayReference() {
          this.questContext.KPIGameplayBossLevel += this.questContext.JumpingLevel;
     }

     computeChallenge() {
          this.questContext.challengeTarget += 1;
     }

     computeLevelShouldAppear() {
          let shouldAppearAt = this.questContext.KPIGameplayBossLevel - 5;
          if (shouldAppearAt < 1) shouldAppearAt = 1;
          this.questContext.KPIGameLevelShouldAppear = shouldAppearAt;
     }
}

module.exports = SMPQuestUpgradeNHeroReachRankStrategy