const SMPQuestDefaultStrategy = require("@/quest-editor/questImpl/strategies/SMPQuestDefaultStrategy");

class SMPQuestHireNSupportCountStrategy extends SMPQuestDefaultStrategy {

     prevKpiLevel = 0;

     initStartingValue() {
          this._challengeList = [1, 2, 2, 1, 2, 1, 1, 2, 2, 1, 1];
     }

     increaseKPIGameplayReference() {
          //this.questContext.KPIGameplayBossLevel += this.questContext.JumpingLevel;

          let unlockSupportHelper = this.GetSupportUnlockHelper(this.questContext.level);
          this.prevKpiLevel = unlockSupportHelper.prevKpiLevel;
          this.questContext.JumpingLevel = unlockSupportHelper.jumpingLevel;
          this.questContext.KPIGameplayBossLevel = unlockSupportHelper.lastKpiUnlock;
     }

     computeChallenge() {
          this.questContext.challengeTarget = this.getTargetBaseOnLevel(this.questContext.level);
     }

     computeLevelShouldAppear() {
          this.questContext.KPIGameLevelShouldAppear = this.prevKpiLevel;
     }

     GetSupportUnlockHelper(level) {
          let countHired = 0;
          let index = level - 1;
          if (index >= this._challengeList.length) {
               let howTime = index / this._challengeList.length;
               index = index - (howTime * this._challengeList.length);
          }
          for (let i = 0;
               i < index;
               i++
          ) {
               countHired += this._challengeList[i];
          }

          let supportDatas = this.questContext.gamePlayDataService.supportGamePlay.supportData;
          let jumpingLevel = 0;
          let supportPossibleHireReady = 0;
          let supportWillHire = 0;
          let lastKpiUnlock = 0;
          let prevLevelUnlock = 0;
          let prevKpiLevel = -1;
          let isEnd = false;
          supportDatas.forEach(supportData => {
               if(!isEnd){
                    lastKpiUnlock = supportData.kpiGameLevelShouldUnlock;
                    supportPossibleHireReady++;
                    if (supportPossibleHireReady <= countHired) {
                         prevLevelUnlock = supportData.kpiGameLevelShouldUnlock;
                    } else {
                         if (prevKpiLevel === -1) {
                              prevKpiLevel = supportData.kpiGameLevelShouldUnlock;
                         }
                         jumpingLevel = supportData.kpiGameLevelShouldUnlock - prevLevelUnlock;
                         supportWillHire++;
                         if (supportWillHire >= this.questContext.challengeTarget) {
                              isEnd = true;
                         }
                    }
               }
          });

          return {
               lastKpiUnlock:lastKpiUnlock,
               jumpingLevel:jumpingLevel,
               prevKpiLevel:prevKpiLevel
          };
     }
}

module.exports = SMPQuestHireNSupportCountStrategy