const SMPQuestDefaultStrategy = require("@/quest-editor/questImpl/strategies/SMPQuestDefaultStrategy");
const SMPNum = require("@/SMPNum");
class SMPQuestCollectGoldStrategy extends SMPQuestDefaultStrategy {
     increaseKPIGameplayReference() {
          this.questContext.KPIGameplayBossLevel += this.questContext.JumpingLevel;
     }

     computeChallenge() {
          let moneyDropByBoss =  this.questContext.gamePlayDataService.DropCoins(this.questContext.KPIGameplayBossLevel);
          moneyDropByBoss.round();
          moneyDropByBoss.setPower(Math.floor(moneyDropByBoss._power));
          moneyDropByBoss = SMPNum.multSmpNum(moneyDropByBoss, SMPNum.fromNum(10));
          let value = SMPNum.plus(this.questContext.challengeTarget, moneyDropByBoss);
          this.questContext.challengeTarget = value;
     }

     computeLevelShouldAppear () {
          let shouldAppearAt = this.questContext.KPIGameplayBossLevel - 5;
          if (shouldAppearAt < 1) shouldAppearAt = 1;
          this.questContext.KPIGameLevelShouldAppear = shouldAppearAt;
     }

     isCountAsQuestComplete(gameLevel){
          /*work, but mad kpi slow down
          if(this.questContext.KPIGameLevelShouldAppear > gameLevel) return false;
          let goldEarn = this.questContext.logicSimulatorService.cumulatedGoldWonAfterAllEnemiesFromGameLevelToGameLevel(this.questContext.KPIGameLevelShouldAppear, gameLevel);
          return SMPNum.greaterThan(goldEarn, this.questContext.challengeTarget);*/

          return super.isCountAsQuestComplete(gameLevel);
     }
}

module.exports = SMPQuestCollectGoldStrategy;