const SMPQuestDefaultStrategy = require("@/quest-editor/questImpl/strategies/SMPQuestDefaultStrategy");

class SMPQuestTrainPowerUpOfNHeroStrategy extends SMPQuestDefaultStrategy {

     initStartingValue() {
          this._challengeList = [8,3,5,10,8,12,15,10,20,25,20,25,30,40,45,50,25,15,10];
     }

     increaseKPIGameplayReference() {
          this.questContext.KPIGameplayBossLevel += this.questContext.JumpingLevel;
     }

     computeChallenge() {
          this.questContext.challengeTarget = this.getTargetBaseOnLevel(this.questContext.level);
     }

     computeLevelShouldAppear () {
          let shouldAppearAt = this.questContext.KPIGameplayBossLevel - 10*this.questContext.challengeTarget;
          if (shouldAppearAt < 1) shouldAppearAt = 1;
          this.questContext.KPIGameLevelShouldAppear = shouldAppearAt;
     }
}

module.exports = SMPQuestTrainPowerUpOfNHeroStrategy