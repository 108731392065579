const SMPQuestDefaultStrategy = require("@/quest-editor/questImpl/strategies/SMPQuestDefaultStrategy");
//const {MAX_PET} = require("@/quest-editor/constants/GameConstant");

class SMPQuestCollectPetNReachStrategy extends SMPQuestDefaultStrategy {

     initStartingValue() {
          this._challengeList = [1,2,2,3,3,4,4];

          /*assign dynamic value to json ready
          let counter = 1;
          let totalChallenge = this.questContext.ProgressModel.target;
          for (let i = 2; totalChallenge < MAX_PET; i++) {
               let value = this.getTargetBaseOnLevel(i);
               totalChallenge += value;

               if (totalChallenge <= MAX_PET) {
                    counter++;
               }
          }

          this.questContext.questJson.dynamicAvailable = counter;*/
     }

     increaseKPIGameplayReference() {
          let newValue = this.getTargetBaseOnLevel(this.questContext.level);
          let numOfHired = 0;
          if (this.questContext.KPIGameplayBossLevel < 100) {
               numOfHired = this.questContext.KPIGameplayBossLevel / 10;
          }
          else {
               numOfHired = 9 + (this.questContext.KPIGameplayBossLevel / 100);
          }

          numOfHired = Math.floor(numOfHired);

          if (numOfHired < 10) {
               let howManyRemainTo10 = 10 - numOfHired;
               if (howManyRemainTo10 >=  newValue) {
                    this.questContext.KPIGameplayBossLevel += (10 * newValue);
                    //jump = 10 * newValue;
               }
               else
               {
                    let belowOrEqual10 = 10 * howManyRemainTo10;
                    let over10 = 100 * (newValue - howManyRemainTo10);
                    this.questContext.KPIGameplayBossLevel += (belowOrEqual10 + over10);
               }
          } else {
               this.questContext.KPIGameplayBossLevel += 100 * newValue;
          }
     }

     computeChallenge() {
          this.questContext.challengeTarget += this.getTargetBaseOnLevel(this.questContext.level);
     }

     computeLevelShouldAppear() {
          let shouldAppearAt = this.questContext.KPIGameplayBossLevel - 10;
          if (shouldAppearAt < 1) shouldAppearAt = 1;
          this.questContext.KPIGameLevelShouldAppear = shouldAppearAt;
     }
}

module.exports = SMPQuestCollectPetNReachStrategy