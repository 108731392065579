const SMPQuestDefaultStrategy = require("@/quest-editor/questImpl/strategies/SMPQuestDefaultStrategy");
const {getZoneByLevel} = require("@/quest-editor/questImpl/SMPQuestUtils");
const {MAX_LEVEL_ON_STAGE, MAX_ZONE} = require("@/quest-editor/constants/GameConstant");

class SMPQuestWorldNameStrategy extends SMPQuestDefaultStrategy {

     increaseKPIGameplayReference() {
          this.questContext.KPIGameplayBossLevel += this.questContext.JumpingLevel;
     }
     computeChallenge() {
          let kpiLevel = this.questContext.KPIGameplayBossLevel;
          let zoneIndex = getZoneByLevel(kpiLevel);
          let zoneId = zoneIndex + 1;
          if (zoneId > MAX_ZONE) {
               zoneId = 1;
          }
          this.questContext.challengeTarget = zoneId;
     }

     computeLevelShouldAppear() {
          let shouldAppearAt = ((this.questContext.challengeTarget - 1) * MAX_LEVEL_ON_STAGE) - 1;
          if (shouldAppearAt < 1) shouldAppearAt = 1;
          this.questContext.KPIGameLevelShouldAppear = shouldAppearAt;
     }

     isCountAsQuestComplete(gameLevel){
          return this.questContext.KPIGameplayBossLevel - 1 <= gameLevel;
     }
}

module.exports = SMPQuestWorldNameStrategy