const SMPQuestDefaultStrategy = require("@/quest-editor/questImpl/strategies/SMPQuestDefaultStrategy");

class SMPQuestUpgradeNPetStrategy extends SMPQuestDefaultStrategy {

     initStartingValue() {
          this._challengeList = [8, 4, 5, 7, 6, 8, 10, 15, 12, 20, 25, 10, 15];
     }

     increaseKPIGameplayReference() {
          this.questContext.KPIGameplayBossLevel += this.questContext.JumpingLevel;
     }

     computeChallenge() {
          this.questContext.challengeTarget = this.getTargetBaseOnLevel(this.questContext.level);
     }

     computeLevelShouldAppear () {
          let shouldAppearAt = this.questContext.KPIGameplayBossLevel - 50 * this.questContext.challengeTarget;
          if (shouldAppearAt < 1) shouldAppearAt = 1;
          this.questContext.KPIGameLevelShouldAppear = shouldAppearAt;
     }


}

module.exports = SMPQuestUpgradeNPetStrategy