const SMPQuestDefaultStrategy = require("@/quest-editor/questImpl/strategies/SMPQuestDefaultStrategy");

class SMPQuestDefeatNBossRevengeStrategy extends SMPQuestDefaultStrategy {
     initStartingValue() {
          this._challengeList = [2,3,4,5,6,6,7,7,8,8,9,9,10,10,10];
     }

     increaseKPIGameplayReference() {
          this.questContext.KPIGameplayBossLevel += this.questContext.JumpingLevel;
          if (this.questContext.KPIGameplayBossLevel % 2 > 0) {
               this.questContext.KPIGameplayBossLevel += 1;
          }
     }

     computeChallenge() {
          this.questContext.challengeTarget = this.getTargetBaseOnLevel(this.questContext.level);
     }

     computeLevelShouldAppear () {
          let bossN = this.questContext.challengeTarget;
          let shouldAppearAt = this.questContext.KPIGameplayBossLevel - ((bossN - 1) * 2);
          if (shouldAppearAt < 1) shouldAppearAt = 1;
          this.questContext.KPIGameLevelShouldAppear = shouldAppearAt;
     }
}

module.exports = SMPQuestDefeatNBossRevengeStrategy;