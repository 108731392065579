const {MAX_LEVEL_ON_STAGE, MAX_ZONE} = require("@/quest-editor/constants/GameConstant");
module.exports = {
     getZoneByLevel(level) {
          let zone = Math.floor(level/MAX_LEVEL_ON_STAGE);
          if (zone > MAX_ZONE) {
               let time = Math.floor(zone / MAX_ZONE);
               zone = zone - (MAX_ZONE * time);
          }
          return zone;
     }
}