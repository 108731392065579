const QuestStrategyAdapterData = require('../assets/json/questStrategyAdapterData.json');
const {SMPQuestStrategyClassConstructors} = require('./strategies/SMPQuestStrategyClassConstructors');

class SMPQuestStrategyFactory {

     static getInstance(questJson, questContext) {
          let identifier = questContext.ProgressModel.eventName + "-" + questContext.ProgressModel.progressType;
          let strategyName = "SMPQuestDefaultStrategy";

          questContext.stillUseDefaultStrategy = true;
          if (questJson.ordered[0].target === -1) {
               questContext.stillUseDefaultStrategy = false;
          }

          for (let index in QuestStrategyAdapterData) {
               let data = QuestStrategyAdapterData[index];
               if (identifier === data.eventName + "-" + data.progressType){
                    let className = undefined;
                    if (questJson.isDaily){
                         className = data.dailyClassName;
                    } else {
                         className = data.strategyClassName;
                    }
                    if (className) {
                         let components = className.split('.');
                         let temp = components[components.length - 1];
                         if (SMPQuestStrategyClassConstructors[temp]){
                              strategyName = temp;
                              questContext.stillUseDefaultStrategy = false;
                              if (!questJson.debug) {
                                   questJson.debug = {}
                              }
                              questJson.debug.strategyClassName = strategyName;
                              break;
                         }
                    }
               }
          }

          let strategy = new SMPQuestStrategyClassConstructors[strategyName];
          strategy.setQuestContextAndInit(questContext);
          return strategy;
     }
}

module.exports = SMPQuestStrategyFactory