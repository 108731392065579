<template>

    <v-card
            min-height="660"
            max-height="660"
            class="overflow-hidden"
    >
        <v-card-title>
            Skill value settings {{this.skillList.length}}
        </v-card-title>

        <v-text-field
                v-model="search"
                append-icon="mdi-magnify"
                label="Search"
                single-line
                hide-details
        ></v-text-field>

        <v-card
                max-height="500" class="overflow-y-auto"
        >

            <v-data-table
                    :headers="headers"
                    :items="skillList"
                    class="elevation-1"
                    group-by="support"
                    hide-default-footer
                    :options="{itemsPerPage:skillList.length}"
                    :search="search"
            >
                <template v-slot:group.header="{items, isOpen, toggle}">
                    <th colspan="2">
                        <v-icon @click="toggle"
                        >{{ isOpen ? 'mdi-minus' : 'mdi-plus' }}
                        </v-icon>
                        {{ items[0].support }}
                        ({{ getColorText(items[0].support_id) }})
                    </th>
                </template>
                <template v-slot:item.m_skillPercent="{item}">
                    <v-edit-dialog
                            :return-value.sync="item.m_skillPercent"
                    >

                        {{ item.m_skillPercent }}

                        <template v-slot:input>
                            <v-text-field
                                    v-model="item.m_skillPercent"
                                    label="Edit"
                                    single-line
                                    autofocus
                                    @focus="$event.target.select()"

                            ></v-text-field>
                        </template>
                    </v-edit-dialog>

                </template>

                <template v-slot:item.m_iCurrentLevel="{item}">
                    <v-edit-dialog
                            :return-value.sync="item.m_iCurrentLevel"
                    >

                        {{ item.m_iCurrentLevel }}

                        <template v-slot:input>
                            <v-text-field
                                    v-model="item.m_iCurrentLevel"
                                    label="Edit"
                                    single-line
                                    autofocus
                                    @focus="$event.target.select()"

                            ></v-text-field>
                        </template>
                    </v-edit-dialog>
                </template>


            </v-data-table>

        </v-card>
        <v-divider></v-divider>
        <v-card-actions>
            <v-btn color="blue darken-1" text @click="dispatchClose">Close</v-btn>
            <v-btn color="green darken-1" text @click="dispatchSave">Save</v-btn>
        </v-card-actions>

      <v-alert
          dense
          text
          type="success"
          v-if="displayConfirm"
      >
        Save Completed
      </v-alert>

    </v-card>


</template>

<script>

    export default {
        data() {
            return {
                search: "",
                skillList: [],
                supportData: null,
                displayConfirm: false,
                headers: [
                    {text: 'Name', value: 'supportData.m_sName'},
                    {
                        text: 'Ability Type',
                        value: 'skillType',
                    },
                    {
                        text: 'Name',
                        value: 'skillNameEn',
                    },
                    {
                        text: 'Percent Skill',
                        value: 'm_skillPercent',
                    },
                    {
                        text: 'Unlock At',
                        value: 'm_iCurrentLevel',
                    },
                ]
            }
        },

        props: {
            'pSkillList': {
                type: Array,
                required: true
            },
            'shouldInit': {
                type: Boolean,
            }
        },

        methods: {
            loadData: function (list) {
                this.skillList = [];
                if (Array.isArray(list)) {
                    list.forEach(e => {
                        //console.log('load data')
                        this.skillList.push(Object.assign({}, e));
                    });
                }
            },
            didChange: function () {
                this.loadData(this.pSkillList);
            },

            dispatchClose: function () {
                this.$emit('close');
            },

            dispatchSave: async function () {
                this.$store.commit(this.$store.state.commitActions.PUSH_SKILL_SUPPORTS, this.skillList);
                this.displayConfirm = true;

                const myTimeout = setTimeout(() => {
                    this.dispatchClose();
                    clearTimeout(myTimeout);
                    this.displayConfirm = false;
                }, 1000);
            },

            customFilter (value, search, item) {
                return Object.values(item).some(v=>v&&v.toString().toLowerCase().includes(search));
            },

            applyPercentChangeHotFix(){

                /*100%-200%
                orange (4) : [1], 2,3, 4  => 1 is strong under 200%, 2 is weak under 100%
                red (0) : 1, [2] ,3,4
                purple (5) : 1, 2, [3] ,4
                blue (3) : 1, 2, 3, [4]*/

                let colorCount = {};
                let skillTargetId = 'SUPPORT_DAMAGE';// HERO_DAMAGE = 1,//the supporter damage
                this.supportData.forEach(support => {
                    if (colorCount[support.m_iFruitType] === undefined) {
                        colorCount[support.m_iFruitType] = 1;
                    } else {
                        colorCount[support.m_iFruitType] += 1;
                    }

                    let skills = [];
                    let maxPercent = 0;
                    let maxId = 1;
                    this.skillList.forEach(sk =>{
                        if(sk.support_id === support.m_iID && sk.skillType === skillTargetId){
                            skills.push(sk);
                            if(maxPercent < sk.m_skillPercent){
                                maxPercent = sk.m_skillPercent;
                                maxId = sk.skill_id;
                            }
                        }
                    });

                    let colorCountForStrong = 0;

                    if(support.m_iFruitType === 4){//orange
                        colorCountForStrong = 1;
                    } else if(support.m_iFruitType === 0){//red
                        colorCountForStrong = 2;
                    } else if(support.m_iFruitType === 5){//purple
                        colorCountForStrong = 3;
                    } else if(support.m_iFruitType === 3){//blue
                        colorCountForStrong = 4;
                    }

                    let targetPercent = maxPercent;
                    if(colorCount[support.m_iFruitType] === colorCountForStrong){
                        if(maxPercent > 2){
                            targetPercent = 2;//200%
                        }
                    } else {
                        if(maxPercent > 1){
                            targetPercent = 1;//100%
                        }
                    }
                    skills.forEach(sk => {
                        if(sk.skill_id === maxId){
                            sk.m_skillPercent = targetPercent;
                        } else {
                            sk.m_skillPercent = (sk.m_skillPercent / (maxPercent / targetPercent)).toFixed(2);
                        }
                    });

                });
            },

            getColorText(supportId) {
                let colorCount = {};
                let colorText = "None";
                this.supportData.forEach(support => {
                    if (colorCount[support.m_iFruitType] === undefined) {
                        colorCount[support.m_iFruitType] = 1;
                    } else {
                        colorCount[support.m_iFruitType] += 1;
                    }
                    if (support.m_iID === supportId) {
                        if (support.m_iFruitType === 0) {
                            colorText = "RED " + colorCount[support.m_iFruitType];
                        } else if (support.m_iFruitType === 1) {
                            colorText = "sword " + colorCount[support.m_iFruitType];
                        } else if (support.m_iFruitType === 2) {
                            colorText = "leaf " + colorCount[support.m_iFruitType];
                        } else if (support.m_iFruitType === 3) {
                            colorText = "BLUE " + colorCount[support.m_iFruitType];
                        } else if (support.m_iFruitType === 4) {
                            colorText = "ORANGE " + colorCount[support.m_iFruitType];
                        } else if (support.m_iFruitType === 5) {
                            colorText = "PURPLE " + colorCount[support.m_iFruitType];
                        }
                        return;
                    }
                });
                return colorText;
            },
        },

        created: function () {
            this.supportData = this.$store.state.supportersData;
            this.loadData(this.pSkillList);

            //to apply target percent what have customize
            //this.applyPercentChangeHotFix();
        },
        watch: {
            pSkillList: {
                deep: true,
                handler: 'didChange'
            },

            shouldInit: {
                handler: 'didChange'
            }
        },

    }

</script>
