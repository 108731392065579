// SUPPORT1 = 0,//RED
//     HERO, //sword
//     LEAF, //leaft
//     SUPPORT3,//BLUE
//     SUPPORT4,//orange
//     SUPPORT5,//PURPLE
//     EMPTY

/*
SUPPORT1 = 0,//RED
	HERO, //sword
	LEAF, //leaft
	SUPPORT3,//BLUE
	SUPPORT4,//orange
	SUPPORT5,//PURPLE
	EMPTY
 */

let constants = {
    runes : [
       'Red', 'Sword','leaf','Blue','Orange','Purple','Empty'
    ],
    bossType : [
        "boss revenge",
        "boss",
        "big boss",
    ],

    supportName : {
        "1" : "Sena",
        "2" : "Vorana",
        "3" : "Chao Cheum",
        "4" : "Kakei",
        "5" : "Reachboth",
        "6" : "Kola",
        "7" : "Chhorda",
        "8" : "Mala",
        "9" : "Botom",
        "10" : "Neang Romsai Sork",
        "11" : "Kamov",
        "12" : "Angulimear",
        "13" : "Machar",
        "14" : "Neang Phan",
        "15" : "Tida",
        "16" : "Chey",
        "17" : "Angk Tep",
        "18" : "Terah Mara",
        "19" : "Terah Meura",
        "20" : "Angk Piseth",
    },

    rankName : [
        "None",
        "Bronze",
        "Silver",
        "Gold",
        "platinum"
    ],

    heroName : [
    "Haknura",
    "Kasor",
    "Meora",
    "Reachny",
    "Visalabot",
    "Tida",
    "Noroseng",
    "Soknarika",
    "Motharo",
    ],

    petName : {
        "1" : "Kiny",
        "2" : "Baka",
        "3" : "Melon",
        "4" : "Opabo",
        "5" : "Oppa",
        "7" : "Jroleng",
        "8" : "Jroload",
        "9" : "Knaiy Tan",
        "10" : "Knaiy Teol",
        "11" : "Parv",
        "12" : "Pong",
        "13" : "Tam",
        "14" : "Ting",
        "15" : "Tnaot",
        "16" : "Tol",
        "17" : "Ban Ban",
        "18" : "Ben Ben",
        "19" : "Boobie",
        "20" : "Doki",
        "21" : "Oub Oub",
        "22" : "Poo Poo",
        "23" : "Bong",
        "24" : "Joy",
        "25" : "Mot Thom",
        "26" : "Plok",
        "27" : "Ploy",
        "28" : "Poy",
        "29" : "Sa",
        "30" : "Bora",
        "31" : "Dy",
        "32" : "Mab",
        "33" : "Mat",
        "34" : "Rotha",
        "35" : "Thom",
        "36" : "Chanta",
        "37" : "Somlab",
        "38" : "Manita",
        "39" : "Mato",
        "40" : "Riya"
    },

    heroSkill : {
        "0": "THUNDER_ATTACK",
        "1": "TWIN_SHADOW",
        "2": "LAST_HAND",
        "3": "FAST_AND_FURIOUS",
        "4": "ANGER_OF_GOD",
        "5": "GOLD_FINGER",
        "6": "ANGER_OF_PET",
        "7": "PROTECTION",
        "8": "FLYING_SUPPORT",
        "10": "THE_TEAMMATE"
    },
    heroSkillByName : {
        "THUNDER_ATTACK": "THUNDER_ATTACK",
        "TWIN_SHADOW": "TWIN_SHADOW",
        "LAST_HAND": "LAST_HAND",
        "FAST_AND_FURIOUS": "FAST_AND_FURIOUS",
        "ANGER_OF_GOD": "ANGER_OF_GOD",
        "GOLD_FINGER": "GOLD_FINGER",
        "ANGER_OF_PET": "ANGER_OF_PET",
        "PROTECTION": "PROTECTION",
        "FLYING_SUPPORT": "FLYING_SUPPORT",
        "THE_TEAMMATE": "THE_TEAMMATE"
    },

    zoneName: {
        "1" : "The sleeping forest",
        "2" : "The enchanted forest",
        "3" : "The magical forest",
        "4" : "The enchanting place",
        "5" : "The fairy house",
        "6" : "The mighty kingdom",
        "7" : "The wise view",
        "8" : "Gorhann land",
        "9" : "The old temple",
        "10" : "The underground",
        "11" : "The crooked forest",
        "12" : "The goblin forest",
        "13" : "The blue trees forest",
        "14" : "The sacred tree",
        "15" : "The legendary warrior",
        "16" : "The giant trees",
        "17" : "The forgotten forest",
        "18" : "The secret temple",
        "19" : "The cover city",
        "20" : "The lavish bridge",
        "21" : "The death king",
        "22" : "The sleeping tree",
        "23" : "The lost land",
        "24" : "The peace field",
        "25" : "The arcane forest",
        "26" : "The old gods",
        "27" : "The Big trees",
        "28" : "The living trees",
        "29" : "The big trees ",
        "30" : "The green forest",
        "31" : "The peaceful forest",
        "32" : "The King's forest",
        "33" : "The heaven village",
        "34" : "The old wall",
        "35" : "The old gods",
        "36" : "The night fall",
        "37" : "The highest castle",
        "38" : "The fairies forest",
        "39" : "The legendary kingdom",
        "40" : "The frozen way",
        "41" : "The frozen way",
        "42" : "The frozen lake",
        "43" : "The frozen valley",
        "44" : "The hunter nest",
        "45" : "The shelter",
        "46" : "The exit",
        "47" : "Last fight",
        "48" : "The portal place",
        "49" : "The winter cave",
        "50" : "The outland",
        "51" : "The demon door",
        "52" : "The magic place",
        "53" : "The inside lake",
        "54" : "The fairy forest",
        "55" : "The dragon throne",
        "56" : "Bakarott's world",
        "57" : "Mehopa's  planet",
        "58" : "Arkhaan city of mage",
        "59" : "The sacred tree",
        "60" : "The old world",
        "61" : "The pirates bay",
        "62" : "The raining forest",
        "63" : "The fairy nest",
        "64" : "The small river",
        "65" : "The shaman place",
        "66" : "The eyes of chaos",
        "67" : "The hope path",
        "68" : "The Dark place",
        "69" : "The secret path",
        "70" : "The black pearl",
        "71" : "The sorcerer's place",
        "72" : "Ice city",
        "73" : "Nehmoteck town",
        "74" : "Downtown",
        "75" : "The old city",
        "76" : "Big Cat's kingdom",
        "77" : "The gods sanctuary",
        "78" : "The gods door",
        "79" : "Deck of god",
        "80" : "The battlefield",
        "81" : "The landlord",
        "82" : "The Kheronos bay",
        "83" : "The dangerous path",
        "84" : "The sacrifice plan",
        "85" : "The bargon cave",
        "86" : "The frozen land",
        "87" : "The village",
        "88" : "Death field",
        "89" : "Frozen creek",
    },
    
    questIcons : [
        "quest_icon_0043_bag.png"
        ,"quest_icon_0014_axe.png"
        ,"quest_icon_0077_magic4.png"
        ,"quest_icon_0067_helmet.png"
        ,"quest_icon_0023_pets.png"
        ,"quest_icon_0078_special4.png"
        ,"quest_icon_0065_luck.png"
        ,"quest_icon_0003_key.png"
        ,"quest_icon_0034_target.png"
        ,"quest_icon_0018_failed.png"
        ,"quest_icon_0026_attack.png"
        ,"quest_icon_0073_fire2.png"
        ,"quest_icon_0039_star.png"
        ,"quest_icon_0007_death.png"
        ,"quest_icon_0005_chest.png"
        ,"quest_icon_0074_upgrade.png"
        ,"quest_icon_0004_potion.png"
        ,"quest_icon_0013_boss.png"
        ,"quest_icon_0016_ring.png"
        ,"quest_icon_0072_special2.png"
        ,"quest_icon_0027_clan.png"
        ,"quest_icon_0056_magic.png"
        ,"quest_icon_0048_level.png"
        ,"quest_icon_0029_check.png"
        ,"quest_icon_0064_lock.png"
        ,"quest_icon_0019_time.png"
        ,"quest_icon_0068_fight.png"
        ,"quest_icon_0002_archer.png"
        ,"quest_icon_0021_rang.png"
        ,"quest_icon_0006_gift.png"
        ,"quest_icon_0000_formula.png"
        ,"quest_icon_0040_special.png"
        ,"quest_icon_0062_impact2.png"
        ,"quest_icon_0033_flight.png"
        ,"quest_icon_0008_taptap.png"
        ,"quest_icon_0038_wing.png"
        ,"quest_icon_0052_map.png"
        ,"quest_icon_0032_grid.png"
        ,"quest_icon_0055_deathcoins.png"
        ,"quest_icon_0075_money.png"
        ,"quest_icon_0015_diamond.png"
        ,"quest_icon_0049_air.png"
        ,"quest_icon_0061_target3.png"
        ,"quest_icon_0053_target2.png"
        ,"quest_icon_0076_special5.png"
        ,"quest_icon_0028_team.png"
        ,"quest_icon_0001_poison.png"
        ,"quest_icon_0059_lightening.png"
        ,"quest_icon_0070_special3.png"
        ,"quest_icon_0046_chat.png"
        ,"quest_icon_0030_coins.png"
        ,"quest_icon_0035_runes.png"
        ,"quest_icon_0031_deamon.png"
        ,"quest_icon_0010_inventory.png"
        ,"quest_icon_0057_location.png"
        ,"quest_icon_0037_hit.png"
        ,"quest_icon_0042_arrow.png"
        ,"quest_icon_0041_cross.png"
        ,"quest_icon_0044_impact.png"
        ,"quest_icon_0012_support.png"
        ,"quest_icon_0054_kingcoins.png"
        ,"quest_icon_0058_bomb.png"
        ,"quest_icon_0011_heart.png"
        ,"quest_icon_0045_hero.png"
        ,"quest_icon_0079_magic3.png"
        ,"quest_icon_0009_crown.png"
        ,"quest_icon_0017_critical.png"
        ,"quest_icon_0071_food2.png"
        ,"quest_icon_0025_fire.png"
        ,"quest_icon_0024_health.png"
        ,"quest_icon_0060_magic2.png"
        ,"quest_icon_0036_sword.png"
        ,"quest_icon_0047_pets.png"
        ,"quest_icon_0051_animal.png"
        ,"quest_icon_0022_defense.png"
        ,"quest_icon_0069_monster.png"
        ,"quest_icon_0020_quest.png"
        ,"quest_icon_0066_share.png"
        ,"quest_icon_0050_food.png"
        ,"quest_icon_0063_location2.png"
    ]
}


  



module.exports = constants;
