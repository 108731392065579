const SMPQuestDefaultStrategy = require("@/quest-editor/questImpl/strategies/SMPQuestDefaultStrategy");
class SMPQuestMakeReviveNSupportStrategy extends SMPQuestDefaultStrategy {

     initStartingValue() {
          this._challengeList = [4, 3, 2, 4, 3, 4, 2, 3];
     }

     increaseKPIGameplayReference() {
          this.questContext.KPIGameplayBossLevel += this.questContext.JumpingLevel;
     }

     computeChallenge() {
          this.questContext.challengeTarget = this.getTargetBaseOnLevel(this.questContext.level);
     }

     computeLevelShouldAppear() {
          let shouldAppearAt = this.questContext.challengeTarget * 5;
          shouldAppearAt = Math.max(1, this.questContext.KPIGameplayBossLevel - shouldAppearAt);
          if (shouldAppearAt < 1) shouldAppearAt = 1;
          this.questContext.KPIGameLevelShouldAppear = shouldAppearAt;
     }
}

module.exports = SMPQuestMakeReviveNSupportStrategy;