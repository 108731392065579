<template>
    <v-container
            fluid
    >
        <v-dialog
                v-model="showSupportDetail"
        >
           <v-card
                    min-width="500"
                    min-height="500"
            >
                <v-simple-table>
                    <template v-slot:default>
                        <thead>
                        <tr>
                            <th>
                                <v-avatar>
                                    <img
                                            :src="Tools.renderLocalPicture('supports/'+selectedSupportDetail.icon)"
                                    ></v-avatar>
                            </th>

                        </tr>
                        <tr>
                            <th>
                                {{ kpiSupportLabelInfos }}
                            </th>
                        </tr>
                        <tr/>
                        <tr>
                            <th class="text-left">
                                KPI Name
                            </th>
                            <th class="text-left">
                                KPI Value
                            </th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr
                        >
                            <td>$G available for all support</td>
                            <td>{{totalGoldAvailableForSupport.ToReadableAlphabetV2()}}</td>
                        </tr>
                        <tr
                        >
                            <td>Cumulated $G to bring this support to this level</td>
                            <td>{{kpiSupportSelectedUnlockAndLevelUpCost.ToReadableAlphabetV2()}}</td>
                        </tr>
                        <tr
                        >
                            <td>$G Remain</td>
                            <td>{{kpiSupportSelectedGoldRemainComeThisLevel.ToReadableAlphabetV2()}}</td>
                        </tr>
                        <tr
                        >
                            <td>Possible Level Up</td>
                            <td>{{supportPossibleLevel.ToReadableAlphabetV2()}}</td>
                        </tr>
                        <tr
                        >
                            <td> Support standard DPS</td>
                            <td> {{ kpiSupportDpsStandard.ToReadableAlphabetV2() }}</td>
                        </tr>
                        <tr
                        >
                            <td> Cost to level up to the next level</td>
                            <td>{{kpiSupportSelectedNextLvCost.ToReadableAlphabetV2()}}</td>
                        </tr>

                        <tr
                        >
                            <td> SUPPORT_TAP_DAMAGE</td>
                            <td> {{ kpiSupportTapDamage.ToReadableAlphabetV2() }}</td>
                        </tr>
                        </tbody>
                    </template>
                </v-simple-table>
                <br/>&nbsp;List of sills unlocked for support at this level <br/>
                <v-simple-table>
                    <template>
                        <tbody>
                        <tr
                        >
                            <td> Skill TAP_DAMAGE</td>
                            <td> {{ kpiSupportSkillTapDamage }}</td>
                        </tr>

                        <tr
                        >
                            <td> Skill SUPPORT_DAMAGE</td>
                            <td> {{ kpiSupportSkillSupportDamage }}</td>
                        </tr>

                        <tr
                        >
                            <td> Skill ALL_DAMAGE</td>
                            <td> {{ kpiSupportSkillAllDamage }}</td>
                        </tr>

                        <tr
                        >
                            <td> Skill CAT BONUS</td>
                            <td> {{ kpiSupportCatBonus }}</td>
                        </tr>

                        <tr
                        >
                            <td> Skill CRITICAL CHANCE</td>
                            <td> {{ kpiSupportCriticalChance }}</td>
                        </tr>

                        <tr
                        >
                            <td> Skill MANA BONUS</td>
                            <td> {{ kpiSupportManaBonus }}</td>
                        </tr>

                        <tr
                        >
                            <td> Skill CRITICAL DAMAGE</td>
                            <td> {{ kpiSupportCriticalDamage }}</td>

                        </tr>

                        <tr
                        >
                            <td> Skill GOLD DROP</td>
                            <td> {{ kpisSupportGoldDrop }}</td>

                        </tr>

                        <tr
                        >
                            <td> Skill WAVE REDUCE</td>
                            <td> {{ kpiSupportWaveReduce }}</td>

                        </tr>

                        <tr
                        >
                            <td> Skill TAP DAMAGE TOTAL DPS</td>

                            <td> {{ kpiSupportTapDamageTotalDps }}</td>

                        </tr>
                        </tbody>
                    </template>
                </v-simple-table>
            </v-card>
        </v-dialog>
        <v-row>
            <v-col cols="3">
                TTKG : <strong> {{ timeToKillGhost }}</strong> <br/>
                TTKNB : <strong>{{ timeToKillBoss }}</strong> <br/>
                TTKZB : <strong>{{ timeToKillZoneBoss }}</strong> <br/>
                SPRINT LEVEL : <strong>{{ sprintLevel }}</strong> <br/>
                NTL : <strong>{{ this.heroBaseLevel + 1 }} </strong><br/>
                $GAP : <strong> {{ costHero.ToReadableAlphabetV2() }} </strong>
                <br/>
                FT
                <small>({{ this.ghostLevel }})</small>
                : <strong> {{ kpiFarmingRequiredToLevelUp }} </strong><br/>

            </v-col>
            <v-col cols="2">
                FTT : <strong> {{ kpiTimeFarming }} </strong> <br/>
                GHP <small>({{this.ghostLevel}})</small>: <strong> {{ kpiGhostHP }} </strong><br/>
                NBHP <small>({{this.ghostLevel}})</small>: <strong> {{ kpiNormalBossHP }} </strong><br/>
                ZBHP <small>({{this.ghostLevel}})</small>: <strong> {{ kpiZoneBossHP }} </strong><br/>
                DMG <small>({{ this.heroBaseLevel }})</small>: <strong> {{kpiHeroBaseDmg.ToReadableAlphabetV2()}} </strong><br/>
                DPS <small>({{ this.heroBaseLevel }})</small>: <strong> {{kpiHeroDps.ToReadableAlphabetV2()}} </strong><br/>
                Support DPS <small>({{this.kpiSupportActiveCounter}})</small>: <strong> {{kpiSupportDpsStandard.ToReadableAlphabetV2() }} </strong><br/>
                WAVE: <strong>{{ kpiWaveValue }} </strong>
            </v-col>
            <v-col cols="2">
                &Sigma;$G Ghost <small>({{ this.getTotalGhost }})</small>
                : <strong>
                {{ kpiCumulatedGoldWonAfterReachingGhostLevel.ToReadableAlphabetV2() }}</strong> <br/>

                &Sigma;$G Boss <small>({{ this.getTotalBoss }})</small>
                : <strong>
                {{ kpiCumulatedGoldWonAfterReachingBossLevel.ToReadableAlphabetV2() }}</strong> <br/>

                &Sigma;$G Zone Boss <small>({{ this.getTotalZoneBoss }})</small>
                : <strong>
                {{ kpiCumulatedGoldWonAfterReachingZoneBossLevel.ToReadableAlphabetV2() }}</strong> <br/>

                &Sigma;$G Enemies <small>({{ this.getTotalEnemies }})</small>
                : <strong>
                {{ kpiCumulatedGoldWonAfterAllEnemiesReachingLevel.ToReadableAlphabetV2() }}</strong> <br/>

                &Sigma;$G Quest <small>({{ this.questData.completeCount }})</small>
                : <strong>
                {{ questData.totalsReward.ToReadableAlphabetV2() }}</strong> <br/>

                &Sigma;$G Total <small>(+{{ getPercentageGoldBonusDisplay }}%)</small>
                : <strong>
                {{ this.totalGoldReachOnLevel.ToReadableAlphabetV2() }}</strong> <br/>
            </v-col>
            <v-col cols="3">
                &Sigma;$GAP
                <small> {{ this.heroBaseLevel }}</small>
                : <strong>
                {{ this.totalGoldHeroSpent.ToReadableAlphabetV2() }}</strong>
                <br/> $G remain from &Sigma;$GAP :
                <strong>{{ this.totalGoldRemainFromHeroSpent.ToReadableAlphabetV2() }}</strong>
                <br/> $G ending :
                <strong>{{this.goldEndingValidate}}</strong><br/>
                $G available for hero skill ({{ratioAllocatedToHeroSkill}}%) : <strong>
                {{ this.totalGoldAvailableForHeroSkill.ToReadableAlphabetV2() }}</strong><br/>
                $G available for support ({{ratioAllocatedToSupport}}%) : <strong>
                {{ this.totalGoldAvailableForSupport.ToReadableAlphabetV2() }}</strong>
                <br/> Support have unlocked : <strong> {{ this.supportPossibleUnlockCounter }}</strong>
                <br/> Support possible levelup : <strong>
                {{ this.supportPossibleLevel.ToReadableAlphabetV2()
                }}</strong><small> ( LvS: {{supportPossibleLevelForSkillUnlock.ToReadableAlphabetV2()}} )</small>
            </v-col>
            <v-col cols="2">
                &Sigma;$D Boss : <strong>{{getDiamondBossCollect}}</strong>
                <br/>&Sigma;$D Achievement : <strong>{{achivDiamonds}}</strong>
                <br/>&Sigma;$D Total : <strong>{{totalDiamonds}}</strong>
                <br/>$D available for pet ({{ratioAllocatedToPet}}%) : <strong>{{totalDiamondAvailableForPet}}</strong>
                <br/>Pet have unlocked : <strong>{{petPossibleUnlockCounter}}</strong>
                <br/>Pet possible levelup : <strong>{{petPossibleLevel}}</strong>
            </v-col>
        </v-row>
        <v-row>
            <v-col>
                <v-card
                        max-width="800"
                        min-width="800"
                >
                    <v-toolbar
                            flat
                            dense
                    >
                        <v-toolbar-title>
              <span class="subheading">
                <v-avatar>
              <img
                      :src="Tools.renderLocalPicture('quest_icon/quest_icon_0013_boss.png')"
              >
                </v-avatar>
                     {{ ghostLevel }}

                    <v-avatar>
                  <img
                          :src="Tools.renderLocalPicture('heroes/HERO-head-09-motharo.png')"
                  >
                </v-avatar>  {{ heroBaseLevel }}</span>


                        </v-toolbar-title>
                        <v-spacer></v-spacer>
                        <v-avatar
                                :color="balanceHeroGhost"
                                size="60"
                        >
                            <span class="white--text text-h5">{{ this.heroBaseLevel - this.ghostLevel }}</span>
                        </v-avatar>


                        1 Ghost will drop : {{ ghostDrop.ToReadableAlphabetV2() }}


                    </v-toolbar>

                    <v-card-text>
                        <v-row
                                class="mb-4"
                                justify="space-between"
                        >
                            <v-col class="text-left">
                      <span
                              class="text-h4 font-weight-light"

                      >HTKG:</span>
                                <span
                                        class="text-h3 font-weight-light"
                                        v-text="hitPerGhosts"
                                ></span>
                                <span class="subheading font-weight-light mr-1">TTKG ({{ timeToKillGhost }} )</span>


                            </v-col>

                        </v-row>


                        <v-slider
                                v-model="ghostLevel"
                                :color="color1"
                                track-color="grey"
                                always-dirty
                                min="1"
                                max="5000"
                                hint="level"
                                persistent-hint
                                @input="onGhostLevelInputed"
                        >
                            <template v-slot:prepend>
                                <v-icon
                                        :color="color1"
                                        @click="decrement"
                                >
                                    mdi-minus
                                </v-icon>
                            </template>

                            <template v-slot:append>
                                <v-icon
                                        :color="color1"
                                        @click="increment"
                                >
                                    mdi-plus
                                </v-icon>
                            </template>
                        </v-slider>
                        <v-slider
                                v-model="heroBaseLevel"
                                :color="colorBaseHero"
                                track-color="grey"
                                always-dirty
                                min="1"
                                max="5000"
                                hint="Hero Base Level"
                                persistent-hint
                                @input="onHeroLevelInputed"
                        >
                            <template v-slot:prepend>
                                <v-icon
                                        :color="colorBaseHero"
                                        @click="decrementBaseHero"
                                >
                                    mdi-minus
                                </v-icon>
                            </template>

                            <template v-slot:append>
                                <v-icon
                                        :color="colorBaseHero"
                                        @click="incrementBaseHero"
                                >
                                    mdi-plus
                                </v-icon>
                            </template>
                        </v-slider>

                        <v-btn
                                color="primary"
                                fab
                                small
                                dark
                                @click="oneShot()"
                        >
                            <v-icon>mdi-alien-outline</v-icon>
                        </v-btn>

                        <v-text-field
                                label="Ghost level direct input"
                                v-model="ghostLevel"
                                single-line
                                type="number"
                                :min="1"
                                :max="5000"
                        />
                    </v-card-text>

                    <v-btn
                            @click="showGroupMainConfig"
                    >
                        Main Config
                    </v-btn>
                    <v-btn
                            @click="showGroupSupportConfig"
                    >
                        Support Config
                    </v-btn>
                    <v-btn
                            @click="showGroupPetConfig"
                    >
                        Pet Config
                    </v-btn>

                </v-card>
                <v-divider></v-divider>
            </v-col>
            <v-card
                    max-width="550"
                    min-width="550"
                    min-height="700"
                    max-height="700"
            >
                <v-tabs
                        v-model="tab"
                        background-color="deep-purple accent-4"
                        centered
                        dark
                        icons-and-text
                >
                    <v-tabs-slider></v-tabs-slider>
                    <v-tab href="#tab-gamePlay-kpi">
                        GamePlay KPI
                    </v-tab>
                    <v-tab href="#tab-hero-skill">
                        Hero Skill
                    </v-tab>
                    <v-tab href="#tab-support">
                        Support ({{supportPossibleUnlockCounter}})
                    </v-tab>
                    <v-tab href="#tab-pet">
                        Pets ({{petPossibleUnlockCounter}})
                    </v-tab>
                </v-tabs>

                <v-tabs-items v-model="tab"
                >
                    <v-tab-item
                            :key="1"
                            value='tab-gamePlay-kpi'
                    >
                        <v-card
                                max-height="650"
                        >
                            <v-row class="ml-1 pt-4">
                                <v-col>
                                    <v-row class="pa-0">
                                        <div class="font-weight-black">GAMEPLAY KPI</div>
                                    </v-row>
                                    <v-row class="ml-0 pt-0">
                                        <v-simple-table>
                                            <template v-slot:default>
                                                <tbody>
                                                <tr>
                                                    <td>PLAYER TAP DAMAGE</td>
                                                    <td>{{kpiGameplayPlayerTapDamage.ToReadableAlphabetV2()}}</td>
                                                </tr>
                                                <tr>
                                                    <td>PLAYER TAP DPS</td>
                                                    <td>{{kpiHeroWithBonusDps.ToReadableAlphabetV2()}}</td>
                                                </tr>
                                                <tr>
                                                    <td>&Sigma;SUPPORT DPS <small>({{kpiSupportActiveCounter}})</small></td>
                                                    <td>{{kpiSupportDpsStandard.ToReadableAlphabetV2()}}</td>
                                                </tr>
                                                <tr>
                                                    <td>PET DMG Standard</td>
                                                    <td>{{kpiPetGamePlayStandardDMG.ToReadableAlphabetV2()}}</td>
                                                </tr>
                                                <tr>
                                                    <td>&Sigma;PET Total Passive DMG </td>
                                                    <td>{{kpiPetTotalPassiveDMG.ToReadableAlphabetV2()}}</td>
                                                </tr>
                                                <tr>
                                                    <td>PET Average DPS</td>
                                                    <td>{{kpiPetDps.ToReadableAlphabetV2()}}</td>
                                                </tr>
                                                </tbody>
                                            </template>
                                        </v-simple-table>
                                    </v-row>
                                </v-col>
                            </v-row>
                        </v-card>
                    </v-tab-item>

                    <v-tab-item
                            :key="2"
                            value='tab-hero-skill'
                    >
                        <v-card
                                max-height="750"
                                min-height="630"
                        >
                            <v-row class="ml-1 pt-4">
                                <v-col>
                                    <v-row class="pa-0">
                                        <div class="font-weight-black">Active Skill</div>
                                    </v-row>
                                    <v-row class="ml-0 pt-0">
                                        <v-simple-table>
                                            <template v-slot:default>
                                                <tbody>
                                                <tr v-for="item in getHeroActiveSkillList"
                                                    :key="item.key">
                                                    <td>
                                                        <v-avatar><img
                                                            :src="Tools.renderLocalPicture('hero_skill/'+item.skill.m_iID+'.png')"
                                                        ></v-avatar>
                                                    </td>
                                                    <td>{{item.skill.m_sName}}</td>
                                                    <td>Lv: {{item.levelPossible}}</td>
                                                    <td>{{item.bonusDisplay}}</td>
                                                </tr>
                                                </tbody>
                                            </template>
                                        </v-simple-table>
                                    </v-row>
                                    <v-row class="pa-0">
                                        <div class="font-weight-black">Boss Skill</div>
                                    </v-row>
                                    <v-row class="ml-0 pt-0">
                                        <v-simple-table>
                                            <template v-slot:default>
                                                <tbody>
                                                <tr v-for="item in getHeroBossSkillList"
                                                    :key="item.key">
                                                    <td>
                                                        <v-avatar><img
                                                                :src="Tools.renderLocalPicture('hero_skill/'+item.skill.m_iID+'.png')"
                                                        ></v-avatar>
                                                    </td>
                                                    <td>{{item.skill.m_sName}}</td>
                                                    <td>Lv: {{item.levelPossible}}</td>
                                                    <td>{{item.bonusDisplay}}</td>
                                                </tr>
                                                </tbody>
                                            </template>
                                        </v-simple-table>
                                    </v-row>
                                    <v-row class="pa-0">
                                        <div class="font-weight-black">Passive Skill</div>
                                    </v-row>
                                    <v-row class="ml-0 pt-0">
                                        <v-simple-table>
                                            <template v-slot:default>
                                                <tbody>
                                                <tr v-for="item in getHeroPassiveSkillList"
                                                    :key="item.key">
                                                    <td>
                                                        <v-avatar><img
                                                                :src="Tools.renderLocalPicture('hero_skill/'+item.skill.m_iID+'.png')"
                                                        ></v-avatar>
                                                    </td>
                                                    <td>{{item.skill.m_sName}}</td>
                                                    <td>Lv: {{item.levelPossible}}</td>
                                                    <td>{{item.bonusDisplay}}</td>
                                                </tr>
                                                </tbody>
                                            </template>
                                        </v-simple-table>
                                    </v-row>
                                </v-col>
                            </v-row>
                        </v-card>
                    </v-tab-item>

                    <v-tab-item
                            :key="3"
                            value='tab-support'
                    >
                        <v-card
                                max-height="650"
                        >
                            <v-row class="ml-1 pt-4">
                                <div class="font-weight-black">SUPPORTS</div>
                            </v-row>
                            <v-row class="ml-1 pt-4"
                                   v-for="(rows, row) in supportList"
                                   :key="row"
                                   dense no-gutters
                            >
                                <v-col
                                        v-for="(item, i) in rows"
                                        :key="i"
                                        @click="didClickOnSupport(item)"
                                >
                                    <v-avatar>
                                        <img
                                                :src="Tools.renderLocalPicture('supports/'+item.icon)"
                                        ></v-avatar>
                                </v-col>
                            </v-row>
                            <v-row class="ml-1 pt-4">
                                <v-col>
                                    <v-row class="pa-0">
                                        <div class="font-weight-black"> TEAM SKILLS BONUS</div>
                                    </v-row>
                                    <v-row class="ml-0 pt-0">
                                        <v-col>
                                            <v-simple-table>
                                                <template v-slot:default>
                                                    <thead>
                                                    <tr>
                                                        <th class="text-left">
                                                            Type
                                                        </th>
                                                        <th class="text-left">
                                                            &Sigma;Bonus
                                                        </th>
                                                    </tr>
                                                    </thead>
                                                    <tbody>
                                                    <tr>
                                                        <td>TAP_DAMAGE</td>
                                                        <td>{{kpiTeamSkillTapDamage}}</td>
                                                    </tr>
                                                    <tr
                                                    >
                                                        <td>SUPPORT_DAMAGE</td>
                                                        <td>{{kpiTeamSkillSupportDamage}}</td>
                                                    </tr>
                                                    <tr
                                                    >
                                                        <td>ALL_DAMAGE</td>
                                                        <td>{{kpiTeamSkillAllDamage}}</td>
                                                    </tr>
                                                    <tr
                                                    >
                                                        <td>CAT BONUS</td>
                                                        <td>{{kpiTeamSkillCatBonus}}</td>
                                                    </tr>
                                                    <tr
                                                    >
                                                        <td>CRITICAL CHANCE</td>
                                                        <td>{{kpiTeamSkillCriticalChance}}</td>
                                                    </tr>
                                                    </tbody>
                                                </template>
                                            </v-simple-table>
                                        </v-col>
                                        <v-col>
                                            <v-simple-table>
                                                <template v-slot:default>
                                                    <thead>
                                                    <tr>
                                                        <th class="text-left">
                                                            Type
                                                        </th>
                                                        <th class="text-left">
                                                            &Sigma;Bonus
                                                        </th>
                                                    </tr>
                                                    </thead>
                                                    <tbody>
                                                    <tr>
                                                        <td>MANA BONUS</td>
                                                        <td>{{kpiTeamSkillManaBonus}}</td>
                                                    </tr>
                                                    <tr
                                                    >
                                                        <td>CRITICAL DAMAGE</td>
                                                        <td>{{kpiTeamSkillCriticalDamage}}</td>
                                                    </tr>
                                                    <tr
                                                    >
                                                        <td>GOLD DROP</td>
                                                        <td>{{percentageGoldFromSupport}}</td>
                                                    </tr>
                                                    <tr
                                                    >
                                                        <td>WAVE REDUCE</td>
                                                        <td>{{kpiTeamSkillWaveReduce}}</td>
                                                    </tr>
                                                    <tr
                                                    >
                                                        <td>TAP DAMAGE TOTAL DPS</td>
                                                        <td>{{kpiTeamSkillTapDamageTotalDps}}</td>
                                                    </tr>
                                                    </tbody>
                                                </template>
                                            </v-simple-table>
                                        </v-col>
                                    </v-row>
                                </v-col>
                            </v-row>
                        </v-card>
                    </v-tab-item>

                    <v-tab-item
                            :key="4"
                            value='tab-pet'
                    >
                        <v-card
                                max-height="650"
                        >
                            <v-row class="ml-1 pt-4">
                                <div class="font-weight-black">PETS</div>
                            </v-row>
                            <v-row class="ml-1 pt-4"
                                   v-for="(rows, row) in petList"
                                   :key="row"
                                   dense no-gutters
                            >
                                <v-col
                                        v-for="(item, i) in rows"
                                        :key="i"
                                        @click="didClickOnPet(item)"
                                >
                                    <v-avatar>
                                        <img
                                                :src="Tools.renderLocalPicture('pets/'+item.petID+'.png')"
                                        ></v-avatar>
                                </v-col>
                            </v-row>
                            <v-row class="ml-1 pt-4">
                                <v-col>
                                    <v-row class="pa-0">
                                        <div class="font-weight-black"> PET SKILLS BONUS</div>
                                    </v-row>
                                    <v-row class="ml-0 pt-0">
                                        <v-col>
                                            <v-simple-table>
                                                <template v-slot:default>
                                                    <thead>
                                                    <tr>
                                                        <th class="text-left">
                                                            Type
                                                        </th>
                                                        <th class="text-left">
                                                            Avg. Bonus
                                                        </th>
                                                        <th class="text-left">
                                                            &Sigma;Passive Bonus
                                                        </th>
                                                        <th class="text-left">
                                                            &Sigma;Possible Bonus
                                                        </th>
                                                    </tr>
                                                    </thead>
                                                    <tbody>
                                                    <tr>
                                                        <td>ALL_DAMAGE</td>
                                                        <td>{{kpiPetAvgActiveBonusSkillAllDMG}}</td>
                                                        <td>{{kpiPetPassiveBonusSkillAllDMG}}</td>
                                                        <td><strong>{{kpiPetTotalPossibleBonusSkillAllDMG}}</strong></td>
                                                    </tr>
                                                    <tr>
                                                        <td>ALL_GOLD</td>
                                                        <td>{{kpiPetAvgActiveBonusSkillAllGOLD}}</td>
                                                        <td>{{kpiPetPassiveBonusSkillAllGOLD}}</td>
                                                        <td><strong>{{kpiPetTotalPossibleBonusSkillAllGOLD}}</strong></td>
                                                    </tr>
                                                    <tr>
                                                        <td>ALL_SUPPORT_DMG</td>
                                                        <td>{{kpiPetAvgActiveBonusSkillAllSupportDMG}}</td>
                                                        <td>{{kpiPetPassiveBonusSkillAllSupportDMG}}</td>
                                                        <td><strong>{{kpiPetTotalPossibleBonusSkillAllSupportDMG}}</strong></td>
                                                    </tr>
                                                    <tr>
                                                        <td>TAP_DAMAGE</td>
                                                        <td>{{kpiPetAvgActiveBonusSkillTapDMG}}</td>
                                                        <td>{{kpiPetPassiveBonusSkillTapDMG}}</td>
                                                        <td><strong>{{kpiPetTotalPossibleBonusSkillTapDMG}}</strong></td>
                                                    </tr>

                                                    </tbody>
                                                </template>
                                            </v-simple-table>
                                        </v-col>
                                    </v-row>
                                </v-col>
                            </v-row>
                        </v-card>
                    </v-tab-item>
                </v-tabs-items>
            </v-card>
        </v-row>
        <SmpDialog :should-show="shouldShowMainConfig"
                   @close="hideGroupMainConfig">
            <v-card
                    min-width="550"
                    class="pa-2"
                    elevation="0"
            >
                <div class="font-weight-black">Hero Skill Allocate Gold Remaining Config</div>
                <v-card
                        class="pt-4 pb-0"
                        elevation="0">
                    <v-slider
                            label="Allocated to hero skill"
                            v-model="ratioAllocatedToHeroSkill"
                            track-color="grey"
                            @input="onRatioHeroSkillChange"
                            always-dirty
                            thumb-label
                            min="0"
                            max="100"
                    >
                        <template v-slot:append>
                            <v-text-field
                                    v-model="ratioAllocatedToHeroSkill"
                                    class="mt-0 pt-0"
                                    type="number"
                                    min="0"
                                    max="100"
                            ></v-text-field>
                        </template>
                    </v-slider>
                </v-card>

                <div class="font-weight-black">Custom Config</div>
                <v-card
                        class="pt-4 pb-0"
                        elevation="0">
                    <v-text-field
                            label="Gold Direct Bonus Percentage"
                            v-model="percentageGoldBonus"
                            @input="onPercentGoldInputed"
                            type="number"
                            :min="0"
                    />
                </v-card>
                <v-row>
                    <v-col class="pt-0  pb-0">
                        <v-checkbox
                                v-model="shouldAddHeroDMGToTTKE"
                                label="Include hero dmg to TTKE"
                                @change="onPercentSwitching"
                                class="pt-0 pl-0"
                        ></v-checkbox>
                    </v-col>
                </v-row>
            </v-card>
        </SmpDialog>

        <SmpDialog :should-show="shouldShowSupportConfig"
                   @close="hideGroupSupportConfig">
            <v-card
                    min-width="550"
                    class="pa-2"
                    elevation="0"
            >
                <div class="font-weight-black">Support Allocate Gold Remaining Config</div>
                <v-card
                        class="pt-4 pb-0"
                        elevation="0">
                    <v-slider
                            label="Allocated to support"
                            v-model="ratioAllocatedToSupport"
                            track-color="grey"
                            @input="onRatioSupportChange"
                            always-dirty
                            thumb-label
                            min="0"
                            max="100"
                    >
                        <template v-slot:append>
                            <v-text-field
                                    v-model="ratioAllocatedToSupport"
                                    class="mt-0 pt-0"
                                    type="number"
                                    min="0"
                                    max="100"
                            ></v-text-field>
                        </template>
                    </v-slider>
                    <v-slider
                            label="Allocated to support skill"
                            v-model="ratioAllocatedToSupportSkill"
                            track-color="grey"
                            @input="onRatioSupportSkillChange"
                            always-dirty
                            thumb-label
                            min="0"
                            max="50"
                    >
                        <template v-slot:append>
                            <v-text-field
                                    v-model="ratioAllocatedToSupportSkill"
                                    class="mt-0 pt-0"
                                    type="number"
                                    min="0"
                                    max="50"
                            ></v-text-field>
                        </template>
                    </v-slider>
                </v-card>

                <div class="font-weight-black">Support Usage Option Config</div>
                <v-row>
                    <v-col class="pt-2">
                        <v-checkbox
                                v-model="isUseSupportSkillGold"
                                label="Include support skill gold bonus"
                                @change="onSupportSkillGoldSwitching"
                                class="pt-0 pl-0"
                        ></v-checkbox>
                        <v-card max-width="300"
                                elevation="0">
                            <v-text-field
                                    label="Use support gold skill percentage"
                                    v-model="percentUseSupportGoldSkill"
                                    @input="onPercentUseSupportGoldSkill"
                                    type="number"
                                    :min="0"
                                    :max="100"
                                    :disabled="!isUseSupportSkillGold"
                            />
                        </v-card>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col class="pt-0 pb-0">
                        <v-checkbox
                                v-model="isUseSupportSkillDmg"
                                label="Include support skill dmg bonus"
                                @change="onPercentSwitching"
                                class="pt-0 pl-0"
                        ></v-checkbox>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col class="pt-0  pb-0">
                        <v-checkbox
                                v-model="shouldAddSupportDMGToTTKE"
                                label="Include support dmg to TTKE"
                                @change="onPercentSwitching"
                                class="pt-0 pl-0"
                        ></v-checkbox>
                    </v-col>
                </v-row>
            </v-card>
        </SmpDialog>

        <SmpDialog :should-show="shouldShowPetConfig"
                   @close="hideGroupPetConfig">
            <v-card
                    min-width="550"
                    class="pa-2"
                    elevation="0"
            >
                <div class="font-weight-black">Pet Allocate Gold Remaining Config</div>
                <v-card
                        class="pt-4 pb-0"
                        elevation="0">
                    <v-slider
                            label="Allocated to pet"
                            v-model="ratioAllocatedToPet"
                            track-color="grey"
                            @input="onRatioPetChange"
                            always-dirty
                            thumb-label
                            min="0"
                            max="100"
                    >
                        <template v-slot:append>
                            <v-text-field
                                    v-model="ratioAllocatedToPet"
                                    class="mt-0 pt-0"
                                    type="number"
                                    min="0"
                                    max="100"
                            ></v-text-field>
                        </template>
                    </v-slider>
                </v-card>

                <div class="font-weight-black">Pet Usage Option Config</div>
                <v-row>
                    <v-col class="pt-2">
                        <v-checkbox
                                v-model="isUsePetSkillGold"
                                label="Include pet skill gold bonus"
                                @change="onPetSkillGoldSwitching"
                                class="pt-0 pl-0"
                        ></v-checkbox>
                        <v-card max-width="300"
                                elevation="0">
                            <v-text-field
                                    label="Use pet gold skill percentage"
                                    v-model="percentUsePetGoldSkill"
                                    @input="onPercentUsePetGoldSkill"
                                    type="number"
                                    :min="0"
                                    :max="100"
                                    :disabled="!isUsePetSkillGold"
                            />
                        </v-card>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col class="pt-0">
                        <v-checkbox
                                v-model="isUsePetSkillDmg"
                                label="Include pet skill dmg bonus"
                                @change="onPercentSwitching"
                                class="pt-0 pl-0"
                        ></v-checkbox>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col class="pt-0 pb-0">
                        <v-checkbox
                                v-model="shouldAddPetDMGToTTKE"
                                label="Include pet dmg to TTKE"
                                @change="onPercentSwitching"
                                class="pt-0 pl-0"
                        ></v-checkbox>
                    </v-col>
                </v-row>
            </v-card>
        </SmpDialog>
    </v-container>
</template>

<style>
    @keyframes metronome-example {
        from {
            transform: scale(.5);
        }

        to {
            transform: scale(1);
        }
    }

    .scroll {
        overflow-y: auto;
    }
</style>

<script>
    import BalanceKpiUtils from "./../../Utils/BalanceKpiUtils";
    import Tools from "../../../utils/Tools";
    import SMPNum from "../../../../src/SMPNum";
    import SupportSkillsConstant from "../../../../src/constants/SupportSkillsConstants"
    import QuestSimulationService from "@/quest-editor/services/QuestSimulationService";
    import lodash from "lodash";
    import PetKpiUtils from "./../../Utils/PetKpiUtils";
    import SupportKpiUtils from "./../../Utils/SupportKpiUtils";
    import PetSkillConstants from "./../../../constants/PetSkillConstants";
    import {KpiGlobalEvents, EVENT_ON_CHANGE_BALANCE_SETTINGS} from "@/kpi-editor/events/KpiGlobalEvents";
    import AchievementSimulationService from "@/achievement-simulation/services/AchievementSimulationService";
    import HeroSkillKpiUtils from "./../../Utils/HeroSkillKpiUtils";
    import SmpDialog from "@/item-editor/modules/SmpDialog";

    export default {
        components:{
            SmpDialog,
        },
        data: () => ({
            items: [
                {text: 'Real-Time', icon: 'mdi-clock'},
                {text: 'Audience', icon: 'mdi-account'},
                {text: 'Conversions', icon: 'mdi-flag'},
            ],
            tab: null,
            text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua',
            Tools: Tools,
            viewMinute: false,
            ghostLevel: 1,
            heroBaseLevel: 1,
            interval: null,
            isPlaying: false,
            balanceKpiUtils: null,
            showSupportDetail: false,
            selectedSupportDetail: null,
            gamePlayDataService: null,
            questData: {
                completeCount:0,
                totalsReward: SMPNum.fromNum(0),
            },

            goldEndingValidate: "0",
            totalGoldSupportHaveSpent: SMPNum.fromNum(0),
            totalGoldHeroSkillHaveSpent: SMPNum.fromNum(0),

            supportKpiUtils: null,
            percentageGoldBonus: 0,
            percentageGoldFromSupport: 0,
            isUseSupportSkillGold: true,
            isUseSupportSkillDmg: true,

            totalGoldReachOnLevel: SMPNum.fromNum(0),
            totalGoldHeroSpent: SMPNum.fromNum(0),
            totalGoldRemainFromHeroSpent: SMPNum.fromNum(0),
            totalGoldAvailableForSupport: SMPNum.fromNum(0),
            supportPossibleUnlockCounter: 0,
            supportPossibleLevel: SMPNum.fromNum(0),
            supportPossibleLevelForSkillUnlock: SMPNum.fromNum(0),
            ratioAllocatedToSupport: 50,
            ratioAllocatedToSupportSkill: 20,
            percentUseSupportGoldSkill: 100,

            maxRatioAllocateToSupportSet: -1,
            maxRatioAllocateToHeroSkillSet: -1,

            petKpiUtils: null,
            petPossibleUnlockCounter: 0,
            petPossibleLevel: 0,
            isUsePetSkillGold: true,
            isUsePetSkillDmg: true,
            percentUsePetGoldSkill: 100,
            totalDiamondAvailableForPet: 0,
            ratioAllocatedToPet: 50,
            totalDiamonds: 0,
            achivDiamonds: 0,

            heroSkillKpiUtils: null,
            ratioAllocatedToHeroSkill: 20,
            totalGoldAvailableForHeroSkill: SMPNum.fromNum(0),
            totalGoldHeroSkillSpent: SMPNum.fromNum(0),
            heroSkillsUnlockList: [],

            shouldShowMainConfig: false,
            shouldShowSupportConfig: false,
            shouldShowPetConfig: false,

            shouldAddHeroDMGToTTKE: true,
            shouldAddSupportDMGToTTKE: true,
            shouldAddPetDMGToTTKE: true,
            shouldAddPetPassiveDMGToTTKE: true,
        }),

        computed: {

            kpiGameplayPlayerTapDamage() {
                let tapDamagePercent = 0;
                let allDmgPercent = 0;
                let petTapDmgPercent = 0;
                let petAllDmgPercent = 0;
                if(this.isUseSupportSkillDmg){
                    tapDamagePercent = this.kpiTeamSkillTapDamage;
                    allDmgPercent = this.kpiTeamSkillAllDamage;
                }
                if(this.isUsePetSkillDmg){
                    petTapDmgPercent = this.getPetTotalPossibleBonusSkillWithoutFormat(PetSkillConstants.TAP_DAMAGE);
                    petAllDmgPercent = this.getPetTotalPossibleBonusSkillWithoutFormat(PetSkillConstants.ALL_DAMAGE);
                }
                let baseDmg = this.kpiHeroBaseDmg;
                let totalPercent = (tapDamagePercent + allDmgPercent + petTapDmgPercent + petAllDmgPercent)/100.0;
                let finalResult = SMPNum.multSmpNum(baseDmg, new SMPNum(totalPercent));
                finalResult = SMPNum.plus(finalResult, baseDmg);

                if(this.isUseSupportSkillDmg){
                    let totalSupportDPS = this.kpiSupportDpsStandard;
                    if(!totalSupportDPS.isZero){
                        let percentTapDMGFromSupportDPS = this.kpiTeamSkillTapDamageTotalDps;
                        if(percentTapDMGFromSupportDPS> 0){
                            let bonus = SMPNum.multSmpNum(totalSupportDPS, SMPNum.fromNum(percentTapDMGFromSupportDPS/100));
                            finalResult = SMPNum.plus(finalResult, bonus);
                        }
                    }
                }

                return finalResult;
            },

            kpiTeamSkillTapDamageTotalDps() {
                return this.defineSupportBonusSkill(SupportSkillsConstant.TAP_DAMAGE_TOTAL_DPS);
            },

            kpiTeamSkillWaveReduce() {
                return this.defineSupportBonusSkill(SupportSkillsConstant.WAVE_REDUCE);
            },

            kpiTeamSkillGoldDrop() {
                return this.defineSupportBonusSkill(SupportSkillsConstant.GOLD_DROP);
            },

            kpiTeamSkillCriticalDamage() {
                return this.defineSupportBonusSkill(SupportSkillsConstant.CRITICAL_DMG);
            },

            kpiTeamSkillManaBonus() {
                return this.defineSupportBonusSkill(SupportSkillsConstant.MANA_BONUS);
            },

            kpiTeamSkillCriticalChance() {
                return this.defineSupportBonusSkill(SupportSkillsConstant.CRITICAL_CHANCE);
            },

            kpiTeamSkillCatBonus() {
                return this.defineSupportBonusSkill(SupportSkillsConstant.CAT_BONUS);
            },

            kpiTeamSkillSupportDamage() {
                return this.defineSupportBonusSkill(SupportSkillsConstant.SUPPORT_DAMAGE);
            },


            kpiTeamSkillTapDamage() {
                return this.defineSupportBonusSkill(SupportSkillsConstant.TAP_DAMAGE);
            },

            kpiTeamSkillAllDamage() {
                return this.defineSupportBonusSkill(SupportSkillsConstant.ALL_DAMAGE);
            },

            kpiSupportTapDamageTotalDps() {
                return this.defineSupportSelectedBonusSkill(SupportSkillsConstant.TAP_DAMAGE_TOTAL_DPS);
            },

            kpiSupportWaveReduce() {
                return this.defineSupportSelectedBonusSkill(SupportSkillsConstant.WAVE_REDUCE);
            },

            kpisSupportGoldDrop() {
                return this.defineSupportSelectedBonusSkill(SupportSkillsConstant.GOLD_DROP);
            },

            kpiSupportCriticalDamage() {
                return this.defineSupportSelectedBonusSkill(SupportSkillsConstant.CRITICAL_DMG);
            },

            kpiSupportManaBonus() {
                return this.defineSupportSelectedBonusSkill(SupportSkillsConstant.MANA_BONUS);
            },


            kpiSupportCriticalChance() {
                return this.defineSupportSelectedBonusSkill(SupportSkillsConstant.CRITICAL_CHANCE);
            },

            kpiSupportCatBonus() {
                return this.defineSupportSelectedBonusSkill(SupportSkillsConstant.CAT_BONUS);
            },

            kpiSupportSkillAllDamage() {
                return this.defineSupportSelectedBonusSkill(SupportSkillsConstant.ALL_DAMAGE);
            },

            kpiSupportSkillSupportDamage() {
                return this.defineSupportSelectedBonusSkill(SupportSkillsConstant.SUPPORT_DAMAGE);
            },


            kpiSupportSkillTapDamage() {
                return this.defineSupportSelectedBonusSkill(SupportSkillsConstant.TAP_DAMAGE);
            },

            kpiSupportTapDamage() {
                let possibleLevelUp = this.supportPossibleLevel.ToDoubleValue();
                possibleLevelUp = parseInt(possibleLevelUp);

                let temp = this.gamePlayDataService.DMGStandardSupport(possibleLevelUp);
                return temp;

            },

            kpiSupportActiveCounter(){
                let activeSupport = 0;
                if(this.supportPossibleUnlockCounter >= 4){
                    activeSupport = 4;
                } else {
                    activeSupport = this.supportPossibleUnlockCounter;
                }
                return activeSupport;
            },

            kpiSupportDpsStandard() {
                let supportDamagePercent = 0;
                let allDmgPercent = 0;
                let petSupportDmgPercent = 0;
                let petAllDmgPercent = 0;
                if(this.isUseSupportSkillDmg){
                    supportDamagePercent = this.kpiTeamSkillSupportDamage;
                    allDmgPercent = this.kpiTeamSkillAllDamage;
                }
                if(this.isUsePetSkillDmg){
                    petSupportDmgPercent = this.getPetTotalPossibleBonusSkillWithoutFormat(PetSkillConstants.ALL_SUPPORTER_DAMAGE);
                    petAllDmgPercent = this.getPetTotalPossibleBonusSkillWithoutFormat(PetSkillConstants.ALL_DAMAGE);
                }
                let totalPercent = (supportDamagePercent + allDmgPercent + petSupportDmgPercent + petAllDmgPercent)/100;
                let dmgPerSupportBonus = SMPNum.multSmpNum(this.kpiSupportTapDamage, new SMPNum(totalPercent));

                let dmgPerSupport = SMPNum.plus(this.kpiSupportTapDamage, dmgPerSupportBonus);
                let activeSupport = this.kpiSupportActiveCounter;
                if(activeSupport === 0){
                    return SMPNum.fromNum(0);
                } else {
                    return SMPNum.multSmpNum(dmgPerSupport, SMPNum.fromNum(activeSupport));
                }
            },

            kpiSupportLabelInfos() {
                let res = "";
                if (this.selectedSupportDetail !== null) {
                    res = this.selectedSupportDetail.name;
                }
                return res;
            },

            kpiSupportSelectedUnlockAndLevelUpCost(){
                let id = this.selectedSupportDetail.supportId;
                let costUnlock = this.gamePlayDataService.getCostUnlockSupport(id);
                let costLevelUp = this.gamePlayDataService.CostSupport(this.supportPossibleLevel.ToDoubleValue());
                let cost = SMPNum.plus(costUnlock, costLevelUp);
                return cost;
            },

            kpiSupportSelectedGoldRemainComeThisLevel(){
                let cost = this.kpiSupportSelectedUnlockAndLevelUpCost;
                let remain = SMPNum.divSmpNum(this.totalGoldAvailableForSupport, cost);
                return remain;
            },

            kpiSupportSelectedNextLvCost(){
                let lv = this.supportPossibleLevel.ToDoubleValue();
                let cost = this.gamePlayDataService.getCostUpdateSupport(lv, lv+1);
                return cost;
            },

            kpiHeroDps() {
                let temp = this.gamePlayDataService.getHeroDMG(this.heroBaseLevel);
                return SMPNum.multSmpNum(temp, new SMPNum(this.$store.state.ratios.hitPerSeconds));
            },

            kpiHeroWithBonusDps() {
                let temp = this.kpiGameplayPlayerTapDamage;
                return SMPNum.multSmpNum(temp, new SMPNum(this.$store.state.ratios.hitPerSeconds));
            },

            kpiHeroWithBonusDpsOnBoss() {
                let temp = this.kpiGameplayPlayerTapDamage;
                return SMPNum.multSmpNum(temp, new SMPNum(this.$store.state.ratios.hitBossPerSeconds));
            },

            kpiPetDps() {
                return this.getAvgPetDps(this.$store.state.ratios.hitPerSeconds);
            },

            kpiPetDpsOnBoss() {
                return this.getAvgPetDps(this.$store.state.ratios.hitBossPerSeconds);
            },

            supportList() {

                let availableSupport = this.supportKpiUtils.getGamePlayUnlockedTeam(this.supportPossibleUnlockCounter);

                let rows = [];
                let rowCount = 1;
                let tempRow = [];
                for (let i = 0; i < availableSupport.length; i++) {
                    tempRow.push(availableSupport[i]);
                    if (rowCount === 10 || i === availableSupport.length - 1) {
                        rows.push(tempRow);
                        rowCount = 0;
                        tempRow = [];
                    }
                    rowCount++;
                }
                return rows;
            },

            petList() {

                let availablePet = this.petKpiUtils.getUnlockedPetList(this.petPossibleUnlockCounter);

                let rows = [];
                let rowCount = 1;
                let tempRow = [];
                for (let i = 0; i < availablePet.length; i++) {
                    tempRow.push(availablePet[i]);
                    if (rowCount === 10 || i === availablePet.length - 1) {
                        rows.push(tempRow);
                        rowCount = 0;
                        tempRow = [];
                    }
                    rowCount++;
                }
                return rows;
            },

            kpiPetGamePlayStandardDMG() {
                return this.petKpiUtils.getPetStandardDMG(this.kpiGameplayPlayerTapDamage, this.petPossibleLevel);
            },

            kpiPetTotalPassiveDMG() {
                return this.petKpiUtils.getTotalPetPassiveDMG(this.kpiGameplayPlayerTapDamage, this.petPossibleLevel, this.petPossibleUnlockCounter);
            },

            kpiPetTotalPossibleBonusSkillAllDMG(){
                return this.getPetTotalPossibleBonusSkill(PetSkillConstants.ALL_DAMAGE);
            },

            kpiPetTotalPossibleBonusSkillAllGOLD(){
                return this.getPetTotalPossibleBonusSkill(PetSkillConstants.ALL_GOLD);
            },

            kpiPetTotalPossibleBonusSkillAllSupportDMG(){
                return this.getPetTotalPossibleBonusSkill(PetSkillConstants.ALL_SUPPORTER_DAMAGE);
            },

            kpiPetTotalPossibleBonusSkillTapDMG(){
                return this.getPetTotalPossibleBonusSkill(PetSkillConstants.TAP_DAMAGE);
            },

            kpiPetAvgActiveBonusSkillAllDMG(){
                return this.getPetAvgActiveBonusSkill(PetSkillConstants.ALL_DAMAGE);
            },

            kpiPetAvgActiveBonusSkillAllGOLD(){
                return this.getPetAvgActiveBonusSkill(PetSkillConstants.ALL_GOLD);
            },

            kpiPetAvgActiveBonusSkillAllSupportDMG(){
                return this.getPetAvgActiveBonusSkill(PetSkillConstants.ALL_SUPPORTER_DAMAGE);
            },

            kpiPetAvgActiveBonusSkillTapDMG(){
                return this.getPetAvgActiveBonusSkill(PetSkillConstants.TAP_DAMAGE);
            },

            kpiPetPassiveBonusSkillAllDMG(){
                return this.getPetPassiveBonusSkill(PetSkillConstants.ALL_DAMAGE);
            },

            kpiPetPassiveBonusSkillAllGOLD(){
                return this.getPetPassiveBonusSkill(PetSkillConstants.ALL_GOLD);
            },

            kpiPetPassiveBonusSkillAllSupportDMG(){
                return this.getPetPassiveBonusSkill(PetSkillConstants.ALL_SUPPORTER_DAMAGE);
            },

            kpiPetPassiveBonusSkillTapDMG(){
                return this.getPetPassiveBonusSkill(PetSkillConstants.TAP_DAMAGE);
            },

            kpiCumulatedGoldWonAfterReachingGhostLevel() {
                let baseDrop = this.gamePlayDataService.mathGamePlay.ComputeSumGeometricForSeries('DropCoins', this.ghostLevel);
                //one level have 9 ghost
                let ghostCountPerLv = this.gamePlayDataService.ratiosConfig.ghostCounterPerLv;
                return SMPNum.multSmpNum(baseDrop, new SMPNum(ghostCountPerLv));
            },

            kpiCumulatedGoldWonAfterReachingBossLevel() {
                let baseDrop = this.gamePlayDataService.mathGamePlay.ComputeSumGeometricForSeries('DropCoins', this.ghostLevel)
                //10 levels have 9 boss
                let bossCounterPerLv = this.gamePlayDataService.ratiosConfig.bossCounterPerLv;
                let bossGoldRatio = this.gamePlayDataService.ratiosConfig.bossGoldGhostRatio;
                return SMPNum.multSmpNum(baseDrop, new SMPNum(bossCounterPerLv * bossGoldRatio));
            },

            kpiCumulatedGoldWonAfterReachingZoneBossLevel() {
                let baseDrop = this.gamePlayDataService.mathGamePlay.ComputeSumGeometricForSeries('DropCoins', this.ghostLevel)
                //10 level have 1 zone boss
                let zoneBossCounterPerLv = this.gamePlayDataService.ratiosConfig.zoneBossCounterPerLv;
                let zoneBossGoldRatio = this.gamePlayDataService.ratiosConfig.zoneBossGoldGhostRatio;
                let result = SMPNum.multSmpNum(baseDrop, new SMPNum(zoneBossCounterPerLv * zoneBossGoldRatio));
                return result;
            },

            kpiCumulatedGoldWonAfterAllEnemiesReachingLevel() {
                let total = this.totalEnemiesGolds();
                return total;
            },

            getPercentageGoldBonusDisplay() {
                return this.format2Decimal(this.getPercentageGoldBonus());
            },

            getTotalGhost() {
                let counterPerLv = this.gamePlayDataService.ratiosConfig.ghostCounterPerLv;
                let total = Math.round(counterPerLv * this.ghostLevel);
                return total;//SMPNum.fromNum(total).ToReadableAlphabetV2();
            },

            getTotalBoss() {
                let counterPerLv = this.gamePlayDataService.ratiosConfig.bossCounterPerLv;
                let total = Math.round(counterPerLv * this.ghostLevel);
                return total;//SMPNum.fromNum(total).ToReadableAlphabetV2();
            },

            getTotalZoneBoss() {
                let counterPerLv = this.gamePlayDataService.ratiosConfig.zoneBossCounterPerLv;
                let total = Math.floor(counterPerLv * this.ghostLevel);
                return total;//SMPNum.fromNum(total).ToReadableAlphabetV2();
            },

            getTotalEnemies() {
                //ghost
                let counterPerLv = this.gamePlayDataService.ratiosConfig.ghostCounterPerLv;
                let total = Math.round(counterPerLv * this.ghostLevel);

                //boss
                counterPerLv = this.gamePlayDataService.ratiosConfig.bossCounterPerLv;
                total += Math.round(counterPerLv * this.ghostLevel);

                //zone boss
                counterPerLv = this.gamePlayDataService.ratiosConfig.zoneBossCounterPerLv;
                total += Math.floor(counterPerLv * this.ghostLevel);

                return total;//SMPNum.fromNum(total).ToReadableAlphabetV2();
            },

            getDiamondStartUp(){
                return this.gamePlayDataService.getDiamondStartUp();
            },

            getDiamondBossCollect(){
                return this.gamePlayDataService.getDiamondDrop(this.ghostLevel);
            },

            kpiWaveValue() {
                return Math.floor(this.ghostLevel / 450) + 10;
            },
            kpiTimeFarming() {
                let t = this.balanceKpiUtils.getTimeToKillGhostInSMPNum(this.ghostLevel, this.heroBaseLevel, this.$store.state.ratios.hitPerSeconds);
                let farming = SMPNum.divSmpNum(this.costHero, this.ghostDrop)
                let total = SMPNum.multSmpNum(t, farming);

                return SMPNum.ToReadableTimeValue(total);
            },

            kpiGhostHP() {
                let temp = this.gamePlayDataService.getGhostHP(this.ghostLevel);
                return temp.ToReadableAlphabetV2();
            },

            kpiNormalBossHP() {
                let temp = this.gamePlayDataService.getBossHP(this.ghostLevel);
                return temp.ToReadableAlphabetV2();
            },

            kpiZoneBossHP() {
                let temp = this.gamePlayDataService.getZoneBossHP(this.ghostLevel);
                return temp.ToReadableAlphabetV2();
            },

            kpiHeroBaseDmg() {
                let temp = this.gamePlayDataService.getHeroDMG(this.heroBaseLevel);
                return temp;
            },

            kpiFarmingRequiredToLevelUp() {
                let res = SMPNum.divSmpNum(this.costHero, this.ghostDrop)
                return res.ToReadableAlphabetV2();
            },

            ghostDrop() {
                let res = this.gamePlayDataService.getCoinDrop(this.ghostLevel);
                return res;
            },

            costHero() {
                let res = this.gamePlayDataService.getHeroCost(this.heroBaseLevel + 1);
                return res;
            },

            balanceHeroGhost() {
                if (this.ghostLevel < this.heroBaseLevel) return 'green'
                return 'red'
            },

            timeToKillGhost() {
                if (this.balanceKpiUtils) {
                    let heroDps = SMPNum.fromNum(0);
                    if(this.shouldAddHeroDMGToTTKE){
                        heroDps = this.kpiHeroWithBonusDps;
                    }
                    let supportDps = SMPNum.fromNum(0);
                    if(this.shouldAddSupportDMGToTTKE){
                        supportDps = this.kpiSupportDpsStandard;
                    }
                    let petAvgDps = SMPNum.fromNum(0);
                    if(this.shouldAddPetDMGToTTKE){
                        petAvgDps = this.kpiPetDps;
                    }
                    let dps = SMPNum.plus(heroDps, supportDps);
                    dps = SMPNum.plus(dps, petAvgDps);
                    return this.balanceKpiUtils.getTimeToKillGhostByDPS(this.ghostLevel, dps);
                } else {
                    return 0;
                }
            },

            timeToKillBoss() {
                //SMPNum.ToReadableTimeValue(this.getRealTimeToKillBossByHeroDPS(bossLv, dps))
                let timeToKillBoss = this.getTimeToKillBossOnStageLevel(this.ghostLevel);
                return SMPNum.ToReadableTimeValue(timeToKillBoss);
            },

            timeToKillZoneBoss() {
                let timeToKillBoss = this.getTimeToKillZoneBossOnStageLevel(this.ghostLevel);
                return SMPNum.ToReadableTimeValue(timeToKillBoss);
            },

            sprintLevel() {
                let bossLevel = this.ghostLevel + 1;
                //let time = this.balanceKpiUtils.getTimeToKillBossValueOnly(bossLevel, this.heroBaseLevel, this.$store.state.ratios.hitBossPerSeconds)
                let time = this.getTimeToKillBossOnStageLevel(bossLevel);

                let temp = 0;
                let oneHour = new SMPNum(1500);
                while (temp < 1000 && !SMPNum.greaterThan(time, oneHour)) {
                    temp++;
                    //time = this.balanceKpiUtils.getTimeToKillBossValueOnly(bossLevel, this.heroBaseLevel, this.$store.state.ratios.hitBossPerSeconds)
                    time = this.getTimeToKillBossOnStageLevel(bossLevel);
                    bossLevel = bossLevel + 1;
                }
                let label = 'Can reach level' + bossLevel + '(TTKB: ' + SMPNum.ToReadableTimeValue(time) + ')';
                //return temp;
                if (temp === 0) {
                    label = 'Impossible';
                }
                return label;
            },

            hitPerGhosts() {
                return this.computeHitPerGhosts();

            },

            color1() {
                if (this.ghostLevel < 100) return 'indigo';
                if (this.ghostLevel < 125) return 'teal';
                if (this.ghostLevel < 140) return 'green';
                if (this.ghostLevel < 175) return 'orange';
                return 'red'
            },

            colorBaseHero() {
                if (this.heroBaseLevel < 100) return 'indigo';
                if (this.heroBaseLevel < 125) return 'teal';
                if (this.heroBaseLevel < 140) return 'green';
                if (this.heroBaseLevel < 175) return 'orange';
                return 'red'
            },

            animationDuration() {
                return `${60 / this.ghostLevel}s`
            },


            getHeroActiveSkillList(){
                return this.heroSkillKpiUtils.getHeroSkillPossibleByTypes(this.heroSkillsUnlockList, [0, 1]);
            },

            getHeroBossSkillList(){
                return this.heroSkillKpiUtils.getHeroSkillPossibleByTypes(this.heroSkillsUnlockList, [2]);
            },

            getHeroPassiveSkillList(){
                return this.heroSkillKpiUtils.getHeroSkillPossibleByTypes(this.heroSkillsUnlockList, [3]);
            },
        },

        created() {
            KpiGlobalEvents.$on(EVENT_ON_CHANGE_BALANCE_SETTINGS, () => this.onBalanceSettingChange());
            this.gamePlayDataService = this.$store.state.gamePlayDataService;
            this.balanceKpiUtils = new BalanceKpiUtils(this.gamePlayDataService);
            this.petKpiUtils = new PetKpiUtils(this.$store.state.petsData, this.gamePlayDataService);
            this.supportKpiUtils = new SupportKpiUtils(this.$store.state.skillList, this.gamePlayDataService, SupportSkillsConstant);
            this.heroSkillKpiUtils = new HeroSkillKpiUtils(this.$store.state.heroSkillList, this.gamePlayDataService);

            this.ghostLevel = this.$store.state.ui.kpi.bpm;
            this.heroBaseLevel = this.$store.state.ui.kpi.heroBaseLevel;

            this.onGhostLevelInputed();
        },

        methods: {

            onBalanceSettingChange() {
                this.executeAllKPIFormula();
            },

            executeAllKPIFormula(){
                this.kpiComputeQuestComplete();

                this.defineDiamondCollect();
                this.defineDiamondAvailableForPet();
                this.definePossiblePetGamePlay();

                this.executeAllGoldFormula();
            },

            executeAllGoldFormula(){

                this.resetPercentBeforeCalculateTotalGoldReach();

                //when support skill bonus change, we will get new gold earning
                //so we can upgrade hero, or new remain to upgrade more support for new skill gold bonus

                let lastSupportBonus = 0;
                let isEnd = false;
                do {
                    this.defineTotalGoldOnLevelReach();
                    this.defineTotalHeroSpent();
                    this.defineTotalRemainFromHeroSpent();
                    this.defineGoldAvailableForHeroSkill();
                    this.defineLevelForHeroSkillPossibleReach();
                    this.defineMoneyAvailableForSupport();
                    this.defineLevelForSupportPossibleReach();
                    this.defineTeamSkillGoldDrop();
                    this.defineGoldEnding();
                    if(lastSupportBonus < this.percentageGoldFromSupport){
                        lastSupportBonus = this.percentageGoldFromSupport;
                    } else {
                        isEnd = true;
                    }
                }while (!isEnd);
            },

            defineTotalGoldOnLevelReach(){
                let total = SMPNum.plus(this.totalEnemiesGolds(), this.questData.totalsReward);

                let percent = this.getPercentageGoldBonus();
                if(percent > 0){
                    let bonus = SMPNum.multSmpNum(total, SMPNum.fromNum(percent/100));
                    total = SMPNum.plus(total, bonus)
                }

                this.totalGoldReachOnLevel.setFromSMPNum(total);
            },

            defineTotalHeroSpent(){
                let heroLvSpent = this.gamePlayDataService.mathGamePlay.ComputeSumGeometricForSeries('CostHero', this.heroBaseLevel);
                this.totalGoldHeroSpent.setFromSMPNum(heroLvSpent);

            },

            defineGoldAvailableForHeroSkill(){
                if (!this.totalGoldRemainFromHeroSpent.isZero && this.ratioAllocatedToHeroSkill > 0) {
                    let result = SMPNum.multSmpNum(new SMPNum(this.ratioAllocatedToHeroSkill), this.totalGoldRemainFromHeroSpent);
                    result = SMPNum.divSmpNum(result, new SMPNum(100));
                    this.totalGoldAvailableForHeroSkill.setFromSMPNum(result);
                } else {
                    this.totalGoldAvailableForHeroSkill.setNumDouble(0);
                }
            },

            defineLevelForHeroSkillPossibleReach() {
                this.heroSkillsUnlockList = [];
                if(!this.totalGoldAvailableForHeroSkill.isZero){
                    let skillUnlocksAndSpent = this.heroSkillKpiUtils.getHeroSkillUnlockedAndSpent(this.heroBaseLevel, this.ghostLevel, this.totalGoldAvailableForHeroSkill);

                    if(skillUnlocksAndSpent.skillDisplays.length === 0){
                        this.totalGoldHeroSkillHaveSpent.setNumDouble(0);
                        return;
                    }

                    //define possible level
                    if(SMPNum.greaterThan(skillUnlocksAndSpent.totalSpent, this.totalGoldAvailableForHeroSkill)){
                        console.log("Invalid on available gold and unlock hero skill");
                    } else {
                        let goldRemain = SMPNum.minus(this.totalGoldAvailableForHeroSkill, skillUnlocksAndSpent.totalSpent);
                        let lastGold = this.heroSkillKpiUtils.definePossibleHeroSkillLevel(skillUnlocksAndSpent.skillDisplays, this.ghostLevel, goldRemain);
                        this.totalGoldHeroSkillHaveSpent.setFromSMPNum(lastGold);
                    }

                    skillUnlocksAndSpent.skillDisplays.forEach(sk => {
                        this.heroSkillsUnlockList.push(sk);
                    });
                } else {
                    this.totalGoldHeroSkillHaveSpent.setNumDouble(0);
                }
            },

            defineTotalRemainFromHeroSpent() {
                if(SMPNum.greaterThan(this.totalGoldReachOnLevel, this.totalGoldHeroSpent)){
                    let remain = SMPNum.minus(this.totalGoldReachOnLevel, this.totalGoldHeroSpent);
                    this.totalGoldRemainFromHeroSpent.setFromSMPNum(remain);
                } else {
                    this.totalGoldRemainFromHeroSpent.setNumDouble(0);
                }
            },

            defineMoneyAvailableForSupport() {
                if (!this.totalGoldRemainFromHeroSpent.isZero && this.ratioAllocatedToSupport > 0) {
                    let result = SMPNum.multSmpNum(new SMPNum(this.ratioAllocatedToSupport), this.totalGoldRemainFromHeroSpent);
                    result = SMPNum.divSmpNum(result, new SMPNum(100));
                    this.totalGoldAvailableForSupport.setFromSMPNum(result);
                } else {
                    this.totalGoldAvailableForSupport.setNumDouble(0);
                }
            },

            defineLevelForSupportPossibleReach() {
                if(!this.totalGoldAvailableForSupport.isZero){
                    let result = this.supportKpiUtils.GetNumberOfSupportUnlockableAndGoldRemain(this.totalGoldAvailableForSupport, this.ghostLevel);
                    this.supportPossibleUnlockCounter = result.totalUnlockSupport;
                    let goldAvailableForSupport = result.goldRemain;
                    if (!goldAvailableForSupport.isZero && this.supportPossibleUnlockCounter > 0) {
                        let possibleSupportSkillSpent = SMPNum.multSmpNum(new SMPNum(this.ratioAllocatedToSupportSkill), goldAvailableForSupport);
                        possibleSupportSkillSpent = SMPNum.divSmpNum(possibleSupportSkillSpent, new SMPNum(100));

                        let possibleSupportLvSpent = SMPNum.minus(goldAvailableForSupport, possibleSupportSkillSpent);
                        let levelPossible = this.supportKpiUtils.innerFunctionBuildPossibleLevelUpForSupport(possibleSupportLvSpent, this.supportPossibleUnlockCounter);
                        let resultSkillPossible = this.supportKpiUtils.innerFunctionBuildPossibleUnlockSkillForSupport(possibleSupportSkillSpent, this.supportPossibleUnlockCounter, levelPossible);

                        this.supportPossibleLevel.setNumDouble(levelPossible);
                        this.supportPossibleLevelForSkillUnlock.setNumDouble(resultSkillPossible.skillPossibleLevel);

                        //find total spent on support
                        let supportLvSpent = this.supportKpiUtils.TotalSupportSpentReachToLevel(levelPossible, this.supportPossibleUnlockCounter);
                        let lvSkillSpent = resultSkillPossible.totalSkillSpent;
                        let totalSupportSpent = SMPNum.plus(supportLvSpent, lvSkillSpent);
                        this.totalGoldSupportHaveSpent.setFromSMPNum(totalSupportSpent);

                    } else {
                        this.supportPossibleLevel.setNumDouble(0);
                        this.supportPossibleLevelForSkillUnlock.setNumDouble(0);
                        this.totalGoldSupportHaveSpent.setNumDouble(0);
                    }
                } else {
                    this.supportPossibleUnlockCounter = 0;
                    this.supportPossibleLevel.setNumDouble(0);
                    this.supportPossibleLevelForSkillUnlock.setNumDouble(0);
                    this.totalGoldSupportHaveSpent.setNumDouble(0);
                }
            },

            defineTeamSkillGoldDrop() {
                this.percentageGoldFromSupport = this.defineSupportBonusSkill(SupportSkillsConstant.GOLD_DROP);
            },

            defineGoldEnding(){
                let remain = this.totalGoldRemainFromHeroSpent;
                let spent = SMPNum.plus(this.totalGoldSupportHaveSpent, this.totalGoldHeroSkillHaveSpent);
                if(SMPNum.greaterThan(remain, spent)){
                    let end = SMPNum.minus(remain, spent);
                    this.goldEndingValidate = end.ToReadableAlphabetV2();
                } else {
                    let end = SMPNum.minus(spent, remain);
                    if(end.isZero){
                        this.goldEndingValidate = "0";
                    } else {
                        this.goldEndingValidate = "-"+end.ToReadableAlphabetV2();
                    }
                }
            },

            getPercentageGoldBonus() {
                let percent = this.percentageGoldBonus;
                if (this.isUseSupportSkillGold) {
                    percent += (this.percentUseSupportGoldSkill * this.percentageGoldFromSupport)/100;
                }
                if (this.isUsePetSkillGold){
                    percent += (this.percentUsePetGoldSkill * this.getPetTotalPossibleBonusSkill(PetSkillConstants.ALL_GOLD))/100;
                }
                return percent;
            },

            //Support Defining
            defineSupportBonusSkill(skillType) {
                let teamSize = this.supportPossibleUnlockCounter;
                let teamLevel = this.supportPossibleLevelForSkillUnlock.ToDoubleValue();

                let skillPercent = this.supportKpiUtils.computeSkillBonus(skillType, teamLevel, teamSize);

                return skillPercent;
            },
            defineSupportSelectedBonusSkill(skillType) {
                if (this.selectedSupportDetail && this.selectedSupportDetail.data && this.supportPossibleLevelForSkillUnlock) {
                    let percent = this.supportKpiUtils.getSupportCumulatedPercent(this.selectedSupportDetail.supportId
                        , skillType
                        , this.supportPossibleLevelForSkillUnlock.ToDoubleValue())

                    return percent;
                }
                return 0;
            },

            //PET Defining
            definePossiblePetGamePlay(){
                this.petPossibleUnlockCounter = this.gamePlayDataService.getPetCountUnlockOnLevel(this.ghostLevel);

                if(this.petPossibleUnlockCounter > 0){
                    this.petPossibleLevel = this.petKpiUtils.getPossiblePetLevelOnDiamond(this.totalDiamondAvailableForPet, this.petPossibleUnlockCounter);
                } else {
                    this.petPossibleLevel = 0;
                }
            },

            getPetTotalPossibleBonusSkill(skillType){
                return this.format2Decimal(this.getPetTotalPossibleBonusSkillWithoutFormat(skillType));
            },

            getPetTotalPossibleBonusSkillWithoutFormat(skillType){
                let avg = this.petKpiUtils.getPetAvgActiveSkillBonus(skillType, this.petPossibleLevel, this.petPossibleUnlockCounter);
                let passive = this.petKpiUtils.getPetTotalPassiveSkillBonus(skillType, this.petPossibleLevel, this.petPossibleUnlockCounter);
                return avg + passive;
            },

            getPetAvgActiveBonusSkill(skillType){
                let avg = this.petKpiUtils.getPetAvgActiveSkillBonus(skillType, this.petPossibleLevel, this.petPossibleUnlockCounter);
                return this.format2Decimal(avg);
            },

            getPetPassiveBonusSkill(skillType){
                let passive = this.petKpiUtils.getPetTotalPassiveSkillBonus(skillType, this.petPossibleLevel, this.petPossibleUnlockCounter);
                return this.format2Decimal(passive);
            },

            getAvgPetDps(hitPerSecond) {
                let heroDmg = this.kpiGameplayPlayerTapDamage;
                let petPassiveDmg = SMPNum.fromNum(0);
                if(this.shouldAddPetPassiveDMGToTTKE){
                    petPassiveDmg = this.kpiPetTotalPassiveDMG;
                }
                let totalDMG = SMPNum.plus(heroDmg, petPassiveDmg);
                return this.petKpiUtils.getPossibleDPS(totalDMG, this.petPossibleLevel, this.petPossibleUnlockCounter, hitPerSecond);
            },

            format2Decimal(amount){
                return Number(amount).toFixed(2);
            },

            totalEnemiesGolds(){
                let baseDrop = this.gamePlayDataService.mathGamePlay.ComputeSumGeometricForSeries('DropCoins', this.ghostLevel);

                //ghost
                let ghostCountPerLv = this.gamePlayDataService.ratiosConfig.ghostCounterPerLv;
                let g = SMPNum.multSmpNum(baseDrop, SMPNum.fromNum(ghostCountPerLv));

                //boss
                let bossCounterPerLv = this.gamePlayDataService.ratiosConfig.bossCounterPerLv;
                let bossGoldRatio = this.gamePlayDataService.ratiosConfig.bossGoldGhostRatio;
                let b = SMPNum.multSmpNum(baseDrop, SMPNum.fromNum(bossCounterPerLv * bossGoldRatio));

                //zone boss
                let zoneBossCounterPerLv = this.gamePlayDataService.ratiosConfig.zoneBossCounterPerLv;
                let zoneBossGoldRatio = this.gamePlayDataService.ratiosConfig.zoneBossGoldGhostRatio;
                let zb = SMPNum.multSmpNum(baseDrop, SMPNum.fromNum(zoneBossCounterPerLv * zoneBossGoldRatio));

                let total = SMPNum.plus(g, b);
                total = SMPNum.plus(total, zb);

                return total;
            },

            getTimeToKillBossOnStageLevel(stageLevel){
                if (this.balanceKpiUtils) {
                    let heroDps = SMPNum.fromNum(0);
                    if(this.shouldAddHeroDMGToTTKE){
                        heroDps = this.kpiHeroWithBonusDpsOnBoss;
                    }
                    let petDps = SMPNum.fromNum(0);
                    if(this.shouldAddPetDMGToTTKE){
                        petDps = this.kpiPetDpsOnBoss;
                    }
                    let maxTotalDps = SMPNum.plus(heroDps, petDps);

                    let activeSupport = this.kpiSupportActiveCounter;
                    if(activeSupport > 0 && this.shouldAddSupportDMGToTTKE){
                        let supportsDPS = this.kpiSupportDpsStandard;
                        let supportOneDps = SMPNum.divSmpNum(supportsDPS, new SMPNum(activeSupport));
                        if(SMPNum.greaterThan(supportOneDps, maxTotalDps)){
                            maxTotalDps = supportOneDps;
                        }
                    }
                    let time = this.balanceKpiUtils.getRealTimeToKillBossByHeroDPS(stageLevel, maxTotalDps);
                    return time;
                } else {
                    return 0;
                }
            },

            getTimeToKillZoneBossOnStageLevel(stageLevel){
                if (this.balanceKpiUtils) {
                    let heroDps = SMPNum.fromNum(0);
                    if(this.shouldAddHeroDMGToTTKE){
                        heroDps = this.kpiHeroWithBonusDpsOnBoss;
                    }
                    let petDps = SMPNum.fromNum(0);
                    if(this.shouldAddPetDMGToTTKE){
                        petDps = this.kpiPetDpsOnBoss;
                    }
                    let maxTotalDps = SMPNum.plus(heroDps, petDps);

                    let activeSupport = this.kpiSupportActiveCounter;
                    if(activeSupport > 0 && this.shouldAddSupportDMGToTTKE){
                        let supportsDPS = this.kpiSupportDpsStandard;
                        let supportOneDps = SMPNum.divSmpNum(supportsDPS, new SMPNum(activeSupport));
                        if(SMPNum.greaterThan(supportOneDps, maxTotalDps)){
                            maxTotalDps = supportOneDps;
                        }
                    }
                    let time = this.balanceKpiUtils.getRealTimeToKillZoneBossByHeroDPS(stageLevel, maxTotalDps);
                    return time;
                } else {
                    return 0;
                }
            },

            defineDiamondAvailableForPet() {
                if (this.totalDiamonds > 0 && this.ratioAllocatedToPet > 0) {
                    this.totalDiamondAvailableForPet = Math.round(this.totalDiamonds * this.ratioAllocatedToPet / 100);
                } else {
                    this.totalDiamondAvailableForPet = 0;
                }
            },

            onRatioPetChange(){
                if(this.ratioAllocatedToPet>100){
                    this.ratioAllocatedToPet = 100;
                } else if(this.ratioAllocatedToPet < 0){
                    this.ratioAllocatedToPet = 0;
                }

                this.executeAllKPIFormula();
            },

            onRatioHeroSkillChange(){
                if(this.ratioAllocatedToHeroSkill>100){
                    this.ratioAllocatedToHeroSkill = 100;
                } else if(this.ratioAllocatedToHeroSkill < 0){
                    this.ratioAllocatedToHeroSkill = 0;
                }

                this.makeSoureAllocateRatioAsNumber();

                //remain gold must not over 100%
                let totalAllocate = this.ratioAllocatedToSupport + this.ratioAllocatedToHeroSkill;
                if(totalAllocate > 100){
                    if(this.maxRatioAllocateToSupportSet === -1){
                        this.maxRatioAllocateToSupportSet = this.ratioAllocatedToSupport;
                    }
                    this.ratioAllocatedToSupport = 100 - this.ratioAllocatedToHeroSkill;
                } else if(this.maxRatioAllocateToSupportSet >= 0){
                    if((100 - this.ratioAllocatedToHeroSkill) < this.maxRatioAllocateToSupportSet) {
                        this.ratioAllocatedToSupport = 100 - this.ratioAllocatedToHeroSkill;
                        if(this.ratioAllocatedToSupport > this.maxRatioAllocateToSupportSet){
                            this.ratioAllocatedToSupport = this.maxRatioAllocateToSupportSet;
                        }
                    } else if((this.maxRatioAllocateToSupportSet + this.ratioAllocatedToHeroSkill) < 100){
                        this.ratioAllocatedToSupport = this.maxRatioAllocateToSupportSet;
                    }
                }

                this.executeAllKPIFormula();
            },

            computeMaximumLevelAchievable() {
                let found = false;

                let target = Math.floor(this.ghostLevel / 2);
                if(target<1){
                    target = 1;
                }
                let previousTarget = this.ghostLevel;
                let securityLoop = 1;
                let levelToReturn = -1;

                do {
                    let remaining = this.computeRemainingMoney(target);
                    let goLeft = remaining.isZero;
                    if (!goLeft) {
                        levelToReturn = target;
                    }
                    let temp = target;
                    if (goLeft) {
                        target = target - Math.floor((Math.abs(target - previousTarget)) / 2);
                    } else {
                        target = target + Math.floor((Math.abs(previousTarget - target)) / 2);
                    }
                    previousTarget = temp;
                    if (previousTarget === target) {
                        found = true;
                    }
                    securityLoop++;
                } while (!found && securityLoop < 100);
                return levelToReturn;

            },

            computeRemainingMoney(heroLevel) {
                let tempMoneySpent = this.gamePlayDataService.mathGamePlay.ComputeSumGeometricForSeries('CostHero', heroLevel);
                this.resetPercentBeforeCalculateTotalGoldReach();
                this.defineTotalGoldOnLevelReach();
                let totalGold = new SMPNum(0);
                totalGold.setFromSMPNum(this.totalGoldReachOnLevel);

                //minus support spent
                if (!totalGold.isZero && this.ratioAllocatedToSupport > 0) {
                    let possibleSupportSpent = SMPNum.multSmpNum(new SMPNum(this.ratioAllocatedToSupport), totalGold);
                    possibleSupportSpent = SMPNum.divSmpNum(possibleSupportSpent, new SMPNum(100));

                    let result = this.supportKpiUtils.GetNumberOfSupportUnlockableAndGoldRemain(possibleSupportSpent, this.ghostLevel);
                    let supportCounter = result.totalUnlockSupport;
                    possibleSupportSpent = result.goldRemain;

                    let possibleSupportSkillSpent = SMPNum.multSmpNum(new SMPNum(this.ratioAllocatedToSupportSkill), possibleSupportSpent);
                    possibleSupportSkillSpent = SMPNum.divSmpNum(possibleSupportSkillSpent, new SMPNum(100));

                    let possibleSupportLvSpent = SMPNum.minus(possibleSupportSpent, possibleSupportSkillSpent);
                    let levelPossible = this.supportKpiUtils.innerFunctionBuildPossibleLevelUpForSupport(possibleSupportLvSpent, supportCounter);
                    let supportLvSpent = this.supportKpiUtils.TotalSupportSpentReachToLevel(levelPossible, supportCounter);
                    let resultSkillPossible = this.supportKpiUtils.innerFunctionBuildPossibleUnlockSkillForSupport(possibleSupportSkillSpent, supportCounter, levelPossible);
                    let lvSkillSpent = resultSkillPossible.totalSkillSpent;

                    let totalSupportSpent = SMPNum.plus(supportLvSpent, lvSkillSpent);
                    if(SMPNum.greaterThan(possibleSupportSpent, totalSupportSpent)){
                        totalGold = SMPNum.minus(totalGold, totalSupportSpent);
                    } else {
                        console.log("Possible skill spent: "+possibleSupportSkillSpent.ToReadableAlphabetV2()+" real spent: "+lvSkillSpent.ToReadableAlphabetV2());
                        console.log("Possible lv spent: "+possibleSupportLvSpent.ToReadableAlphabetV2()+" real spent: "+supportLvSpent.ToReadableAlphabetV2());
                        console.log("lv: "+levelPossible+" sklv: "+resultSkillPossible.skillPossibleLevel);
                        console.log("Incorrect define possible support level by gold: "+possibleSupportSpent.ToReadableAlphabetV2()+" SC: "+supportCounter+" spent:"+totalSupportSpent.ToReadableAlphabetV2());
                    }

                }

                if (totalGold && tempMoneySpent) {
                    if (SMPNum.greaterThan(totalGold, tempMoneySpent)) {
                        let temp = SMPNum.minus(totalGold, tempMoneySpent);
                        return temp;
                    } else {
                        return new SMPNum(0);
                    }
                }
                return new SMPNum(0);
            },

            computeHitPerGhosts() {
                if (this.balanceKpiUtils) {
                    return this.balanceKpiUtils.getTotalHitsRequiredToKillAGhostAtLevel(this.ghostLevel, this.heroBaseLevel)
                } else {
                    return 0;
                }
            },

            resetPercentBeforeCalculateTotalGoldReach(){
                //the first define support gold will zero
                this.percentageGoldFromSupport = 0;
            },

            oneShot() {
                let temp = this.computeMaximumLevelAchievable();
                if (temp > 0) {
                    this.heroBaseLevel = temp;
                } else {
                    //this.heroBaseLevel = this.ghostLevel;
                    this.heroBaseLevel = 1;
                }
                this.onHeroLevelInputed();
                this.saveUi();
            },

            onGhostLevelInputed() {
                if (this.ghostLevel < 1) {
                    this.ghostLevel = 1;
                } else if (this.ghostLevel > 5000) {
                    this.ghostLevel = 5000;
                }

                this.executeAllKPIFormula();
            },

            onSupportSkillGoldSwitching(){
                if(this.isUseSupportSkillGold){
                    this.percentUseSupportGoldSkill = 100;
                } else {
                    this.percentUseSupportGoldSkill = 0;
                }
                this.executeAllKPIFormula();
            },

            onPercentUseSupportGoldSkill() {
                if (this.percentUseSupportGoldSkill < 0) {
                    this.percentUseSupportGoldSkill = 0;
                } else if (this.percentUseSupportGoldSkill > 100) {
                    this.percentUseSupportGoldSkill = 100;
                }

                this.executeAllKPIFormula();
            },

            onPetSkillGoldSwitching(){
                if(this.isUsePetSkillGold){
                    this.percentUsePetGoldSkill = 100;
                } else {
                    this.percentUsePetGoldSkill = 0;
                }
                this.executeAllKPIFormula();
            },

            onPercentUsePetGoldSkill() {
                if (this.percentUsePetGoldSkill < 0) {
                    this.percentUsePetGoldSkill = 0;
                } else if (this.percentUsePetGoldSkill > 100) {
                    this.percentUsePetGoldSkill = 100;
                }

                this.executeAllKPIFormula();
            },

            onHeroLevelInputed(){
                if(this.heroBaseLevel < 1){
                    this.heroBaseLevel = 1;
                } else if (this.heroBaseLevel > 5000){
                    this.heroBaseLevel = 5000;
                }

                this.executeAllGoldFormula();
            },

            onPercentGoldInputed() {
                if (this.percentageGoldBonus === '' || this.percentageGoldBonus < 0) {
                    this.percentageGoldBonus = 0;
                } else {
                    this.percentageGoldBonus = parseFloat(this.percentageGoldBonus);
                }

                this.executeAllGoldFormula();
            },

            makeSoureAllocateRatioAsNumber(){
                this.ratioAllocatedToSupport = parseInt(this.ratioAllocatedToSupport);
                this.ratioAllocatedToHeroSkill = parseInt(this.ratioAllocatedToHeroSkill);
            },

            onRatioSupportChange(){
                if(this.ratioAllocatedToSupport>100){
                    this.ratioAllocatedToSupport = 100;
                } else if(this.ratioAllocatedToSupport < 0){
                    this.ratioAllocatedToSupport = 0;
                }

                this.makeSoureAllocateRatioAsNumber();

                //remain gold must not over 100%
                let totalAllocate = this.ratioAllocatedToSupport + this.ratioAllocatedToHeroSkill;
                if(totalAllocate > 100){
                    if(this.maxRatioAllocateToHeroSkillSet === -1){
                        this.maxRatioAllocateToHeroSkillSet = this.ratioAllocatedToHeroSkill;
                    }
                    this.ratioAllocatedToHeroSkill = 100 - this.ratioAllocatedToSupport;
                } else if(this.maxRatioAllocateToHeroSkillSet >= 0) {
                    if ((100 - this.ratioAllocatedToSupport) < this.maxRatioAllocateToHeroSkillSet) {
                        this.ratioAllocatedToHeroSkill = 100 - this.ratioAllocatedToSupport;
                        if (this.ratioAllocatedToHeroSkill < this.maxRatioAllocateToHeroSkillSet) {
                            this.ratioAllocatedToHeroSkill = this.maxRatioAllocateToHeroSkillSet;
                        }
                    } else if ((this.maxRatioAllocateToHeroSkillSet + this.ratioAllocatedToSupport) < 100) {
                        this.ratioAllocatedToHeroSkill = this.maxRatioAllocateToHeroSkillSet;
                    }
                }

                this.executeAllKPIFormula();
            },
            onRatioSupportSkillChange(){
                if(this.ratioAllocatedToSupportSkill>50){
                    this.ratioAllocatedToSupportSkill = 50;
                } else if(this.ratioAllocatedToSupportSkill < 0){
                    this.ratioAllocatedToSupportSkill = 0;
                }

                this.executeAllKPIFormula();
            },

            onPercentSwitching(){
                this.executeAllKPIFormula();
            },

            kpiComputeQuestComplete(){
                let simulationService = new QuestSimulationService(
                    lodash.cloneDeep(this.$store.state.questList)
                    , this.$store.state.gamePlayDataService
                    , this.$store.state.progressTitle
                    , this.$store.state.progressMainTitle
                    , this.$store.state.tutorialTitle
                );
                simulationService.initGameQuests();

                let result = simulationService.computeRewardForAllQuests(this.ghostLevel);
                this.questData.completeCount = result.countCompleted;
                this.questData.totalsReward = result.reward;
            },

            defineDiamondCollect(){
                let dps = SMPNum.fromNum(0)
                if (this.balanceKpiUtils) {
                    let heroDps = this.kpiHeroWithBonusDps;
                    let supportDps = this.kpiSupportDpsStandard;
                    let petAvgDps = this.kpiPetDps;
                    dps = SMPNum.plus(heroDps, supportDps);
                    dps = SMPNum.plus(dps, petAvgDps);
                }

                let service = new AchievementSimulationService(
                    lodash.cloneDeep(this.$store.state.achievementList),
                    this.$store.state.balanceBuilder,
                    this.$store.state.gamePlayDataService,
                    this.supportKpiUtils
                );


                const achievementReward = service.compute(
                    this.ghostLevel,
                    [], dps,
                    this.supportPossibleLevel.ToDoubleValue() * this.supportPossibleUnlockCounter
                );

                this.achivDiamonds = achievementReward.reward;

                this.totalDiamonds = this.getDiamondStartUp + this.getDiamondBossCollect + this.achivDiamonds;

            },

            saveUi() {
                let param = {
                    uiScreen: 'kpi',
                    uiValue: {
                        bpm: this.ghostLevel,
                        heroBaseLevel: this.heroBaseLevel
                    }
                }
                this.$store.commit('saveUi', param);
            },

            decrement() {
                if (this.ghostLevel > 1) {
                    this.ghostLevel--;
                    this.onGhostLevelInputed();
                    this.saveUi();
                }

            },

            //events
            increment() {
                this.ghostLevel++;
                this.onGhostLevelInputed();
                this.saveUi();
            },
            decrementBaseHero() {
                if (this.heroBaseLevel > 1) {
                    this.heroBaseLevel--;
                    this.onHeroLevelInputed();
                    this.saveUi();
                }

            },

            incrementBaseHero() {
                this.heroBaseLevel++;
                this.onHeroLevelInputed();
                this.saveUi();
            },

            toggle() {
                this.isPlaying = !this.isPlaying

            },
            didClickOnSupport(p) {
                this.showSupportDetail = true;
                this.selectedSupportDetail = p;
            },
            didClickOnPet(p) {
                console.log(p);
                // this.showSupportDetail = true;
                // this.selectedSupportDetail = p;
            },

            showGroupMainConfig(){
                this.shouldShowMainConfig = true;
            },
            hideGroupMainConfig(){
                this.shouldShowMainConfig = false;
            },

            showGroupSupportConfig(){
                this.shouldShowSupportConfig = true;
            },
            hideGroupSupportConfig(){
                this.shouldShowSupportConfig = false;
            },

            showGroupPetConfig(){
                this.shouldShowPetConfig = true;
            },
            hideGroupPetConfig(){
                this.shouldShowPetConfig = false;
            },
        },
    }
</script>